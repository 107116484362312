<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">抓住APP变现增收“新机遇”，FinalLink 助你C位出道</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2021年5月14日</span>
      <span class="detailTxt">分类：产品功能</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="module1">
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">2019-2021年，随着直播电商、社交电商、社区团购等新型电商形式的兴起，在互联网广告市场上，电商广告主对流量需求大增。2020年电商广告增幅大于其他各种行业广告占比，整体占比接近50%，预计增长率将大于6.5%。</p>
      </div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info2/consult_info1.png" alt="infoImg" class="infoImg">
      </div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">电商广告需求日盛，给存量市场下移动广告增长带来了新的机遇。一方面新兴电商需要更多流量为自己引流，以达到增长目标。另一方面，传统综合电商企业更需要在激烈的市场竞争中，获得更多广告流量来巩固市场占有率，以发挥老用户带来的直接价值。</p>
        <p class="contentTxt">
          电商企业迫切需要流量市场提供更具电商行业特点的解决方案，市场呼唤新的、能满足电商推广需求的广告产品。凭借多年服务淘宝、京东、拼多多、唯品会等大型电商广告主的丰富经验和广告资源，
          <span class="txtBolder">轻变聚合推出了全新的专门针对电商广告主需求的广告形式，并推出新的服务平台——FinalLink。</span>
        </p>
      </div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info2/consult_info2.png" alt="infoImg" class="infoImg">
      </div>
      <div class="consult-content">
        <p class="contentTxt">
          <span class="txtBolder">FinalLink</span>
          可帮助电商企业实现
          <span class="txtBolder">引流促活</span>
          ，同时可使APP流量主快速获得
          <span class="txtBolder">电商广告红利</span>
          ，突破存量市场下收益增长困境，实现增收。
        </p>
      </div>
    </div>
    <!-------------------------------- FinalLink 3大亮点 -------------------------------->
    <div class="module2">
      <!-- 副标题 -->
      <div class="title_sub">FinalLink 3大亮点</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt"><span class="txt_icon dot_icon"></span>针对电商广告主，产品逻辑以引流电商页面唤醒用户为目的。</p>
        <p class="contentTxt"><span class="txt_icon dot_icon"></span>区别于传统广告形式的新开辟广告位。</p>
        <p class="contentTxt"><span class="txt_icon dot_icon"></span>新增广告位资源，以吸引电商广告预算为主要收入来源。</p>
      </div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info2/consult_info3.png" alt="infoImg" class="infoImg">
      </div>
    </div>
    <!-------------------------------- FinalLink 4大优势 -------------------------------->
    <div class="module3">
      <!-- 副标题 -->
      <div class="title_sub">FinalLink 4大优势</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">
          <span class="txt_icon"></span>
          <span class="txtBolder">用户体验好：</span>
          更原生，免打扰，用户体验更友好
        </p>
        <p class="contentTxt">
          <span class="txt_icon"></span>
          <span class="txtBolder">开发成本低：</span>
          轻集成，高可控，技术对接更简单
        </p>
        <p class="contentTxt">
          <span class="txt_icon"></span>
          <span class="txtBolder">素材内容可控：</span>
          品牌佳，审核严，无违规素材风险
        </p>
        <p class="contentTxt">
          <span class="txt_icon"></span>
          <span class="txtBolder">基于电商用户：</span>
          看行为，高匹配，广告投放更精准
        </p>
      </div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info2/consult_info4.png" alt="infoImg" class="infoImg">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo2',
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
</style>
