import Vue from 'vue'
import Router from 'vue-router'
import home from '@/pages/index/home'                                 // home页面
import index from '@/pages/index/index'                               // 首页
import product from '@/pages/product'                                 // 产品服务
import solution from '@/pages/solution'                               // 解决方案
import clientCase from '@/pages/client-case'                          // 客户案例
import consult from '@/pages/consult'                                 // 资讯中心
import about from '@/pages/about'                                     // 关于我们
import demonstrate from '@/pages/demonstrate'                         // 预约演示
import download from '@/pages/download'                               // 下载SDK
// 旧页面 --------------------
// import business from '@/pages/index/components/business'              // 业务场景
// import security from '@/pages/index/components/security'              // 安全透明
// import cooperation from '@/pages/index/components/cooperation'        // 合作案例

Vue.use(Router);

// 消除重复导航报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  // mode: 'history',
  routes: [
    // 路由重定向
    {
      path: "/",
      component: home,
      redirect: { name: "index" }
    },
    // 网站首页
    {
      path: "/home",
      name: "home",
      component: home,
      children: [
        { // 首页
          path: 'index',
          name: 'index',
          component: index,
        },
        { // 产品服务
          path: 'product',
          name: 'product',
          component: product
        },
        { // 解决方案
          path: 'solution',
          name: 'solution',
          component: solution
        },
        { // 客户案例
          path: 'client-case',
          name: 'client-case',
          component: clientCase
        },
        { // 资讯中心
          path: 'consult',
          name: 'consult',
          component: consult
        },
        { // 关于我们
          path: 'about',
          name: 'about',
          component: about
        },
        { // 预约演示
          path: 'demonstrate',
          name: 'demonstrate',
          component: demonstrate,
        },
        { // 下载SDK
          path: 'download',
          name: 'download',
          component: download,
        },


        // { // 业务场景
        //   path: '/home/business',
        //   name: 'business',
        //   component: business,
        // },
        // { // 安全透明
        //   path: '/home/security',
        //   name: 'security',
        //   component: security,
        // },
        // { // 合作案例
        //   path: '/home/cooperation',
        //   name: 'cooperation',
        //   component: cooperation,
        // },
      ]
    },
  ]
});
