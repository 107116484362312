<template>
  <div class="case-container">
    <!-- 主标题 -->
    <div class="title_main">{{ caseInfo.titleMain }}</div>
    <div class="content_txt">
      <p v-for="(item, index) of caseInfo.mainTxt" :key="index">{{ item }}</p>
    </div>
    <!-- 副标题 -->
    <div class="title_sub">{{ caseInfo.subTitle }}</div>
    <!-- 主体内容 -->
    <div class="case-content-warp" v-for="(itemCon, index) of caseInfo.infoContent" :key="index">
      <div class="title_txt">{{ itemCon.title }}</div>
      <div class="content_txt">{{ itemCon.content }}</div>
      <div v-if="itemCon.urlList.length > 0">
        <img v-for="(imgUrl, ind) of itemCon.urlList" :key="ind" :src="imgUrl" alt="info_img" class="info_img">
      </div>
    </div>
    <!-- 上一篇 or 下一篇 -->
    <div class="article-select-warp">
      <p class="article-select" v-for="item of caseInfo.articleSelect" :key="item.id" @click="viewDetail(item.id)">
        <span class="article_label">{{ item.label }}：</span>
        <span class="article_title">{{ item.title }}</span>
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'caseInfo',
    props: {
      caseInfo: { type: Object }
    },
    methods: {
      viewDetail(id) {
        let pageId = id.split('o')[1];
        this.$router.push({ name: 'client-case', hash: '#page=' + pageId });
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
</style>
