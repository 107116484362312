<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">变现干货 | App商业化变现中广告位设计的原则及优化思路</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2021年9月6日</span>
      <span class="detailTxt">分类：产品功能</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="module1">
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">我们在与APP开发者接触时常常会被问到：我的广告要设计成什么样？什么样的广告形式收益比较高？我的广告收益为什么远远低于行业水平？</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">这些问题很大程度上是因为广告位设计问题引起的，不同的广告形式对广告主的营销价值不同，收益自然就有高低。同时，广告位设置，广告场景的不同也对广告收益有较大影响。今天我们就在这里为大家梳理一下广告位设计与收益之间的关系，便于大家今后进行合理的广告位设计开发。</p>
      </div>
    </div>
    <!-------------------------------- 广告位设计的原则 -------------------------------->
    <div class="module2">
      <!-- 副标题 -->
      <div class="title_sub">广告位设计的原则</div>
      <!-- 1. 适配原则 -->
      <div class="title_txt">1. 适配原则</div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">开发者在进行广告位开发时，一定要注意接入的广告平台都支持哪些广告形式。</span>广告平台不适配的广告形式，是没有办法在广告平台进行变现的，这是首先要考虑的问题。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">目前广告平台一般都支持的广告形式有开屏、插屏、Banner、原生信息流、激励视频这几种，也是目前几种主流的广告形式。所以，想做广告变现的开发者，对这几种广告形式一定要熟悉，因为各个广告平台会有具体的广告形式适配范围的文档，应当在适配的尺寸范围内开发设计。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">在适配范围内的广告位，广告平台会自动将素材做一定处理以适配开发者广告位，如果开发者APP广告位不适配的话，会直接影响广告的曝光率，从而降低广告收益。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">预留的展示位置过大或过小都会影响广告展示：（1）预留位置过小，广告展示不完全，广告平台会算作没有曝光或无效展示，不予结算；（2）预留位置过大，广告无法填满广告位，对展示效果有一定影响，不美观的同时降低用户体验，降低用户广告互动意愿，广告转化率会受影响，进而影响收益。所以，适配原则是广告位设计的首要原则。</p>
      </div>
      <!-- 2. 获利原则 -->
      <div class="title_txt">2. 获利原则</div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">APP开发者做广告变现就是为了要获取利润，所以，获利也是首先要考虑的因素。</span></p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">不同的广告形式单价不同。目前，激励视频的价格是所有广告形式中最高的，其次是开屏，原生、插屏、Banner略低。开发者在选择广告形式时需要结合自身用户特点来考虑广告形式的最大获利点。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">另一个决定收益的因素是流量量级，这点比较好理解，流量量级越大，广告收益就越高，流量量级与App自身的用户量成正比，用户量越大，活跃度越高，产生的可用来变现的流量也会越大。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">开发者在设计广告位入口的时候，应该选择流量大、出现率高的位置设置广告位，充分地利用每一次的广告展现机会，才能获得较好收益。</p>
      </div>
      <!-- 3. 第一视觉原则 -->
      <div class="title_txt">3. 第一视觉原则</div>
      <div class="consult-content">
        <p class="contentTxt">大家通常都会对第一眼看到的事物印象深刻，广告也是如此，广告位需要在用户第一眼能看到的地方出现，才能发挥最大展示效果。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">做过App运营的小伙伴应该都知道，一级页面上用户首屏的展现率是最高的，后面依次递减，二级页面的PV量会远远小于一级页面，所以，广告位设计入口，一定要放在PV量高的页面，否则，收益不会高。</p>
      </div>
      <!-- 4. 免打扰原则 -->
      <div class="title_txt">4. 免打扰原则</div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">用户在使用APP过程中，对广告的关注度会降低，此时，就不是展示广告的好时机。也就是说，广告和使用功能不要同时出现，同时出现，用户就会选其一，而放弃一个。</span></p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">绝大多数情况下用户会放弃广告，这样该广告位很可能曝光量很大，但点击很少，点击率非常低，广告平台会判定为效果差的媒体，从而降低该媒体的收益。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">正确的做法是放在用户App使用链路上，放在用户“必经之路”上，既保证了曝光量，又不打扰用户。根据历史经验，放在前链路上的效果会明显高于后链路上，前链路上的广告曝光量和点击率都会较高。而后链路上，效果则相对较差，因为此时用户已经使用完某个功能，绝大多数用户选择离开，而不是点击广告。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">因为媒体之间存在差异性，都有各自的特点，不同广告形式对媒体的适用性也不同，下面我们从各广告形式的角度逐一分析设计和开发思路。</p>
      </div>
    </div>
    <!-------------------------------- 广告形式分析和开发思路 -------------------------------->
    <div class="module3">
      <!-- 副标题 -->
      <div class="title_sub">广告形式分析和开发思路</div>
      <!-- 文字标题1 -->
      <div class="title_txt">1. 开屏广告</div>
      <!-- 节点1 -->
      <div class="node-warp1">
        <!-- 节点标题 -->
        <div class="title_node">（1）广告形式</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">开屏广告一般在App启动页面出现，一般分为全屏开屏和原生开屏两种形式。广告类型一般分成静态、动态、视频3种形式。</p>
        </div>
        <!-- 多个图片排列 -->
        <div class="img-wrap-multiple">
          <div class="flex-img-flex">
            <div class="img-wrap">
              <img src="../../../assets/image/consult/info10/consult_info1.png" class="img">
              <p class="txt">原生开屏</p>
            </div>
            <div class="img-wrap">
              <img src="../../../assets/image/consult/info10/consult_info2.png" class="img">
              <p class="txt">全屏开屏</p>
            </div>
          </div>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">（2）开屏广告位设计优化思路</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">开屏广告只在开机启动时出现，有时间限制，对用户体验影响较小，且基本哪个APP都会有，适用的媒体类型比较广，有一定用户量级，日活还不错的APP都适合开发开屏广告位。开屏广告位的eCPM价格相对较高，与同量级的其他广告类型相比，收益较好。开屏广告位目前设计上相对成熟，开发也比较简单，这里主要要注意的有以下几点：</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">a. 预留足够的展示时长</span></p>
          <p class="contentTxt">广告平台一般对广告展示时长都有要求，静态、动态、视频的展示时长要求可能会有所不同，尤其是视频，展示时长会比较长。有些开发者认为时间过长影响用户体验，会缩减开屏广告的展示时长，导致素材没有展示完毕就过去了，这样会产生大量的无效展示，降低广告收益。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">b. 预留足够的等待时长</span></p>
          <p class="contentTxt">开屏广告的素材有静态有动态，素材大小不同。有些素材较大，加载较慢，等待时长长，还没来得及展示出来，设置的等待时间已过，广告来不及展示。所以，在等待返回广告的时间上，也要留出足够的时间，以免造成错过展示机会。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">c. 缓存/预加载素材</span></p>
          <p class="contentTxt">开发者可以提前缓存或者预加载广告，这样可以最大限度地避免由于网络原因或者素材原因等问题，造成无法展示广告的情况。</p>
        </div>
      </div>
      <!-- 文字标题2 -->
      <div class="title_txt">2. 插屏广告</div>
      <!-- 节点2 -->
      <div class="node-warp2">
        <!-- 节点标题 -->
        <div class="title_node">（1）广告形式</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">在APP特定界面、特定时机弹出的广告形式。插屏广告有全屏形式也有弹框形式，可直接关闭，也可在展示一定时间后关闭，广告收益可观。这种广告形式视觉冲击大，支持图片和视频两种类型，视频一般要求强制观看 5 、6秒后方可关闭。</p>
        </div>
        <!-- 多个图片排列 -->
        <div class="img-wrap-multiple">
          <div class="flex-img-flex">
            <div class="img-wrap">
              <img src="../../../assets/image/consult/info10/consult_info3.png" class="img">
            </div>
            <div class="img-wrap">
              <img src="../../../assets/image/consult/info10/consult_info4.png" class="img">
            </div>
            <div class="img-wrap">
              <img src="../../../assets/image/consult/info10/consult_info5.png" class="img">
            </div>
          </div>
          <div class="img-wrap-multiple-txt">不同形式的插屏</div>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">（2）插屏广告位设计优化思路</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">插屏广告入口一般都设置在用户发生动作之后的页面，流量较大的节点上，如游戏结束、章节结束、退出界面、暂停界面、切换界面等位置。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">插屏广告位更适合有使用功能的App。像工具类APP、阅读类APP、游戏类APP都是插屏广告设置比较多的媒体，具体的设计要求有以下几点：</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">a. 广告展示不要很突然</span></p>
          <p class="contentTxt"><span class="txtBolder">插屏广告是一个强制弹出的广告动作，并不是用户主动触发的行为，所以最好是在用户完成某一动作的环节自然插入。</span>比如一个关卡或章节结束之后，这时候用户看到插屏广告的接受度会好一点。不要在游戏或阅读过程中突然弹出，用户可能会比较反感，造成用户流失。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">b. 提前缓存广告</span></p>
          <p class="contentTxt">提前缓存广告的好处是用户在来到该广告节点时，保证有广告展示，跟开屏一样，为的是避免因广告加载问题造成曝光机会流失，从而降低收益。</p>
          <p class="contentTxt">相比开屏，插屏广告的缓存更容易实现，因为是在固定节点上，前面有大量的时间做缓存。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">c. 注意控制广告频次</span></p>
          <p class="contentTxt">不要在每次用户操作之后都展示广告，这样用户会连续反复看到插屏广告，容易造成：一是降低App使用感受，二是经常发生误操作。因为插屏广告是突然弹出的，所以用户非常容易造成点击。曝光和点击频次过高都有可能降低媒体评分，从而限制收益，严重的还会造成封号和扣款。</p>
        </div>
      </div>
      <!-- 文字标题3 -->
      <div class="title_txt">3. Banner广告</div>
      <!-- 节点3 -->
      <div class="node-warp3">
        <!-- 节点标题 -->
        <div class="title_node">（1）广告形式</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">以条块的形式出现在APP界面顶部或底部的广告形式，一般都会占用一点App的使用界面，但不会影响App的正常使用。如果用户一直停留在该界面，广告也会一直产生展示。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">Banner广告位是会不停的向广告平台请求广告的，因此Banner广告的展示量较大，但点击率较低。目前，Banner广告的eCPM也是最低的，Banner广告现在的数量也越来越少。由于收益太低，随着原生信息流、激励视频广告的兴起，开发者很少愿意再上这样的广告位。</p>
        </div>
        <!-- 多个图片排列 -->
        <div class="img-wrap-multiple">
          <div class="flex-img-flex">
            <div class="img-wrap">
              <img src="../../../assets/image/consult/info10/consult_info6.png" class="img">
            </div>
            <div class="img-wrap">
              <img src="../../../assets/image/consult/info10/consult_info7.png" class="img">
            </div>
            <div class="img-wrap">
              <img src="../../../assets/image/consult/info10/consult_info8.png" class="img">
            </div>
          </div>
          <div class="img-wrap-multiple-txt">Banner广告</div>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">（2）Banner广告位设计优化思路</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">a. 广告与界面有明显区隔，留出专属位置</span></p>
          <p class="contentTxt">广告位置要与使用界面存在明显的差异，留出专门的位置，这样用户不容易误点击。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">b. 不要对APP内容进行遮挡</span></p>
          <p class="contentTxt">Banner广告如果遮挡App内容，用户的体验可能会很差。如果遮挡内容恰好是用户感兴趣的，可能会造成用户的流失。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">c. 避免只有一个广告</span></p>
          <p class="contentTxt">用户只看一个广告，而该用户又对这个广告不感兴趣，那么就不会产生点击。适当的广告素材变化，在视觉上会有一定的新鲜感，对用户可能产生一定的吸引力。</p>
          <p class="contentTxt">这个方法会增加广告展示量，在一定程度上会造成eCPM降低或者CTR降低，但广告点击的绝对值会上升，从而带来收益的上升。</p>
          <p class="contentTxt">正常广告平台都会有轮播机制，只要用户还在这个位置，就会间隔一定时间向广告平台请求广告，避免用户一直看一个广告素材。开发者也可以根据自己需要来设置广告刷新机制，增加广告的丰富性。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">d. 注意不同机型的适配</span></p>
          <p class="contentTxt">由于现在智能手机分辨率比较丰富，所以应该测试不同分辨率以达到全机型都能充分适配。不同的手机品牌和型号，在分辨率上会有所不同，虽然有可能只是几个像素的微调，但视觉上还是有一定差异，所以需要重视。</p>
        </div>
      </div>
      <!-- 文字标题4 -->
      <div class="title_txt">4. 原生信息流广告</div>
      <!-- 节点4 -->
      <div class="node-warp4">
        <!-- 节点标题 -->
        <div class="title_node">（1）广告形式</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">原生信息流广告一般与App内容结合比较紧密的，具有原生性的广告形式，它根据App自身内容的不同在形态上有所不同。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">当前比较多的形式是信息流广告，信息流广告分为大图单图、小图多图、图文结合等形式，也有视频信息流形式，其特点是与信息资讯内容结合紧密，将广告软性的植入到App内容中，起到软性的品牌推广营销目的。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">原生信息流广告的转化效果较好，故收益也相对不错，是现在各类媒体都在大力发展的广告形式。</p>
        </div>
        <!-- 多个图片排列 -->
        <div class="img-wrap-multiple">
          <div class="flex-img-flex">
            <div class="img-wrap">
              <img src="../../../assets/image/consult/info10/consult_info9.png" class="img">
              <p class="txt">大图信息流</p>
            </div>
            <div class="img-wrap">
              <img src="../../../assets/image/consult/info10/consult_info10.png" class="img">
              <p class="txt">图文信息流</p>
            </div>
            <div class="img-wrap">
              <img src="../../../assets/image/consult/info10/consult_info11.png" class="img">
              <p class="txt">视频信息流</p>
            </div>
          </div>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">（2）原生信息流广告位设计优化思路</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">原生信息流广告的位置是多种多样的，主要根据App内容原生性，结合高量级的流量节点，综合考虑来设置广告位置。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">信息流广告适用的媒体也较多，对于开发者来说，合理的原生场景下出现广告是信息流广告成功的关键。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">a. 打造原生内容</span></p>
          <p class="contentTxt">信息流广告必须有原生内容来承载，所以满足App变现条件的一个重要基础是有源源不断的内容流，可以让人长时间停留、刷新，并产生大量广告曝光机会，这样的形式才能达到广告变现的目的。</p>
          <p class="contentTxt">消息、资讯等内容是比较合适的选择，新闻，UGC、PGC、等内容都是作为原生内容不错的形式。开发者可以自己生产内容，也可以直接爬取新闻资讯，主要看开发者对自身APP的发展规划。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">b. 寻找流量大的位置</span></p>
          <p class="contentTxt">信息流广告位本身在用户不断的浏览中，会持续刷新出广告，用户广告曝光的流量较多。但对于没有原生内容又不适合插入资讯的App来说，很多开发者会将信息流广告设置在用户使用App的过程中，以增加广告的展示机会。例如，拍照类App会设置在拍摄照片或进行图片处理之后的页面上，阅读类App会设置在章节结束后的页面上……</p>
          <p class="contentTxt">这些位置虽然已经算是该App中流量较大的位置，但在曝光机会上与资讯流中的反复刷新的信息流广告位比还是不多，后续转化行为上也不及资讯流中的信息流广告位，除非用户量非常大，活跃度非常高。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">c. 原生内容放置位置很重要</span></p>
          <p class="contentTxt">前面说到，App首屏/一级页面的PV量会远远大于其他页面，这里App的原生内容也是这样。资讯内容放在一级页面的PV量远远大于放在二级页面，二级、三级页面的用户达到率会成几何级倍数减少。所以以原生内容承载信息流广告的开发者，一定要注意，尽量要将资讯内容流放在用户最方便看到的地方，不要给广告展示增加难度，要让曝光展示机会最大化。</p>
        </div>
      </div>
      <!-- 文字标题5 -->
      <div class="title_txt">5. 激励视频广告</div>
      <!-- 节点5 -->
      <div class="node-warp4">
        <!-- 节点标题 -->
        <div class="title_node">（1）广告形式</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">用户通过观看广告视频可以获得奖励的广告形式，通常在15-30秒之间。其特点是用户主动观看广告，广告播放完成率高，广告效果好，eCPM超高，开发者收益丰厚。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">激励视频广告是IAA游戏类媒体的主要收入形式，以休闲游戏为代表。休闲游戏关卡多，用户使用频次高，可用来加广告的位置多，量大，产生的广告收益也高。非游戏类的像网赚类媒体设置激励类型广告也比较广泛。其他App也可制造适合的场景，设置激励视频广告，如虚拟积分消费环节，用户观看视频赚取积分奖励等。</p>
        </div>
        <!-- 多个图片排列 -->
        <div class="img-wrap-multiple">
          <div class="flex-img-flex">
            <div class="img-wrap">
              <img src="../../../assets/image/consult/info10/consult_info12.png" class="img">
            </div>
            <div class="img-wrap">
              <img src="../../../assets/image/consult/info10/consult_info13.png" class="img">
            </div>
          </div>
          <div class="img-wrap-multiple-txt">激励视频广告</div>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">（2）激励视频广告位设计优化思路</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">a. 广告入口</span></p>
          <p class="contentTxt">广告入口应设计在用户经常触及的界面，如游戏失败界面，并且增加漂浮、动画特效或设计像弹框式的广告入口。如果广告位入口比较深，我们需要在最初的入口上添加一些提示标志，或者增加一些动画特效，来增强广告场景的触达率。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">b. 合理的奖励内容</span></p>
          <p class="contentTxt">激励视频是必须要给用户奖励的，并且一定要明确地提示用户具体奖励是什么。而奖励内容应当根据App产品类型、产品场景去选择。不同类型的App在奖励上根据自己玩法特点设计即可，以金币、体力、积分等形式为主。</p>
          <p class="contentTxt">需要注意的是获得量和消耗量的设置要合理，奖励量要能够促进消耗，促进用户使用频率的上升，贡献更多的广告曝光机会，这样才是可持续的变现方式。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">c. 不要忽视激励视频广告结束后的页面</span></p>
          <p class="contentTxt">用户在看完激励视频广告后，可以通过弹窗提醒用户获取了新道具或是金币，增强用户获得感。同时，可在该页面上增加二次观看广告的入口，提醒用户可通过观看激励视频获取其它奖励，这样设置也是增加广告展示机会的一个有力方法。</p>
        </div>
      </div>
      <div class="consult-content">
        <p class="contentTxt">以上只是简单介绍一些常见的广告位设计思路和方法，更深入的广告位设计与变现效率之间的问题，还是要靠开发者不断的探索自身App的商业化潜力，在长期的变现运营中丰富并总结有用经验，合理设置及优化广告位置、广告形式。另外，了解广告平台的展示逻辑也是开发者在考虑App商业化时必不可少的准备工作，这对提升广告位的变现效率有着非常的重要意义。</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo8',
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
</style>
