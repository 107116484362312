import { Request } from '@/config/request';


// 收集用户信息接口
// export function collectUser(data = {}) {
//     return Request( 'http://ads.hubcloud.com.cn/p/2019/www/consult/add', {methods: 'POST', data});
// }
export function collectUser(data = {}) {
    return Request( process.env.VUE_APP_HREF_API_ADSCOPE + 'api/addVisitorInfo', {methods: 'POST', data});
    // return Request( process.env.VUE_APP_HREF_AD_SCOPE + 'api/addVisitorInfo', {methods: 'POST', data});
}

// 获取图片验证码接口
export function getCode(data = {}) {
    return Request( process.env.VUE_APP_HREF_AD_SCOPE + 'api/captchaImage', {methods: 'GET', data});
}

// 获取短信验证码
// export function getMessage(data = {}) {
//     return Request( 'http://map.hubcloud.com.cn/sender/sms/phone/code/adscope', {methods: 'POST', data});
// }
export function getMessage(data = {}) {
    // return Request( process.env.VUE_APP_HREF_AD_SCOPE + 'api/registerVerifyCode', {methods: 'POST', data});
    return Request( process.env.VUE_APP_HREF_API_ADSCOPE + 'api/registerVerifyCode', {methods: 'POST', data});
}

// 登录接口
export function login(data = {}) {
    return Request( process.env.VUE_APP_HREF_AD_SCOPE + 'api/login', {methods: 'POST', data});
}
