<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">轻变聚合变现诊断功能，帮助开发者明确流量损耗原因</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2021年5月31日</span>
      <span class="detailTxt">分类：产品功能</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="module1">
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">对开发者来说，维持变现业务稳定，保证广告收益增长，是变现运营过程中最重要的工作。但在变现链路中，广告填充率低，未填充部分原因不明，广告曝光成功率低，曝光流失量高，……，这些问题的原因分析和诊断却也是让开发者最头疼的事情。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">如何快速“剥丝抽茧”，归因诊断？开发者只需打开轻变聚合的“变现诊断”，无需另行接入，即能对当前变现策略进行“深度剖析诊断”，得到详细的多维数据分析指标报告，帮助开发者快速高效地调整售卖策略，实现收益最大化。</p>
      </div>
    </div>
    <!-------------------------------- 01 完善的数据展现和分析 -------------------------------->
    <div class="module2">
      <!-- 副标题 -->
      <div class="title_sub">01 完善的数据展现和分析</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">轻变聚合变现诊断涵盖了账户数据健康、账户收益健康、账户诊断数据、App诊断分析、广告位诊断分析、SDK错误诊断6大核心功能。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">通过对流量变现从“请求”到“点击”全链路的事件上报，详细呈现从“账户”到“App”,从“广告位”再到“渠道”的“错误诊断”。对各层级流量损耗做出量化指导，帮助开发者快速定位广告收益波动因素。</p>
      </div>
      <!-- 节点标题 -->
      <div class="title_node"><span class="txt_icon dot_icon"></span>账户级指示</div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info1/consult_info1.png" alt="infoImg" class="infoImg">
      </div>
      <!-- 节点标题 -->
      <div class="title_node"><span class="txt_icon dot_icon"></span>APP和广告位数据分析</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">
          <span class="txtBolder">此模块向开发者展现了从App和广告位两个维度的核心指标的图谱趋势图</span>
          ，比如：DAU、请求量、返回量、曝光量、请求频次、曝光频次、点击频次、新老用户占比等，用于指导开发者分析收益变化原因，做出调整策略。</p>
      </div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info1/consult_info2.png" alt="infoImg" class="infoImg">
      </div>
    </div>
    <!-------------------------------- 02 核心功能-错误诊断 -------------------------------->
    <div class="module3">
      <!-- 副标题 -->
      <div class="title_sub">02 核心功能-错误诊断</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">完善的数据展现和分析只是帮助开发者做好运营的第一步。如何以数据为导向快速指导开发者做出响应及时止损，才是变现运营的目标。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">在轻变聚合的SDK错误诊断模块，依据不同开发者接入的不同渠道详细展现了各渠道的数据漏斗图。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">
          <span class="txtBolder">渠道数据漏斗图给出了从App-聚合平台阶段的请求转发量，聚合平台-渠道阶段的接收请求量，渠道-APP阶段的广告返回量，以及最终广告曝光四个阶段的数据情况。</span>
        </p>
      </div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info1/consult_info3.png" alt="infoImg" class="infoImg">
      </div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">对于各阶段请求途中产生的流量损耗，轻变聚合也针对性给出了错误占比和对应策略，这大大降低开发者自行排查带来的时间、人力成本和收益损耗。</p>
      </div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info1/consult_info4.png" alt="infoImg" class="infoImg">
      </div>
    </div>
    <!-------------------------------- 03弱网环境下A媒体曝光率提升实验 -------------------------------->
    <div class="module3">
      <!-- 副标题 -->
      <div class="title_sub">03弱网环境下A媒体曝光率提升实验</div>
      <!-- 文字标题 -->
      <div class="title_txt">案例：A媒体接入某渠道后台，广告曝光率低。</div>
      <!-- 节点标题 -->
      <div class="title_node"><span class="txt_icon"></span>诊断分析：</div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info1/consult_info5.png" alt="infoImg" class="infoImg">
      </div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">某渠道：</span>广告曝光机会流失原因为广告曝光成功率低。</p>
        <p class="contentTxt">
          <span class="txtBolder">轻变聚合：</span>
          针对广告曝光率低，精确给出造成曝光率低的原因——广告展示面积、容器不可见、没有合适广告、广告展示时长、海外流量加载超时……其中，有
          <span class="txtBolder">接近60%的广告曝光是由于网络环境不佳导致广告加载不成功</span>
          ，部分APP用户处于弱网环境是问题成因。
        </p>
      </div>
      <!-- 节点标题 -->
      <div class="title_node"><span class="txt_icon"></span>诊断分析：</div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info1/consult_info6.png" alt="infoImg" class="infoImg">
      </div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">轻变聚合变现诊断的优势在于——可以全流程多角度的对从请求到最终曝光点击不同阶段的错误和数据进行量化，并指导开发者更明确地从不同角度进行优化。</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo3',
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
</style>
