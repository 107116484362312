<template>
  <div class="download-wrap">
    <!-- 公用头部 -->
    <headers :activeindex="6"></headers>
    <div class="download-banner-wrap">
      <div class="download-banner">
        <div class="title">SDK下载中心</div>
        <div class="subtitle">安全可靠，技术成熟</div>
        <img src="../../assets/image/download/download-right-bg.jpg" alt="背景" class="banner-bg-img">
      </div>
    </div>
    <div class="download-content">
      <div class="download-cont-wrap" v-for="(item, ind) of downloadList" :key="ind">
        <div class="title">{{ item.title }}</div>
        <div class="info-wrap">
          <img src="../../assets/image/download/ad-icon.jpg" alt="icon" class="info-icon-img">
          <div class="info-cont-wrap">
            <div class="info-title">{{ item.labelTitle }}</div>
            <div class="info-cont">
              <span class="cont">版本号：{{ item.versionNumber }}</span> |
              <span class="cont">更新时间：{{ item.updateTime }}</span>
            </div>
          </div>
          <div class="download-btn" @click="handleClick(item.downloadUrl)">下载SDK</div>
        </div>
        <div class="update-cont-wrap">
          <div class="update-cont-title">更新内容如下：</div>
          <div class="update-cont" v-for="(cont, index) of item.contentList" :key="ind + index + 10">
            {{ index+1 }} {{ cont }}
          </div>
        </div>
      </div>
    </div>
    <div class="buttom-wrap">
      <span class="tips" :style="{ 'width': '100%', 'text-align': 'center' }">All Rights Reserved. <span class="tips-number" @click="handleOpenNewPage">{{ IcpNumber }}</span></span>
      <!-- <span class="tips">
        All Rights Reserved. 沪ICP备 17036269号.
        <span class="tips-number" @click="handleOpenNewPage"><span class="bgSpan"></span>沪公网安备 31010402006552号</span></span> -->
    </div>
  </div>
</template>

<script>
  import headers from "@/component/header";

  export default {
    name: 'download',
    components: { headers },
    data() {
      return {
        downloadList: [
          {
            title: 'Android SDK 下载',
            labelTitle: '轻变聚合 SDK-Android',
            versionNumber: '4.90.2.14',
            updateTime: '2022/02/24 15:26:21',
            contentList: [ '【优化】开屏广告渲染逻辑', '【修复】已知问题' ],
            downloadUrl: 'http://resource.ad-scope.com.cn/sdk/AdScope%20SDK-Android.zip'
          },
          {
            title: 'iOS SDK 下载',
            labelTitle: '轻变聚合 SDK-iOS',
            versionNumber: '4.90.1.26',
            updateTime: '2022/02/24 10:02:56',
            contentList: [ '【优化】广告交互体验', '【修复】已知问题' ],
            downloadUrl: 'http://resource.ad-scope.com.cn/sdk/AdScope%20SDK-iOS.zip'
          },
        ]
      }
    },
    computed: {
      IcpNumber() {
        if (location.host.includes('ad-scope.com.cn')) return '沪ICP备20011467号-7';
        if (location.host.includes('adscope.com.cn')) return '沪ICP备20011467号-6';
        if (location.host.includes('adscope.cn')) return '沪ICP备20011467号-4';
        if (location.host.includes('adscope.xyz')) return '沪ICP备20011467号-5';
        if (location.host.includes('beizi.biz')) return '沪ICP备20011467号-1';
        if (location.host.includes('beizi.info')) return '沪ICP备20011467号-3';
        if (location.host.includes('beizi.online')) return '沪ICP备20011467号-2';
        return '沪ICP备20011467号';
      }
    },
    methods: {
      handleClick(url) { window.location.href = url; },
      // 点击备案号打开新页面
      handleOpenNewPage() {
        window.open('https://beian.miit.gov.cn/', "recordNumber");
      }
    }
  }
</script>

<style lang="scss" scoped>
  .download-wrap{
    background: #ebebeb;
  }
  .download-banner-wrap{
    width: 100%;
    height: 500px;
    margin-bottom: 50px;
    background: #fff;
    background: url("../../assets/image/download/download-bg.jpg") center center no-repeat;
    background-size: 100% 100%;
    .download-banner{
      width: 1200px;
      margin: 0 auto;
      position: relative;
      .title{
        padding: 200px 0 30px;
        font-weight: bolder;
        font-size: 54px;
        letter-spacing: 10px;
      }
      .subtitle{
        font-size: 26px;
        letter-spacing: 10px;
      }
      .banner-bg-img{
        position: absolute;
        right: 80px;
        top: 120px;
      }
    }
  }
  .download-content{
    width: 1200px;
    margin: 0 auto;
    .download-cont-wrap{
      /*width: 1120px;*/
      padding: 30px 40px;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
      margin-bottom: 30px;
      background: #fff;
      .title{
        font-size: 16px;
        font-weight: bolder;
        padding-bottom: 24px;
      }
      .info-wrap{
        height: 60px;
        .info-icon-img{
          float: left;
          margin-right: 25px;
        }
        .info-cont-wrap {
          color: #595959;
          display: inline-block;
          .info-title{
            font-size: 14px;
            padding: 8px 0 8px 0;
          }
          .info-cont {
            font-size: 10px;
          }
        }
        .download-btn {
          float: right;
          width: 200px;
          height: 40px;
          border: 2px solid #5db1ff;
          border-radius: 20px;
          color: #5db1ff;
          cursor: pointer;
          text-align: center;
          line-height: 40px;
          font-weight: bolder;
          display: inline-block;
          margin-top: 10px;
        }
      }
      .update-cont-wrap{
        padding: 40px;
        background: #fafafa;
        margin-top: 20px;
        .update-cont-title{
          font-size: 12px;
          font-weight: bolder;
          color: #575757;
          padding-bottom: 30px;
        }
        .update-cont{
          font-size: 12px;
          padding-bottom: 30px;
        }
        .update-cont:last-child{
          padding-bottom: 0;
        }
      }
    }
    .download-cont-wrap:last-child{
      margin-bottom: 60px;
    }
  }
  /* 备案信息 */
  .buttom-wrap {
    font-size: 14px;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    line-height: 50px;
    color: #b8b8b8;
    background: #1c1f25;
    .tips {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      .tips-number {
        cursor: pointer;
        padding-left: 8px;
      }
      .bgSpan{
        padding-left: 20px;
        background: url("../../assets/image/authentication.png") left no-repeat;
        background-size: 18px 20px;
        margin-right: 10px;
      }
    }
  }
</style>
