<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">轻变聚合聂子尧：从Waterfall到Bidding，开发者离Open Bidding还有多远</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2022年2月28日</span>
      <span class="detailTxt">分类：行业趋势</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="consult-content">
      <p class="contentTxt"><span class="txtBolder">聂子尧：轻变聚合上海倍孜网络技术有限公司创始人及首席执行官</span></p>
      <p class="contentTxt">聂子尧先生投身互联网广告行业十余年，在数字营销领域积累了丰富的实战经验及独到的行业见解。</p>
      <p class="contentTxt">曾先后任职于国内著名程序化广告公司，主要负责市场及产品规划工作，并以联合创始人身份推动公司从成立到成功登陆新三板。</p>
      <p class="contentTxt">凭借出色的程序化和移动广告产品研发经验，创立“倍孜网络”，带领公司团队搭建轻变聚合广告平台，为媒体商业化提供专业的变现服务和解决方案。</p>
    </div>
    <!-------------------------------- 01 -------------------------------->
    <div class="title_sub">Open Bidding是大势所趋</div>
    <div class="consult-content">
      <p class="contentTxt">数据显示，2021年我国移动互联网用户总数已经超过16亿，远大于我国人口数量。单日APP使用时长人均达5.3小时，而人均APP安装数量已上升至63款……一款APP想要占据用户心智，在5.3小时内获得大量的流量，已经难上加难。显然，移动互联网流量已经进入存量时代。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">从海外市场看，早在2020年末，作为全球数字广告之一的Facebook Audience Network将自己的流量采购方式从之前的黑盒模式（国内腾讯和字节的模式）变为了竞价模式。紧接着iOS14更新，Facebook要求从2021年3月31日起，开发者必须要将iOS迁移至Bidding出价，才能继续通过Audience Network获利，而Google则在更早之前就全面支持Open Bidding，国际主流广告平台的一系列变化，意味着海外流量采购方式已经从传统Waterfall瀑布流全面转向Bidding实时竞价。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">在国内，2021年4月份，穿山甲率先推出了GroMore平台，在此平台中穿山甲已经开放了竞价功能。而作为穿山甲在国内最有力的竞争对手——腾讯优量汇，也开放了多阶竞价，紧随其后也支持实时竞价（Bidding），2021年国内广告联盟虽然没有完全实现Open Bidding，但在Bidding技术的加持下渠道内竞价得以实现，媒体对实时竞价的期待值越来越高。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt"><span class="txtBolder">对于整个移动广告行业，应用内竞价模式（In-App Bidding）不仅能显著提升广告变现收益，而且未来势必将进一步完善移动广告流量变现生态，推动全行业自动化效率升级</span>，这无疑是移动流量采购方式程碑式的变化。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">开放应用内竞价（In-APP Bidding），通过Bidding，APP开发者相当于完成了“当家作主”的角色转变。<span class="txtBolder">开发者可以像拍卖方一样，将广告展示机会并行发送个多家潜在需求方，然后需求方按照“价高者得”的规则进行公开竞拍，最后APP广告位上展示的就是出价最高的那条广告，从而保证此次展示获得的是最高的广告收益。</span></p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">相较于之前广泛采用的waterfall广告分层模式，开发者在集成多家买方平台SDK后，根据前一日两个平台的平均结算价或底价来设置一个“高价买方在前，低价买方在后”的串行请求策略，当有广告曝光机会产生时，就按照历史价格高低依次请求。实际上广告主的出价是动态的，以瀑布流方式最后返回的广告有时候并不是当天价格最高的广告。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">而且接入多个渠道和平台提升收益的方式已经成为趋势，对于采用传统waterfall瀑布流进行广告变现的APP开发者来说，还要面临以下几个问题：<span class="txtBolder">（1）无论变现经验有多丰富，始终要投入人力去设置广告竞价的优先级，因为价格是不固定的，需要及时更新，耗时耗力效率低，在技术操作和收益上的不确定性非常高。（2）虽然在瀑布流方式中进行多层级设置，保证填充，但随着层级的增多，策略设计越复杂，请求延时等弊端就显现出来。另外在流量售卖方面waterfall形式不够透明，APP开发者没有足够的控制权与话语权。</span></p>
    </div>
    <div class="consult-content">
      <p class="contentTxt"><span class="txtBolder">应用内竞价最大的优势是可以让出价最高的广告方获得展示机会，开发者可以精准掌握每一次展示的价值，数据透明化程度高。而且自动化竞价避免了反复冗杂的人工瀑布流调整，节省了运营时间和成本。</span></p>
    </div>
    <!-------------------------------- 02 -------------------------------->
    <div class="title_sub">开发者需具备专业的优化能力</div>
    <div class="consult-content">
      <p class="contentTxt">传统Waterfall和In-App Bidding两种方法在收益上会有所区别，前面提到Waterfall的广告分层，主要基于渠道以往的经验设定策略，但价格是实时在变的，广告主可能会临时提高或降低出价，这样根据经验设定的策略不一定是最大收益，但Bidding不同，实时竞价是划分到每次广告请求的，Bidding的结果一定是本次所有需方的最高出价，这样所获得的收益即成为最高收益，所以，在收益上的差异肯定是存在的，这也是为什么开发者需要支持客户端竞价原因。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">另外，随着接入变现渠道数量的增多，平台运营以及多渠道优化等问题会越来越多。多渠道变现过程中比较重要的环节是策略优化，开发者需要具备流量拆分、多渠道流量排序控制等精细化运营能力，为市场全面开放竞价做准备。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">从形势上看，聚合广告技术取得了巨大的进步，实时竞价大大提升了变现优化的效率，带来了增长的可能性，是媒体、开发者不可错过的市场机遇。所以，以Bidding为核心的多渠道精细化运营能力是开发者在变现过程中必须具备的。</p>
    </div>
    <!-------------------------------- 03 -------------------------------->
    <div class="title_sub">做足准备，迎接Bidding时代的到来</div>
    <div class="consult-content">
      <p class="contentTxt">对广告平台方来说，从Waterfall到Bidding，不是一蹴而就的，还是要充分考虑市场的适应程度，广告平台需要做很多的工作，自身系统适应性开发，对开发者的技术支持，相关规则的制定等都要做好，从去年的形势来看，支持多阶价，混合竞价，实时竞价，推出聚合工具等等变化，我们可以看到，主流的广告平台已经在这样做了，转变的步伐正在加快。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">而对开发者来说，要做的事是支持客户端竞价，这牵扯很多的技术、运营、成本问题，相较于大厂，对于中小开发者来说，在市场预期和收益不明朗的情况下，既要做好自身产品，又要投入人力物力搞广告技术研发，显然有些难度。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">从海外方面来看，开发者对聚合工具的接受度较高，主要由于海外流量全面的开放竞价，使得第三方聚合成为开发者们最合适的选择，海外从Waterfall到Bidding的过渡，聚合工具扮演了重要的角色，海外模式给我们广大国内开发者提供了宝贵经验，比起自主研发，选择聚合平台，直接接入技术成熟的聚合广告联盟既能满足快速实现收益的需求，又可以获得Bidding广告优化能力，作为应对Bidding全面到来的准备，对媒体、开发者来说是非常合适的选择。</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo15'
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
</style>