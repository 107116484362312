<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">轻变聚合出席中国信通院2022数据安全峰会并联合签署绿色SDK产业生态倡议书</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2022年8月4日</span>
      <span class="detailTxt">分类：新闻动态</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="consult-content">
      <p class="contentTxt">2022年7月28日，由中国信息通信研究院安全研究所主办，数据安全共同体计划、大数据应用与安全创新实验室承办的“数据安全峰会2022”在北京成功召开。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">上海倍孜作为“绿色SDK产业生态共建行动”的首批参与企业，受邀参会并联合签署《绿色SDK产业生态倡议书》。</p>
    </div>
    <!-- <div class="img-warp">
      <img src="@/assets/image/consult/info17/consult_info1.png" alt="infoImg" class="infoImg">
      <p class="imgIntroduce">左一为上海倍孜</p>
    </div> -->
    <div class="consult-content">
      <p class="contentTxt">2021年6月，中国信通院紧跟技术发展趋势，依托深厚行业积累，启动了“SDK安全专项行动”，通过研究和探索，形成了覆盖多平台的完整测评体系，得到业内认可。本次大会启动的“绿色SDK产业生态共建行动”，更是在助力移动互联网产业健康有序发展，促进SDK产品安全水平提升。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">此次，上海倍孜受邀参与“绿色SDK产业生态共建行动”启动仪式，颁发“SDK 安全专项评测证书”，都是行业对上海倍孜SDK安全性、专业性的高度认可与信任。</p>
    </div>
    <div class="img-warp special-img-wrap">
      <img src="@/assets/image/consult/info17/consult_info2.png" alt="infoImg" class="infoImg special-img">
    </div>
    <div class="consult-content">
      <p class="contentTxt">上海倍孜是一家独立的、第三方广告技术公司，深知数据信息安全与合规的重要性，一直高度重视SDK安全问题，始终秉持着数据透明、对接安全、专业优质的第三方服务原则，制定了严格的合规审查制度，牢牢守住合格安全底线。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">自轻变聚合产品上线以来，坚持让每一位开发者都能够透明审查SDK运行模式和流程，拥有整体广告业务流程中数据的自主权和所有权。让移动应用开发者能够更高效、安全的开展商业化活动。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">未来，上海倍孜将贯彻“绿色SDK产业生态共建行动”所秉承开放共赢的理念，与15家企业一起强化行业自律，履行主体责任，促成“安全高效开发、规范透明集成、合理充分利用”的产业格局，为推动合法、有序、健康的生态发展作出贡献。</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo17'
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
  .special-img-wrap {
    text-align: center;
    .special-img {
      width: 50% !important;
    }
  }
</style>
