/* require == > common的写法
   require将图片作为模块加载进去，就是将图片当成模块先引进来，再绑定。 */
export const caseList = [
  {
    id: '1',
    imgUrl: require('../../../assets/image/client-case/case1.png'),
    title: '工具类媒体收益提升案例（一）',
    content: '倍孜SDK多渠道流量细分，助力免费Wi-Fi工具APP高效提升广告收益50%'
  },
  {
    id: '2',
    imgUrl: require('../../../assets/image/client-case/case2.png'),
    title: '工具类媒体收益提升案例（二）',
    content: '智能分量，助力农业测量APP有效提高填充率，实现收益增长'
  },
  {
    id: '3',
    imgUrl: require('../../../assets/image/client-case/case3.png'),
    title: '工具类媒体收益提升案例（三）',
    content: '以价格优先为渠道策略，串并行结合，多渠道分发，有效保证日历类App达到最佳收益'
  },
  {
    id: '4',
    imgUrl: require('../../../assets/image/client-case/case4.png'),
    title: '阅读类媒体收益提升案例（一）',
    content: 'beizi sdk高频广告位流量分频次请求分量，助力小说阅读APP流量变现单价提升'
  },
  {
    id: '5',
    imgUrl: require('../../../assets/image/client-case/case5.png'),
    title: '阅读类媒体收益提升案例（二）',
    content: 'A/B测试，寻找高/低频用户流量价格锚点，分量串行，实现搜书阅读APP的收益提升',
  },
  {
    id: '6',
    imgUrl: require('../../../assets/image/client-case/case6.png'),
    title: '生活类媒体收益提升案例',
    content: '多渠道接入，拓展买方市场，有效提升社区生活类APP广告收益',
  },
  {
    id: '7',
    imgUrl: require('../../../assets/image/client-case/case7.png'),
    title: '社交类媒体收益提升案例',
    content: '分量串行，扩展售卖渠道同时，保证填充最大 确保社交APP收益最大',
  },
  {
    id: '8',
    imgUrl: require('../../../assets/image/client-case/case8.png'),
    title: '教育类媒体收益提升案例',
    content: '智能混合策略，优质优价，高效提升教育类APP收益',
  },
  {
    id: '9',
    imgUrl: require('../../../assets/image/client-case/case9.png'),
    title: '休闲游戏类媒体变现案例',
    content: '按频次拆分用户流量，有效提升益智类游戏广告单价，收益提升30%',
  }
];
export const caseInfoData = [
  {
    id: '1',
    titleMain: '工具类案例一∣轻变聚合助力工具类媒体高效提升50%广告收益',
    mainTxt: [
      '对于工具类APP来说，只要产品功能做得好基本不怕没流量，所以工具类产品普遍有着广泛的用户基础。但是“用完即走”，可替代成本低，功能单一的属性，导致工具类移动应用用户粘性差，留存和使用率低。所以工具类APP虽然活跃用户规模大，但商业变现之路却并不容易。',
      '一般工具类APP商业模式比较单一，流量变现是快速实现商业价值最佳途径之一，但是“简单粗暴”的投放方式非常容易“伤”身。如何破局？以下是来自轻变聚合的实践案例，让我们一起探究如何对用户流量进行精细化运营，实现工具类APP的持续生长。'
    ],
    subTitle: '精细化分层优化，突破收入增长天花板',
    infoContent: [
      {
        title: '某WIFI软件',
        content: '免费WiFi管家连接工具，可以帮助用户免费获取商场、快餐等地方的wifi密码，轻松自动联网。',
        urlList: [
          require('../../../assets/image/client-case/case1/txt_info1.png'),
          require('../../../assets/image/client-case/case1/txt_info2.png'),
        ]
      },
      {
        title: '媒体变现问题分析',
        content: '媒体在接入多家主流渠道情况下，采取渠道分量并行的售卖策略。核心渠道分量较多，但收益不稳定，收入增量空间已近天花板。由于并行分量采取平均分配方式，容易造成广告位未填充部分的损失。',
        urlList: [
          require('../../../assets/image/client-case/case1/txt_info3.png'),
        ]
      },
      {
        title: '运营调优策略',
        content: '在不影响媒体原渠道的基础上，轻变聚合对媒体采取了全新的优化策略：精细化分层优化，对不同设备和不同网络环境进行用户区分。对于iOS用户，通过识别网络环境分成优质网络用户和弱网用户。优质网络用户采用串行策略，而对于弱网用户采用串并行策略。对于安卓用户经过分量A/B测试对比，进行串行策略。',
        urlList: [
          require('../../../assets/image/client-case/case1/txt_info4.png'),
        ]
      },
      {
        title: '调优效果',
        content: '通过对比，采用轻变聚合全新策略的流量组eCPM涨幅较原策略流量组超过20%，曝光收益涨幅超30%。',
        urlList: [ ]
      }
    ],
    articleSelect: [
      {
        id: 'info2',
        label: '下一篇',
        title: '工具类案例二∣轻变聚合助力工具类媒体高效提升50%广告收益'
      }
    ]
  },
  {
    id: '2',
    titleMain: '工具类案例二∣轻变聚合助力工具类媒体高效提升50%广告收益',
    mainTxt: [
      '对于工具类APP来说，只要产品功能做得好基本不怕没流量，所以工具类产品普遍有着广泛的用户基础。但是“用完即走”，可替代成本低，功能单一的属性，导致工具类移动应用用户粘性差，留存和使用率低。所以工具类APP虽然活跃用户规模大，但商业变现之路却并不容易。',
      '一般工具类APP商业模式比较单一，流量变现是快速实现商业价值最佳途径之一，但是“简单粗暴”的投放方式非常容易“伤”身。如何破局？以下是来自轻变聚合的实践案例，让我们一起探究如何对用户流量进行精细化运营，实现工具类APP的持续生长。'
    ],
    subTitle: '以提升填充为核心优化目标，实现小流量媒体收益增长',
    infoContent: [
      {
        title: '某测量软件',
        content: '精确实时作业面积测量（自带重复去除功能）和作业计价功能的专业测量APP。用户可根据高精度地图数据生成及储存作业地块面积，周长，位置等信息，实时取得精确作业信息。',
        urlList: [
          require('../../../assets/image/client-case/case2/txt_info1.png'),
          require('../../../assets/image/client-case/case2/txt_info2.png'),
        ]
      },
      {
        title: '媒体变现问题分析',
        content: '媒体只接入渠道A、渠道B两个广告平台，媒体用户量不高，日活低。只有一个广告位变现，收入低。技术开发实力弱，无优化经验，存在流量浪费。',
        urlList: [
          require('../../../assets/image/client-case/case2/txt_info3.png'),
        ]
      },
      {
        title: '运营调优策略',
        content: '接入轻变聚合后，通过测试对媒体采取了渠道串行，智能分量优化策略，增加填充机会。同时经过测试分析，发现渠道A的广告素材更适合农业类人群，可以获得高收益。',
        urlList: [
          require('../../../assets/image/client-case/case2/txt_info4.png'),
        ]
      },
      {
        title: '调优效果',
        content: '对于小流量媒体，轻变聚合以提升填充为核心优化目标。通过策略使用前和使用后对比，该媒体接入轻变聚合优化策略后，广告填充率明显增长90%，eCPM提升50%。',
        urlList: [ ]
      }
    ],
    articleSelect: [
      {
        id: 'info1',
        label: '上一篇',
        title: '工具类案例一∣轻变聚合助力工具类媒体高效提升50%广告收益'
      },
      {
        id: 'info3',
        label: '下一篇',
        title: '工具类案例三∣多渠道流量细分售卖，低量级工具类媒体突破收益增长瓶颈'
      }
    ]
  },
  {
    id: '3',
    titleMain: '工具类案例三∣多渠道流量细分售卖，低量级工具类媒体突破收益增长瓶颈',
    mainTxt: [
      '对于工具类APP来说，只要产品功能做得好基本不怕没流量，所以工具类产品普遍有着广泛的用户基础。但是“用完即走”，可替代成本低，功能单一的属性，导致工具类移动应用用户粘性差，留存和使用率低。所以工具类APP虽然活跃用户规模大，但商业变现之路却并不容易。',
      '一般工具类APP商业模式比较单一，流量变现是快速实现商业价值最佳途径之一，但是“简单粗暴”的投放方式非常容易“伤”身。如何破局？以下是来自轻变聚合的实践案例，让我们一起探究如何对用户流量进行精细化运营，实现工具类APP的持续生长。'
    ],
    subTitle: '以价格优先为渠道策略，串并行结合，多渠道分发，保证收益最佳',
    infoContent: [
      {
        title: '某日历类软件',
        content: '融合中国传统文化的日历及各种黄历宜忌、节气节日、出门解运等于一身，提供日历、日程时间管理、天气、各类资讯信息及相关小工具之余，还提供大量运程测算类付费增值功能。',
        urlList: [
          require('../../../assets/image/client-case/case3/txt_info1.png'),
          require('../../../assets/image/client-case/case3/txt_info2.png'),
        ]
      },
      {
        title: '媒体变现问题分析',
        content: '媒体接入两个主流变现平台，人工切换，收益控制凭经验，用户量低，广告形式单一，可变现的资源少。未做流量变现方面的技术开发/无开发能力，缺少必要的运营基础。',
        urlList: [
          require('../../../assets/image/client-case/case3/txt_info3.png'),
        ]
      },
      {
        title: '运营调优策略',
        content: '接入轻变聚合后，根据App流量特点和自身情况，采取区分用户，智能分量，混合串并行的策略。优质网络用户，串行请求，并在原有两家渠道上增加另一串行渠道，保证填充，弱网用户，SDK优先，API渠道预加载，减少曝光损失。',
        urlList: [ ]
      },
      {
        title: '调优效果',
        content: '通过串并结合的请求策略及扩充的渠道资源，有效的弥补流量低、渠道少、缺乏优化经验的问题，使得填充率提升7.25%，eCPM单价提升近10%，平均收益提升超过15%。',
        urlList: [ ]
      }
    ],
    articleSelect: [
      {
        id: 'info2',
        label: '上一篇',
        title: '工具类案例二∣轻变聚合助力工具类媒体高效提升50%广告收益'
      },
      {
        id: 'info4',
        label: '下一篇',
        title: '阅读类案例一∣轻变聚合高频广告位收益提升策略，助力小说阅读APP收益增幅30%'
      }
    ]
  },
  {
    id: '4',
    titleMain: '阅读类案例一∣轻变聚合高频广告位收益提升策略，助力小说阅读APP收益增幅30%',
    mainTxt: [
      '阅读类APP用户登录频次高，使用时长长，拥有大量的流量资源，选择流量变现成为阅读类媒体商业化的必然选择，但用户广告频次高，广告库存以Banner量最大，这些问题都严重制约了阅读类媒体变现收益，尤其是频次高，使得广告单价降低，且账号被封风险大大增加。阅读类媒体如何突破现状，实现收益增长，持续商业化变现？以下是来自轻变聚合的实践案例。'
    ],
    subTitle: '高频广告位流量，分频次请求分量',
    infoContent: [
      {
        title: '某小说阅读App',
        content: '一款免费在线小说阅读软件，为用户提供各类热门小说，书籍更新速度快，超多热门小说，全部免费观看。',
        urlList: [
          require('../../../assets/image/client-case/case4/txt_info1.png'),
          require('../../../assets/image/client-case/case4/txt_info2.png'),
        ]
      },
      {
        title: '媒体变现问题分析',
        content: '媒体对接多家国内主流变现渠道，有SDK对接与API对接形式，且串行请求，广告请求超时问题严重，有底价规则，大量流量没有广告未填充，Banner位曝光偏高，eCPM被拉低，影响收益提升。',
        urlList: [
          require('../../../assets/image/client-case/case4/txt_info3.png'),
        ]
      },
      {
        title: '运营调优策略',
        content: '接入轻变聚合后，采取混合策略的方法，高频广告位按频次切分流量，低频流量给到低频价格最优渠道，高频流量给到高频最优渠道，并向下增加一个串行渠道，保证填充，底价规则下，未填充流量走分频串行策略。',
        urlList: [
          require('../../../assets/image/client-case/case4/txt_info4.png'),
        ]
      },
      {
        title: '调优效果',
        content: '通过混合策略下，流量收益显著增长，分频组预期收益平均高出未分频组35%，开屏广告填充流失降低，填充率提升15%，收入涨幅超过30%。',
        urlList: [ ]
      }
    ],
    articleSelect: [
      {
        id: 'info3',
        label: '上一篇',
        title: '工具类案例三∣多渠道流量细分售卖，低量级工具类媒体突破收益增长瓶颈'
      },
      {
        id: 'info5',
        label: '下一篇',
        title: '阅读类案例二∣巧用A/B测试，有效解决阅读类APP变现困境'
      }
    ]
  },
  {
    id: '5',
    titleMain: '阅读类案例二∣巧用A/B测试，有效解决阅读类APP变现困境',
    mainTxt: [
      '阅读类APP用户登录频次高，使用时长长，拥有大量的流量资源，选择流量变现成为阅读类媒体商业化的必然选择，但用户广告频次高，广告库存以Banner量最大，这些问题都严重制约了阅读类媒体变现收益，尤其是频次高，使得广告单价降低，且账号被封风险大大增加。阅读类媒体如何突破现状，实现收益增长，持续商业化变现？以下是来自轻变聚合的实践案例。'
    ],
    subTitle: 'A/B测试，寻找高/低频用户流量价格锚点，分量串行，提升收益',
    infoContent: [
      {
        title: '某小说阅读App',
        content: '一款搜书引擎手机客户端，最新版为爱阅读的人群提供精准快速的网络资源.搜索服务，小说资源非常丰富。',
        urlList: [
          require('../../../assets/image/client-case/case5/txt_info1.png'),
          require('../../../assets/image/client-case/case5/txt_info2.png'),
        ]
      },
      {
        title: '媒体变现问题分析',
        content: '媒体对接多家变现渠道，用户频次高，在主流渠道上的变现收益数据经常会被核减，账户有被封不结算风险。eCPM单价低，有一定的变现经验，开发了按比例分量请求渠道功能，但其他优化策略较少，需要进一步开发才能实现。',
        urlList: [
          require('../../../assets/image/client-case/case5/txt_info3.png'),
        ]
      },
      {
        title: '运营调优策略',
        content: '轻变聚合通过A/B测试的方法，找到价格最佳渠道及频次锚点，叠加广告类型，智能分量请求，激励视频给到价格最高的渠道A，开屏流量给到开屏价格最高的渠道B，高频广告位信息流、Banner20次以上用户请求给到渠道C，20次以下给到渠道A。',
        urlList: [ ]
      },
      {
        title: '调优效果',
        content: '通过采用A/B测量功能，科学高效制定最佳收益策略，eCPM提升达30%，预期总收入提升25%。',
        urlList: [ ]
      }
    ],
    articleSelect: [
      {
        id: 'info4',
        label: '上一篇',
        title: '阅读类案例一∣轻变聚合高频广告位收益提升策略，助力小说阅读APP收益增幅30%'
      },
      {
        id: 'info6',
        label: '下一篇',
        title: '生活类案例∣多渠道接入，拓展买方市场，有效保证生活类APP填充'
      }
    ]
  },
  {
    id: '6',
    titleMain: '生活类案例∣多渠道接入，拓展买方市场，有效保证生活类APP填充',
    mainTxt: [
      '生活类APP具有粘性高，用户使用场景固定，用户群稳定的特点，在商业化变现市场上，流量价值大，但对于刚刚开始商业化的开发者来说，流量变现还有很多问题，以下是轻变聚合的实践案例，希望可以给到该类在商业化初期的媒体一定的帮助。'
    ],
    subTitle: '多渠道接入，保填充，增收益',
    infoContent: [
      {
        title: '某社区生活软件',
        content: '一款手机社区物业管理服务软件，能够为用户提供如移动可视对讲，智能门前留影、远程开门等服务，以及社区生活，购物，资讯，房产等信息。',
        urlList: [
          require('../../../assets/image/client-case/case6/txt_info1.png'),
          require('../../../assets/image/client-case/case6/txt_info2.png'),
        ]
      },
      {
        title: '媒体变现问题分析',
        content: '媒体对接渠道单一，无专职人员运营，以信息流广告为主，单价和填充都远低于同类广告形式行业水平，无优化经验，不知道问题在哪里，收益提升有困难。',
        urlList: [
          require('../../../assets/image/client-case/case6/txt_info3.png'),
        ]
      },
      {
        title: '运营调优策略',
        content: '轻变聚合采取分广告位，多渠道串并行结合的策略，保证填充同时获得最大渠道收益，提升变现收入，开屏流量串行渠道一、渠道二，信息流流量分比例并行请求主流高收益渠道，高收益渠道下串行渠道三，Banner广告并行请求渠道一、渠道二，同时向下串行Banner广告价格较高的渠道四、渠道三。',
        urlList: [
          require('../../../assets/image/client-case/case6/txt_info4.png'),
        ]
      },
      {
        title: '调优效果',
        content: '通过串并结合的广告请求策略，使得填充率得到提升，填充率达到90%，预期收益环比增长50%以上。',
        urlList: [ ]
      }
    ],
    articleSelect: [
      {
        id: 'info5',
        label: '上一篇',
        title: '阅读类案例二∣巧用A/B测试，有效解决阅读类APP变现困境'
      },
      {
        id: 'info7',
        label: '下一篇',
        title: '社交类案例∣多渠道接入，拓展买方市场，有效保证生活类APP填充'
      }
    ]
  },
  {
    id: '7',
    titleMain: '社交类案例∣多渠道接入，拓展买方市场，有效保证生活类APP填充',
    mainTxt: [
      '社交类APP具有用户使用频次高，使用时长长，用户年轻化的特点，在流量变现市场是价格较高的媒体类型，但是如果运营优化能力不足，也会使收益难以达到预期，那么社交类媒体应该采取何种方式运营显得非常重要，轻变聚合在这里给出具体案例，希望可以给到该类媒体一定的帮助。'
    ],
    subTitle: '分量串行，扩展售卖渠道同时，保证填充最大  收益最大',
    infoContent: [
      {
        title: '某社交软件',
        content: '为情侣打造的专属App，帮助情侣记录恋爱日常之外，更提供了一系列互动工具，如“装扮小家”情侣家园游戏。',
        urlList: [
          require('../../../assets/image/client-case/case7/txt_info1.png'),
          require('../../../assets/image/client-case/case7/txt_info2.png'),
        ]
      },
      {
        title: '媒体变现问题分析',
        content: '媒体采用渠道串行，渠道A收益较好，以渠道A收益为主，渠道B广告拉取超时问题严重，整体收益并没有明显提高，两个渠道变现时间较久，单价和整体收益开始走低，没有有效的解决策略。',
        urlList: [
          require('../../../assets/image/client-case/case7/txt_info3.png'),
        ]
      },
      {
        title: '运营调优策略',
        content: '轻变聚合采取按频次区分用户流量，分量并行，扩展平台，提升单价，避免广告请求超时，根据A/B测试结果，用户6次及以下请求分比例并行渠道A70%、渠道B20%、渠道C10%，6次及以上请求给渠道B。',
        urlList: [ ]
      },
      {
        title: '调优效果',
        content: '通过智能分量请求不同渠道，获得最佳收益，使得曝光成功率提升到99%，单价提升14.9%，月收入提升15%。',
        urlList: [ ]
      }
    ],
    articleSelect: [
      {
        id: 'info6',
        label: '上一篇',
        title: '生活类案例∣多渠道接入，拓展买方市场，有效保证生活类APP填充'
      },
      {
        id: 'info8',
        label: '下一篇',
        title: '教育类案例∣多渠道接入，拓展买方市场，有效保证生活类APP填充'
      }
    ]
  },
  {
    id: '8',
    titleMain: '教育类案例∣多渠道接入，拓展买方市场，有效保证生活类APP填充',
    mainTxt: [
      '教育类APP在疫情期间释放出巨大的商业化潜力，流量增量明显，且超级垂直的教育人群受到广告主们的青睐，但多数教育类APP的开发者都没有流量变现运营经验，对于突然到来的用户高峰措手不及，且教育类媒体商业化也比较敏感，受到多方面的限制，轻变聚合这里给出教育类具体变现运营的成功案例，希望能够为该类媒体的变现之路起到一定帮助。'
    ],
    subTitle: '智能串行，先匹配高价广告渠道，优质优价，帮助收益提升',
    infoContent: [
      {
        title: '某教育软件',
        content: '某地市教育系统专为教育管理打造的教育信息沟通工具，主要针对师生人群。',
        urlList: [
          require('../../../assets/image/client-case/case8/txt_info1.png'),
          require('../../../assets/image/client-case/case8/txt_info2.png'),
        ]
      },
      {
        title: '媒体变现问题分析',
        content: '媒体处在商业化初期，对流量变现市场情况不是很了解，无专业运营人员，仅对接一家渠道，缺乏优化提升经验，为保证用户体验，只有一种广告形式，无法增加新的广告位置和形式，流量填充率也不高，收益与用户量级不匹配，远低于同等量级其他类型的APP。',
        urlList: [
          require('../../../assets/image/client-case/case8/txt_info3.png'),
        ]
      },
      {
        title: '运营调优策略',
        content: '接入轻变聚合后，通过系统诊断未填充原因，发现没有合适的广告占较大比例，针对性的采用多渠道变现的策略，按收益高低串行请求渠道广告，高收益、返回时间快的渠道A在前，低收益、返回速度慢的渠道B在后，在渠道A无法填充时请求渠道B，保证请求被全部填充，并且建议在不打扰用户使用的情况下，添加原生化的信息流广告形式，增加流量收益。',
        urlList: [ ]
      },
      {
        title: '调优效果',
        content: '通过多渠道策略优化流量变现后，填充低的情况得到有效缓解，填充率提升到93%，新增广告位，可售卖的流量库存增加30%，总收益提升55%。',
        urlList: [ ]
      }
    ],
    articleSelect: [
      {
        id: 'info7',
        label: '上一篇',
        title: '社交类案例∣多渠道接入，拓展买方市场，有效保证生活类APP填充'
      },
      {
        id: 'info9',
        label: '下一篇',
        title: '休闲游戏类案例∣多渠道高效管理，分用户混合策略，助力休闲游戏精细化运营收益提升'
      }
    ]
  },
  {
    id: '9',
    titleMain: '休闲游戏类案例∣多渠道高效管理，分用户混合策略，助力休闲游戏精细化运营收益提升',
    mainTxt: [
      '休闲游戏具有用户粘性高，停留时间长，频次高的特点，有大量的流量资源，所以，在国内基本的商业模式都是游戏免费，然后通过流量变现，休闲游戏流量是移动广告市场上重要的组成部分，占移动广告比例很大，可以说具有举足轻重的作用，因此，休闲游戏的商业化运营能力的高低对收益的影响也会比较大，轻变聚合根据该类媒体的实际需求，以精细化运营提效增益为出发点，为开发者提供高效的解决方案，以下为具体实践案例：'
    ],
    subTitle: '高频广告位流量拆分，分频次请求广告，单价提升',
    infoContent: [
      {
        title: '某益智游戏APP',
        content: '一款休闲益智游戏，手游合成与消除两种玩法合二为一，变化无穷。滑动手指即可体验数字与六角激情碰撞，功能玩新颖独特，操作简单上手，让人其乐无穷。',
        urlList: [
          require('../../../assets/image/client-case/case9/txt_info1.png'),
          require('../../../assets/image/client-case/case9/txt_info2.png'),
        ]
      },
      {
        title: '媒体变现问题分析',
        content: '媒体有初级的运营优化意识，对接多渠道变现，主流渠道基本接入，但只有串行请求，设置串行渠道过长，请求超时现象严重，Banner广告为主要流量，用户广告频次高，ecpm单价被拉低，长期在主流渠道变现，收益会降低，优化策略较少，开发针对用户拆分流量的难度高，成本大，投入产出是未知数，短期内不会进行。',
        urlList: [
          require('../../../assets/image/client-case/case9/txt_info3.png'),
        ]
      },
      {
        title: '运营调优策略',
        content: '轻变聚合通过分频实验，确定高低频价格最优渠道，串并结合的混合策略，有效降低广告请求超时的问题，提升填充率和曝光成功率，低频流量给到渠道A，高频流量给到渠道B，并在并行后增加串行',
        urlList: [
          require('../../../assets/image/client-case/case9/txt_info4.png'),
        ]
      },
      {
        title: '调优效果',
        content: '通过分频的混合策略，轻变聚合变现组的平均收益高于媒体自主变现20%，Banner高频广告位的单价有显著提升，比媒体自主变现的单价高出25%左右。',
        urlList: [ ]
      }
    ],
    articleSelect: [
      {
        id: 'info8',
        label: '上一篇',
        title: '教育类案例∣多渠道接入，拓展买方市场，有效保证生活类APP填充'
      }
    ]
  }
];
