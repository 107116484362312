<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">轻变聚合加入中国互联网协会！</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2023年8月30日</span>
      <span class="detailTxt">分类：新闻动态</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="consult-content">
      <p class="contentTxt">数字时代，信息安全尤其是数据隐私信息保护已经成为社会共识。近日，轻变聚合（上海倍孜网络技术有限公司）已通过相关组织机构评审，符合ISO/IEC 27701：2019标准，获得隐私信息管理体系认证证书，标志着轻变聚合在信息安全及数据隐私保护能力方面达到了国际标准。</p>
    </div>
    <div class="img-warp special-img-wrap">
      <img src="@/assets/image/consult/info29/consult_info1.png" alt="infoImg" class="infoImg special-img">
      <img src="@/assets/image/consult/info29/consult_info2.png" alt="infoImg" class="infoImg special-img">
    </div>
    <div class="consult-content">
      <p class="contentTxt">ISO/IEC 27701:2019,即隐私信息管理体系（PIMS），是ISO/IEC27001（信息安全管理体系，ISMS）和ISO/IEC27002（信息安全控制实践指南 ）在隐私信息管理的一个扩展标准，适用于全球范围内，包括企业、政府及各类组织的隐私信息管理安全认证。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">本次轻变聚合认证范围包含了与计算机软件开发（为移动应用开发者提供流量商业化的SaaS解决平台、全网流量聚合商业化管理平台）相关的各类数据隐私安全管理活动，表明轻变聚合所使用的数据隐私管理方式方法符合国际隐私信息安全标准，轻变聚合时刻践行对保护合作方与用户数据隐私的承诺。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">认证的通过对轻变聚合完善和持续优化数据隐私信息安全管理，以及实现用户隐私保护的标准化与合规化具有重要意义，也是对轻变聚合秉承护航信息安全、保护用户隐私、将数字安全保障作为企业核心责任的重要印证。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">未来，轻变聚合将继续秉持保护隐私信息安全理念，围绕信息安全核心能力，持续提升隐私信息安全管理水平，成为数字安全领域的排头兵。</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo28'
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
  .special-img-wrap {
    text-align: center;
    .special-img {
      width: 50% !important;
    }
  }
</style>
