<template>
  <div class="index-wrap">
    <!-- 公用头部 -->
    <headers :activeindex="0"></headers>
    <!-- 轮播图部分 -->
    <div class="swiper-contanier">
      <swiper :options="swiperOption" class="swiper-wrap" ref="mySwiper">
        <!-- <swiper-slide class="common">
          <div class="inner-wrap">
            <div class="banner-txt-wrap banner-txt-wrap4">
              <p class="income-subtitle">
                <span class="income-subtitle-txt">轻变聚合开源聚合广告管理系统</span>
                <img class="banner4-icon" src="../../assets/image/index/banner4-icon.png">
              </p>
              <p class="banner-title banner-title4">系统开源 全面自主</p>
              <p class="income-content">全面促进APP广告商业化加速发展</p>
              <p class="banner-btn banner-btn3" @click="handleService">了解详情</p>
            </div>
            <div class="banner-img-wrap banner-img-wrap4">
              <img src="../../assets/image/index/banner4.png">
            </div>
          </div>
        </swiper-slide> -->
        <!-- 第一个 -->
        <swiper-slide class="common">
          <div class="inner-wrap">
            <div class="banner-txt-wrap">
              <p class="banner-title banner-title1">完全免费</p>
              <p class="income-content">源代码交付或SaaS部署，100%真免费；</p>
              <p class="banner-title banner-title1">真正开源</p>
              <p class="income-content">各端代码全交付，独立自主安全可控；</p>
              <p class="banner-title banner-title1">高效变现</p>
              <p class="income-content">多渠道管理、精细化投放策略，更好变现更高收益</p>
              <p class="banner-title banner-title1">安全合规</p>
              <p class="income-content">承诺绝不留后门，公平公开，保证流量安全</p>
            </div>
            <div class="banner-img-wrap banner-img-wrap1">
              <img src="../../assets/image/index/banner1.png" class="banner-img1">
            </div>
          </div>
        </swiper-slide>
        <!-- 第二个 -->
        <swiper-slide class="common">
          <div class="inner-wrap">
            <div class="banner-txt-wrap">
              <p class="banner-title">让移动应用<span class="specialTxt orangeColor">变</span>得更好</p>
              <p class="banner-content">您的媒体变现与增收一体化服务平台</p>
              <p class="banner-btn" @click="goRegister">立即接入</p>
            </div>
            <div class="banner-img-wrap banner-img-wrap2">
              <img src="../../assets/image/index/banner2.png">
            </div>
          </div>
        </swiper-slide>
        <!-- 第三个 -->
        <swiper-slide class="common">
          <div class="inner-wrap">
            <div class="banner-txt-wrap">
              <p class="banner-title banner-title3">实现提效增收工具</p>
              <p class="income-content">通过Bidding实时竞价技术，智能化流量分配策略，</p>
              <p class="income-content">让您的每一次请求都能获得最大收益；</p>
              <p class="income-content">在节约运营成本的同时，最大程度提高广告变现收益。</p>
              <p class="banner-btn orangeBg banner-btn3" @click="handleOrder">预约演示</p>
            </div>
            <div class="banner-img-wrap banner-img-wrap4">
              <img src="../../assets/image/index/banner4.png">
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <!-- "优加"广告联盟 -->
    <div class="space-contanier">
      <!-- <div class="main-title"> -->
        <!--<p>广告联盟</p>-->
        <!-- <img src="../../assets/image/index/spaceLogo.png"> -->
      <!-- </div> -->
      <div class="vice-title">提供媒体高质量广告变现</div>
      <div class="space-info-btn" @click="goToDetail(1)">了解详情</div>
      <div class="contanier-wrap">
        <div class="space-union-wrap" v-for="item in spaceList" :key="item.id">
          <img class="union-img" :src="item.imgUrl">
          <div class="union-title">{{ item.title }}</div>
          <p class="union-cont" v-for="(cont, index) in item.content" :key="index">{{ cont }}</p>
        </div>
      </div>
    </div>
    <!-- 变现提效增收工具 -->
    <div class="tool-contaniner">
      <div class="tool-title">变现提效增收工具</div>
      <div class="tool-vice-title">智能化提升媒体收益</div>
      <div class="tool-info-btn" @click="goToDetail(2)">了解详情</div>
      <div class="swiper tool-swiper">
        <swiper class="swiper-wrapper" :options="toolSwiperOption">
          <swiper-slide v-for="item in toolList" :key="item.id">
            <div class="tool-content-wrap">
              <div class="img-wrap">
                <img class="tool-img" :src="item.imgUrl">
              </div>
              <div class="tool-cont-box">
                <div class="tool-content" v-if="item.content">
                  <div class="cont-title">{{ item.title }}</div>
                  <p class="contCont" v-for="(cont, index) in item.content" :key="index">{{ cont }}</p>
                </div>
                <div class="tool-content tool-content-special" v-else>
                  <div class="cont-title">{{ item.title }}</div>
                  <div v-for="(cont, index) in item.contentSpecial" :key="index">
                    <p class="contTitle">{{ cont.title }}</p>
                    <p class="contCont">{{ cont.cont }}</p>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <!-- 如果需要分页器 -->
        <div class="tool-swiper-pagination swiper-pagination"></div>
        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev el-icon-arrow-left"></div>
        <div class="swiper-button-next el-icon-arrow-right"></div>
      </div>
    </div>
    <!-- 多重安全保障 -->
    <div class="safe-container">
      <div class="save-title">多重安全保障</div>
      <div class="save-content-wrap">
        <div class="save-content" v-for="item in safeList" :key="item.id">
          <img class="img" :src="item.imgUrl">
          <div class="title">{{ item.title }}</div>
          <img class="iconImg" src="../../assets/image/icon2.png">
          <p class="cont" v-for="(cont, ind) in item.content" :key="ind">{{ cont }}</p>
        </div>
      </div>
    </div>
    <!-- 专业服务支持 -->
    <div class="service-container">
      <div class="service-title">专业服务支持</div>
      <div class="service-wrap">
        <div class="service-content" v-for="item in serviceList" :key="item.id">
          <div class="title">{{ item.title }}</div>
          <div class="line"></div>
          <p class="cont" v-for="(cont, ind) in item.content" :key="ind">{{ cont }}</p>
          <img class="img" :src="item.imgUrl">
        </div>
      </div>
    </div>
    <!-- 注册会员，解锁更多福利 -->
    <div class="vip-container">
      <div class="vip-container-wrap">
        <div class="vip-title">注册会员，解锁更多福利</div>
        <div class="vip-btn" @click="goRegister">注册会员领取资料</div>
        <div class="vip-content">
          <p class="vip-info" :class="'vip-info' + (index + 1)" v-for="(item, index) in vipList" :key="index">
            <span class="label">会员专享<span class="index">{{ index + 1 }}</span></span>
            <span class="cont">{{ item }}</span>
          </p>
        </div>
      </div>
    </div>
    <!-- 留下您的联系方式  专业顾问会尽快与您取得联系 -->
    <div class="register-container">
      <div class="register-content-wrap">
        <div class="register-title">留下您的联系方式  专业顾问会尽快与您取得联系</div>
        <div class="register-form-wrap">
          <el-form ref="form" :model="formData">
            <div class="input-group">
              <span class="iconFlag" :class="status.userName ? 'checkIconFlag' : ''"></span>
              <el-input placeholder="*请输入您的姓名" v-model.trim="formData.userName" @focus='status.userName = true' clearable @blur.native.capture="handleCheck('姓名', formData.userName), status.userName = false"></el-input>
            </div>
            <div class="input-group">
              <span class="iconFlag" :class="status.companName ? 'checkIconFlag' : ''"></span>
              <el-input placeholder="*请输入您的公司名称" v-model.trim="formData.companName" @focus='status.companName = true' clearable @blur.native.capture="handleCheck('公司名称', formData.companName), status.companName = false"></el-input>
            </div>
            <div class="input-group input-group-noMargin">
              <span class="iconFlag" :class="status.appName ? 'checkIconFlag' : ''"></span>
              <el-input placeholder="*请输入您的APP名称" v-model.trim="formData.appName" @focus='status.appName = true' clearable @blur.native.capture="handleCheck('APP名称', formData.appName), status.appName = false"></el-input>
            </div>
            <!--<div class="input-group input-group-noMargin">-->
              <!--<span class="iconFlag" :class="status.email ? 'checkIconFlag' : ''"></span>-->
              <!--<el-input placeholder="*请输入您的电子邮箱" v-model.trim="formData.email" @focus='status.email = true' clearable @blur.native.capture="handleCheck('电子邮箱', formData.email), status.email = false"></el-input>-->
            <!--</div>-->
            <div class="input-group">
              <span class="iconFlag" :class="status.phonenumber ? 'checkIconFlag' : ''"></span>
              <el-input placeholder="*请输入您的手机号" v-model.trim="formData.phonenumber" @focus='status.phonenumber = true' maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" clearable @blur.native.capture="handleCheck('手机号', formData.phonenumber), status.phonenumber = false"></el-input>
            </div>
            <div class="input-group">
              <span class="iconFlag" :class="status.verifyCode ? 'checkIconFlag' : ''"></span>
              <el-input placeholder="*请输入6位验证码" v-model.trim="formData.verifyCode" @focus='status.verifyCode = true' maxlength="6" oninput="value=value.replace(/[^\d]/g,'')" clearable @blur.native.capture="handleCheck('验证码', formData.verifyCode), status.verifyCode = false"></el-input>
              <div class="send" @click.stop="sendMessage" :class="{'disabled': disabled == true, 'pointer': disabled == false}">{{ buttonMsg }}</div>
            </div>
            <div class="input-group"></div>
            <div class="input-group">
              <div class="submit-btn" @click="onSubmit">立即提交</div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 底部导航 -->
    <footers></footers>
  </div>
</template>

<script>
import utils from "@/utils/utils";
import headers from "@/component/header";
import footers from "@/component/footer";
import { collectUser, getMessage, login, getCode } from "@/api/api";
export default {
  name: "index",
  components: {
    headers,
    footers
  },
  data() {
    return {
      //配置轮播图
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        //自动轮播
        // autoplay: {
        //   delay: 6000,
        //   disableOnInteraction: false,
        // },
        loop: true,
        loopAdditionalSlides: 2
      },
      toolSwiperOption: {
        pagination: {
          el: ".tool-swiper-pagination",
          clickable: true,
          // 处理分页器样式
          renderBullet: function(index, className) {
            let text = '';
            switch(index) {
              case 0:
                text = '聚 合';
                break;
              case 1:
                text = '策 略';
                break;
              case 2:
                text = '数 据';
                break;
              case 3:
                text = '管 理';
                break;
            }
            return '<span class="'+ className +' span'+ index + '" style="color: #595959; font-size: 20px; border: none; opacity: 1; width: auto !important; height: auto !important; flex: 1; text-align: center;">'+ text +'</span>'
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        loop: true,
        loopAdditionalSlides: 2,
      },
      scroll: 0,
      buttonMsg: '发送验证码',// 发送按钮文案
      disabled: true,       // 发送短信按钮是否禁用
      timeDown: 60,         // 倒计时按钮
      // "优加"广告联盟
      spaceList: [
        { id: 1, title: '高填充  高单价', imgUrl: require('../../assets/image/index/union1.png'),
          content: [ '通过Bidding的实时竞价', '可以有效的获得更高eCPM', '单价提升可达100%，填充率达50%以上' ]
        },
        { id: 2, title: '优质广告资源', imgUrl: require('../../assets/image/index/union2.png'),
          content: [ '高质量的品牌广告资源', '广告素材可控可审核' ]
        },
        { id: 3, title: '丰富的广告形式', imgUrl: require('../../assets/image/index/union3.png'),
          content: [ '满足媒体各类开发场景下的广告需求', '独家广告样式提供，补充收益' ]
        },
        { id: 4, title: 'SDK安全可靠', imgUrl: require('../../assets/image/index/union4.png'),
          content: [ 'SDK代码逻辑清晰透明', '符合国家规定，安全可靠' ]
        }
      ],
      // 变现提效增收工具
      toolList: [
        { id: 1, imgUrl: require('../../assets/image/index/tool1.png'), title: '高效的广告聚合平台',
          content: [ '聚合了多家的优质广告联盟，免去媒体自己选择苦恼，评估的成本；', '帮助您在变现业务上拓展更多成熟变现渠道。' ]
        },
        { id: 2, imgUrl: require('../../assets/image/index/tool2.png'), title: '灵活的售卖策略',
          content: [ '通过Bidding实时竞价，优质广告充分竞争，让每次请求获得最优收益，', '配合自主的流量分配策略，灵活售卖，助力媒体取得最佳收益；' ]
        },
        { id: 3, imgUrl: require('../../assets/image/index/tool3.png'), title: '多维度数据支持',
          contentSpecial: [
            { title: '关键数据展示', cont: '提供广告收益、DAU、eCPM、LTV、留存率等关键数据展示' },
            { title: '实时数据呈现', cont: '流量实时监控，异常数据报警，变现情况一手掌握' },
            { title: '实现业务诊断', cont: '针对从广告请求到展示，异常问题识别，并提供调优方案' },
          ]
        },
        { id: 4, imgUrl: require('../../assets/image/index/tool4.png'), title: '智能系统管理',
          content: [ '智能化系统化管理变现业务，操作简单方便；', ' 降低人力成本，提升运营效率；' ]
        }
      ],
      // 多重安全保障
      safeList: [
        { id: 1, imgUrl: require('../../assets/image/index/safe1.png'), title: '数据公开透明',
          content: [ '平台作为工具的提供方案数据100%中立', '所有数据均可做到公开透明' ]
        },
        { id: 2, imgUrl: require('../../assets/image/index/safe2.png'), title: 'SDK符合工信部要求',
          content: [ '符合相关法律法规、政策及标准，更好地落实', '用户个人信息保护相关要求' ]
        },
        { id: 3, imgUrl: require('../../assets/image/index/safe3.png'), title: '系统安全稳定',
          content: [ '系统安全无小事，稳定运行是基础', '系统成熟已平稳运行3年以上' ]
        }
      ],
      // 专业服务支持
      serviceList: [
        { id: 1, title: '专业变现顾问随时在线', imgUrl: require('../../assets/image/index/service1.png'),
          content: [ '我们的变现顾问均拥有多年的企业服务和商业化运营经验，', '服务于媒体商业化变现的全生命周期。' ]
        },
        { id: 2, title: '7x24小时跟踪服务', imgUrl: require('../../assets/image/index/service2.png'),
          content: [ '您随时发现问题，我们随时解决问题；', '7x24小时随时在线解决您的一切疑问和顾虑。' ]
        },
        { id: 3, title: '技术集成有支持', imgUrl: require('../../assets/image/index/service3.png'),
          content: [ '根据媒体商业化周期的不同，技术接入的熟练程度各有不同，', '我们专业的技术支持将帮助您更快的完成接入。' ]
        },
        { id: 4, title: '收益优化有指导', imgUrl: require('../../assets/image/index/service4.png'),
          content: [ '完成媒体商业化的利益最大化，是我们一直以来的任务和责任；', '我们将指导您如何进行迭代和优化。' ]
        }
      ],
      // 注册会员，解锁更多福利
      vipList: [ '技术专家、运营专家；一对一沟通。', '热门渠道数据公开，每日更新整体大盘情况。', '详细媒体案例分享，靶向定义，提效增收。' ],
      formData: {
        userName: '', // 姓名
        companName: '', // 公司名称
        appName: '', // APP名称
        // email: '', // 电子邮箱
        phonenumber: '', // 手机号
        verifyCode: '' // 验证码
      },
      status: {
        userName: '', // 姓名
        companName: '', // 公司名称
        appName: '', // APP名称
        // email: '', // 电子邮箱
        phonenumber: '', // 手机号
        verifyCode: '' // 验证码
      },
      // 判断是否输入了姓名以及电话，输入后才允许获取验证码
      phoneStatus: false,
      nameStatus: false
    };
  },
  methods: {
    // 跳转到表单位置
    jumpForm() {
      document.documentElement.scrollTop = 3780;
      window.pageYOffset = 3780;
      document.body.scrollTop = 3780;
    },
    // 点击了解详情跳转对应页面
    goToDetail(val) {
      this.$router.push({
        name: 'product',
        hash: val === 1 ? '' : '#page=' + val
      })
    },
    // 点击跳转到注册页
    goRegister() {
      window.open(process.env.VUE_APP_HREF_AD_SCOPE + '#/register', "register");
    },
    // 点击立即咨询
    handleService() {
      window.open('http://p.qiao.baidu.com/cps/chat?siteId=15926720&userId=31420752&siteToken=847e66b9805066a3b369c02889def33b', "customerService");
    },
    // 点击预约演示
    handleOrder() {
      let href = window.location.origin + '#/home/demonstrate';
      window.open(href, "order");
    },
    // 表单失去焦点/提交时校验--校验是否输入/输入正确
    handleCheck(flag, val) {
      console.log(flag);
      console.log(val);
      if(val == '') {
        this.$message({
          message: '请输入您的' + flag,
          type: 'warning'
        });
        if(flag == '姓名') this.nameStatus = false;
        if(this.phoneStatus && this.nameStatus){
          this.disabled = false;
        } else {
          this.disabled = true;
        }
        console.log("1");
        return false;
      } else {
        if(flag == '姓名') this.nameStatus = true;
      }
      if(flag == '手机号') {
        let phone_reg = /^13[0-9]{1}[0-9]{8}$|15[0-9]{1}[0-9]{8}$|18[0-9]{1}[0-9]{8}|17[0-9]{1}[0-9]{8}$|14[0-9]{1}[0-9]{8}/;
        if (!phone_reg.test(val)) {
          this.$message({
            message: '请输入正确格式的手机号码',
            type: 'warning'
          });
          this.phoneStatus = false;
          return false;
        } else {
          this.phoneStatus = true;
          if(this.phoneStatus && this.nameStatus){
            this.disabled = false;
          } else {
            this.disabled = true;
          }
        }
      }
      if(flag == '电子邮箱') {
        let email_reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
        if (val !== '' && !email_reg.test(val)) {
          this.$message({
            message: '请输入正确格式的电子邮箱',
            type: 'warning'
          });
          if(this.phoneStatus && this.nameStatus){
            this.disabled = false;
          } else {
            this.disabled = true;
          }
          return false;
        }
      }
    },
    // 发送验证码
    sendMessage() {
      this.utils.sendMessage(this, this.formData.phonenumber, this.formData.userName)
    },
    // 立即提交
    async onSubmit() {
      let num = 0;
      for(let i in this.formData) {
        if(this.formData[i] == '') num ++;
      }
      if(num > 0) {
        this.$message({
          message: '请输入您的信息',
          type: 'warning'
        });
        return false
      }
      let submitData = this.formData;
      submitData.userType = '3';
      const res = await collectUser(submitData);
      if(res.code == 200) {
        this.$message({
          message: '信息提交成功',
          type: 'success'
        });
        this.timeDown = 0;
        for(let item in this.formData) {
          this.formData[item] = '';
        }
      }else {
        let message= res.msg;
        this.$message({
          message: `${message}`,
          type: 'error'
        });
      }
    },
  },

  mounted() {
    // 检测到跳转页面携带jump=to参数时调用jumpForm方法，
    // if(this.$route.query.jump) {
    if(this.$route.params.jumpTo) {
      this.jumpForm();
    }
  },
};
</script>

<style lang="scss" scoped>
.index-wrap {
  position: relative;
}
/* 轮播图样式部分 */
.swiper-contanier {
  background: url('../../assets/image/index/bannerBg.jpg') no-repeat center;
  background-size: 100% 100%;
  .common {
    width: 100%;
    height: 800px;
    text-align: center;
    position: relative;
    .inner-wrap {
      width: 1400px;
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 0;
      right: 0;
      margin-left: -700px;
      padding-top: 146px;
      background: transparent;
      color: #fff;
      text-align: left;
      display: flex;
      .banner-txt-wrap {
        color: #000;
        text-align: left;
        flex: 1;
        .income-subtitle, .banner-title4 {
          line-height: 1em;
        }
        .income-subtitle {
          margin-top: 134px;
          font-size: 20px;
          padding-left: 4px;
          /*height: 48px;*/
          /*line-height: 48px;*/
          display: flex;
          .income-subtitle-txt {
            padding-top: 26px;
          }
          .banner4-icon {
            display: inline-block;
            width: 138px;
            margin-left: 20px;
          }
        }
        .banner-title {
          font-size: 56px;
          font-weight: bolder;
          margin-bottom: 20px;
          padding-top: 125px;
        }
        .banner-title1 {
          padding-top: 0;
          margin-bottom: 10px;
          font-size: 50px;
          &:first-child {
            margin-top: 80px;
          }
        }
        .banner-cont-img {
          padding-top: 110px;
        }
        .banner-title3 {
          padding-top: 105px;
          margin-bottom: 20px;
        }
        .banner-title4 {
          padding-top: 30px;
          margin-bottom: 30px;
        }
        .orangeColor {
          color: #ff9711;
        }
        .orangeBg {
          background: #ff9711 !important;
        }
        .noPadding {
          padding: 0 !important;
        }
        .specialTxt {
          font-weight: bolder;
          padding: 0 26px;
        }
        .banner-content {
          font-size: 26px;
          letter-spacing: 2px;
        }
        .banner-btn {
          width: 220px;
          height: 52px;
          background: $theme-color;
          color: #fff;
          font-size: 14px;
          text-align: center;
          line-height: 52px;
          margin-top: 50px;
          cursor: pointer;
          font-weight: bolder;
        }
        /* banner2 */
        .banner-btn2 {
          margin-top: 30px;
        }
        /* banner3 */
        .income-content {
          font-size: 22px;
          color: #575757;
          padding-bottom: 5px;
        }
        .banner-btn3 {
          margin-top: 40px;
        }
      }
      .banner-txt-wrap4 {
        padding-left: 30px;
        width: 570px;
        .income-content {
          font-size: 28px;
          letter-spacing: 3px;
          padding-left: 2px;
        }
      }
      .banner-img-wrap1 {
        padding: 43px 0 0 0;
        .banner-img1{
          display: inline-block;
          width: 520px;
        }
      }
      .banner-img-wrap2 {
        padding: 43px 0 0 0;
      }
      .banner-img-wrap3 {
        padding: -10px 0 0 50px;
      }
      .banner-img-wrap4 {
        width: 630px;
      }
    }
  }
  .swiper-pagination {
    width: 80px;
    left: 50%;
    bottom: 50px !important;
    margin-left: -40px;
    text-align: left;
  }
  /* 轮播图中图片样式 */
  .swiper-pagination /deep/ .swiper-pagination-bullet {
    width: 16px !important;
    height: 16px !important;
    background: #399dfb;
  }
  .swiper-pagination /deep/ .swiper-pagination-bullet-active {
    background: #fff !important;
    border: 1px solid #399dfb;
  }
  @media screen and (max-width: 1500px) {
    .inner-wrap {
      padding: 0 80px;
    }
  }
}
/* 文字阴影部分 */
.text-shadow {
  text-shadow: 1px 6px 10px rgba(0, 13, 134, 0.2) !important;
}

/* '优加'广告联盟 */
.space-contanier {
  width: 100%;
  height: 800px;
  text-align: center;
  background: url('../../assets/image/index/spaceBg.png') no-repeat center;
  background-size: 100% 100%;
  /* .main-title {
    padding-top: 45px;
  } */
  .vice-title {
    /* font-size: 20px;
    color: #fff;
    padding: 15px 0 20px 0;
    letter-spacing: 10px; */
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 68px 0 30px 0;
    color: #ffffff;
  }
  .space-info-btn {
    width: 250px;
    height: 46px;
    background: $theme-color;
    line-height: 46px;
    font-size: 14px;
    letter-spacing: 4px;
    border-radius: 30px;
    color: #fff;
    border: 2px solid #fff;
    margin: 0 auto;
    cursor: pointer;
    font-weight: bolder;
  }
  /* 四个联盟内容 */
  .contanier-wrap{
    max-width: 1600px;
    height: 100%;
    margin: 30px auto 0;
    display: flex;
    .space-union-wrap {
      flex: 1;
      margin-right: 40px;
      background: #fff;
      height: 520px;
      border-radius: 10px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
      .union-img{
        margin-top: 50px;
      }
      .union-title{
        font-size: 30px;
        font-weight: bolder;
        padding: 35px 0;
      }
      .union-cont{
        font-size: 14px;
        padding-bottom: 14px;
      }
    }
    .space-union-wrap:last-child {
      margin-right: 0;
    }
  }
}

/* 变现提效增收工具 */
.tool-contaniner {
  width: 100%;
  height: 800px;
  text-align: center;
  background: url("../../assets/image/index/toolBg.png") no-repeat center;
  background-size: 100% 100%;
  position: relative;
  .tool-title {
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: 3px;
    padding-top: 40px;
  }
  .tool-vice-title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 10px;
    color: #676767;
    padding: 20px 0 30px 0;
  }
  .tool-info-btn {
    width: 246px;
    height: 45px;
    background: $theme-color;
    color: #fff;
    font-size: 14px;
    letter-spacing: 4px;
    line-height: 45px;
    margin: auto;
    border-radius: 30px;
    cursor: pointer;
    font-weight: bolder;
  }
  .tool-swiper{
    width: 1400px;
    height: 350px;
    position: absolute;
    top: 360px;
    left: 50%;
    right: 0;
    margin-left: -700px;
    .tool-content-wrap {
      display: flex;
      .img-wrap {
        flex: 1;
        text-align: right;
        .tool-img {
          margin-right: 20px;
        }
      }
      .tool-cont-box {
        flex: 1;
        display: flex;
        align-items: center;
        margin-left: 20px;
        text-align: left;
        .cont-title {
          font-size: 30px;
          font-weight: bolder;
          letter-spacing: 4px;
          color: $theme-color;
          padding-bottom: 50px;
        }
        .contCont {
          padding-bottom: 20px;
          font-size: 14px;
          color: #272727;
        }
        .contTitle {
          font-size: 16px;
          color: #272727;
          font-weight: bolder;
          padding-bottom: 12px;
        }
        .tool-content-special {
          .cont-title {
            padding-bottom: 30px;
          }
          .contCont {
            padding-bottom: 30px;
          }
        }
      }
    }
    /* 左、右按键 */
    .swiper-button-prev, .swiper-button-next {
      width: 80px;
      height: 80px;
      background: #c3ced9;
      border-radius: 50px;
      color: #fff;
      font-size: 28px;
      display: inline-block;
      line-height: 80px;
    }
    .swiper-button-prev:after, .swiper-button-next:after {
      content: '';
      font-size: inherit;
    }
    .tool-swiper-pagination {
      top: -90px;
      height: 40px;
      width: 100%;
      display: flex;
      line-height: 40px;
      border-bottom: 2px solid #ccc;
    }
    .tool-swiper-pagination /deep/ .swiper-pagination-bullet-active{
      font-weight: bolder;
      color: #272727 !important;
      background: url('../../assets/image/icon1.png') no-repeat bottom center !important;
      margin-bottom: -2px;
    }
    .tool-swiper-pagination /deep/ .swiper-pagination-bullet {
      background: none;
    }
  }
}

/* 多重安全保障 */
.safe-container {
  width: 100%;
  height: 720px;
  text-align: center;
  background: url("../../assets/image/index/safeBg.png") no-repeat center;
  background-size: 100% 100%;
  .save-title {
    color: #fff;
    font-size: 42px;
    font-weight: bolder;
    letter-spacing: 3px;
    padding: 60px 0 50px 0;
  }
  .save-content-wrap {
    width: 1400px;
    height: 500px;
    border-radius: 10px;
    background: #fff;
    margin: 0 auto;
    display: flex;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    .save-content {
      flex: 1;
      .img {
        margin: 80px 0 56px 0;
      }
      .title {
        font-size: 26px;
        color: $theme-color;
        font-weight: bolder;
        padding-bottom: 18px;
      }
      .iconImg {
        padding-bottom: 20px;
      }
      .cont {
        font-size: 14px;
        color: #000;
        padding-bottom: 10px;
      }
    }
  }
}

/* 专业服务支持 */
.service-container {
  width: 100%;
  height: 740px;
  background: #fff;
  .service-title {
    font-size: 40px;
    font-weight: bolder;
    color: #272727;
    text-align: center;
    padding: 60px 0 40px 0;
  }
  .service-wrap {
    width: 1300px;
    margin: 0 auto;
    overflow: hidden;
    .service-content {
      width: 620px;
      height: 260px;
      margin-bottom: 30px;
      background: #e4f0ff;
      border-radius: 10px;
      float: left;
      margin-right: 30px;
      padding-left: 40px;
      position: relative;
      .title {
        color: $theme-color;
        font-size: 26px;
        padding: 46px 0 20px 0;
        font-weight: bolder;
      }
      .line {
        margin: 0 0 20px 0;
        width: 20px;
        height: 5px;
        background: $theme-color;
      }
      .cont {
        font-size: 14px;
        padding-bottom: 10px;
      }
      .img {
        position: absolute;
        right: 30px;
        bottom: 40px;
      }
    }
  }
}

/* 注册会员，解锁更多福利 */
.vip-container {
  width: 100%;
  height: 700px;
  background: url("../../assets/image/index/vipBg.png") no-repeat center;
  background-size: 100% 100%;
  overflow: hidden;
  .vip-container-wrap {
    width: 1270px;
    height: 600px;
    margin: 50px auto;
    border-radius: 10px;
    background: url("../../assets/image/index/vipContBg.png") no-repeat center;
    background-size: 100% 100%;
    text-align: center;
    .vip-title {
      font-size: 40px;
      font-weight: bolder;
      padding: 50px 0 40px 0;
    }
    .vip-btn {
      height: 40px;
      width: 238px;
      border-radius: 20px;
      border: 1px solid #f0d23c;
      line-height: 40px;
      color: #f0d23c;
      margin: 0 auto;
      background: #fffaea;
      font-weight: bolder;
      cursor: pointer;
    }
    .vip-content {
      margin-left: 535px;
      margin-top: 48px;
      text-align: left;
      .vip-info {
        height: 40px;
        line-height: 40px;
        margin-bottom: 80px;
        .label {
          display: inline-block;
          width: 180px;
          background: url("../../assets/image/index/vipTitleBg.png") no-repeat center;
          background-size: 100% 100%;
          color: #fff;
          font-size: 18px;
          font-weight: bolder;
          padding-left: 28px;
          letter-spacing: 4px;
          .index {
            float: right;
            font-size: 12px;
            color: $theme-color;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            letter-spacing: 0;
            background: #fff;
            line-height: 20px;
            text-align: center;
            font-weight: bolder;
            margin: 10px 30px 10px 0;
          }
        }
        .cont {
          font-size: 18px;
          color: #464646;
          font-weight: bolder;
          padding-left: 24px;
        }
      }
      .vip-info1, .vip-info3 {
        margin-left: 40px;
      }
    }
  }
}

/* 留下您的联系方式  专业顾问会尽快与您取得联系 */
.register-container {
  width: 100%;
  height: 520px;
  background: url("../../assets/image/index/formBg.png") no-repeat center;
  background-size: 100% 100%;
  .register-content-wrap {
    width: 1120px;
    margin: 0 auto;
    .register-title {
      color: #fff;
      font-size: 42px;
      font-weight: bolder;
      letter-spacing: 5px;
      text-align: center;
      padding: 80px 0;
    }
    .register-form-wrap {
      overflow: hidden;
      .input-group {
        width: 340px;
        height: 50px;
        margin-right: 50px;
        margin-bottom: 28px;
        float: left;
        position: relative;
        .iconFlag {
          position: absolute;
          width: 6px;
          height: 6px;
          left: 20px;
          top: 22px;
          border-radius: 5px;
          background: #bfbfbf;
          z-index: 1;
        }
        .checkIconFlag {
          background: $theme-color;
        }
        .el-input--suffix {
          height: 50px;
        }
        .el-input--suffix /deep/ .el-input__inner {
          height: 50px;
          text-indent: 20px;
        }
        .submit-btn {
          background: $theme-color;
          color: #fff;
          line-height: 50px;
          text-align: center;
          font-size: 14px;
          font-weight: bolder;
          letter-spacing: 2px;
          margin-top: 32px;
          cursor: pointer;
        }
        .send {
          position: absolute;
          top: 9px;
          right: 8px;
          width: 80px;
          height: 32px;
          font-size: 12px;
          border-radius: 8px;
          float: left;
          color: #fff;
          text-align: center;
          line-height: 32px;
        }
        .disabled {
          background: #bfbfbf;
        }
        .pointer {
          cursor: pointer;
          background: #5aa2ff;
        }
      }
      .input-group-noMargin {
        margin-right: 0;
      }
    }
  }
}

.login-dialog-wrap {
  width: 340px;
  height: 400px;
  background-color: #fff;
  box-shadow: 0px 6px 12px 0px rgba(12, 7, 110, 0.15);
  border-radius: 4px;
  position: absolute;
  top: 150px;
  right: calc((100% - 1200px) / 2 + 40px);
  z-index: 99;
  .wrap {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 40px;
    box-sizing: border-box;
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
    .el-icon-close {
      width: 16px;
      height: 16px;
      background-color: #ff5f55;
      border-radius: 50%;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      color: #fff;
      line-height: 16px;
    }
  }
  .title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    margin-bottom: 0;
  }
  .line {
    width: 78px;
    margin: 0;
  }
  .info-wrap {
    margin-top: 10px;
    .common-wrap {
      width: 100%;
      height: 50px;
      line-height: 50px;
      margin-top: 20px;
      border-bottom: 1px solid #E5E5E5;
      .login-icon {
        display: inline-block;
        width: 20px;
        height: 18px;
        vertical-align: middle;
      }
      .code-icon {
        display: inline-block;
        width: 70px;
        height: 35px;
        vertical-align: middle;
        cursor: pointer;
        background-size: 70px 35px;
      }
    }
    .common-wrap.selected {
      border-bottom: 1px solid #493DD1;
    }
    .common-wrap /deep/ .el-input {
      width: 90% !important;
    }
    .el-input /deep/ .el-input__inner {
      border: 0 none !important;
      color: #493DD1 !important;
    }
    .login {
      width: 100%;
      height: 42px;
      line-height: 42px;
      margin-top: 30px;
      text-align: center;
      background: linear-gradient(-10deg, #A634E7, #493DD1);
      box-shadow: 0px 4px 6px 0px rgba(7, 0, 147, 0.1);
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      cursor: pointer;
    }
    .apply {
      width: 100%;
      height: 42px;
      line-height: 42px;
      margin-top: 20px;
      text-align: center;
      background: #FFFFFF;
      border: 1px solid #493DD1;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #493DD1;
      cursor: pointer;
      span{
        display: inline-block;
        height: 100%;
        padding-left: 36px;
        background: url("../../assets/image/vip.png") left center no-repeat;
        background-position: left top 6px;
      }
    }
  }
}
.login-dialog-wrap.hight {
  width: 340px;
  height: 474px;
  background-color: #fff;
  box-shadow: 0px 6px 12px 0px rgba(12, 7, 110, 0.15);
  border-radius: 4px;
  position: absolute;
  top: 150px;
  right: calc((100% - 1200px) / 2 + 40px);
  z-index: 99;
}
</style>
