<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">创新永不停歇，轻变聚合成功斩获2022第十届TopDigital创新营销奖</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2022年7月14日</span>
      <span class="detailTxt">分类：新闻动态</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="consult-content">
      <p class="contentTxt">2022年7月14日，第十届TopDigital创新营销奖获奖结果正式揭晓，上海倍孜凭借“轻变聚合移动广告变现管理平台”成功斩获“TopDigital创新营销奖—年度产品奖”。</p>
    </div>
    <div class="img-warp">
      <img src="@/assets/image/consult/info16/consult_info1.png" alt="infoImg" class="infoImg">
    </div>
    <div class="consult-content">
      <p class="contentTxt">TopDigital创新营销奖是数字营销领域品牌创建与产品创新的权威奖项，“年度产品奖”主要针对优秀数字营销创新产品开展，该奖项的评审主要由数字营销行业产品与技术领域领袖级人物评出，一定程度上代表业界对轻变聚合的认可。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">作为极富创新活力与专业性的媒体变现一站式移动应用商业化服务供应商，上海倍孜自2016年成立以来积极关注移动应用商业化变现趋势，从媒体变现实际需求出发，依托多年移动广告行业经验与独到的媒体商业化见解，打造专为移动应用开发者使用的流量变现工具化产品——轻变聚合移动广告变现管理平台。</p>
    </div>
    <div class="img-warp">
      <img src="@/assets/image/consult/info16/consult_info2.png" alt="infoImg" class="infoImg">
    </div>
    <div class="consult-content">
      <p class="contentTxt">轻变聚合移动广告变现管理平台以轻量化、工具化的系统服务，为媒体提供商业化变现及增收运营解决方案。四大核心产品能力赋能媒体，搭建“提效+增益”的媒体流量运营体系，实现媒体变现渠道科学管理，变现策略能力逐步提升，智能化提升广告收益，助力媒体实现业务增收。至今已经帮助数百家移动应用开发者实现变现收益提升。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">此外，轻变聚合移动广告变现管理平台还具有数据透明、对接安全、专业优质服务三大优势，以增收为核心，业务透明、系统安全有保障，专人对接及时响应需求，为媒体变现业务运营提供坚实基础。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">未来，上海倍孜将继续不负期待，坚持用专业与创新为移动应用开发者服务，通过不断提升核心竞争力，持续为开发者赋能，实现媒体变现优中更优，高中更高！</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo16'
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
</style>