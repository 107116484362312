// 循环展示在咨询中心中的item数据
export const consultList = [
  // 每次新增放在第一个
  {
    imgUrl: require('@/assets/image/consult/cover29.png'),
    title: '筑牢数据隐私安全底线，轻变聚合通过ISO隐私信息管理体系认证！',
    content: '近日，轻变聚合（上海倍孜网络技术有限公司）已通过相关组织机构评审，符合ISO/IEC 27701：2019标准……'
  },
  {
    imgUrl: require('@/assets/image/consult/cover28.png'),
    title: '轻变聚合加入中国互联网协会！',
    content: '近日，经协会批准，轻变聚合（上海倍孜网络技术有限公司）正式加入中国互联网协会，成为会员单位！'
  },
  {
    imgUrl: require('@/assets/image/consult/cover27.png'),
    title: '轻变聚合加入中国广告协会！',
    content: '近日，经协会批准，上海倍孜网络技术有限公司正式加入中国广告协会，成为会员单位！'
  },
  // 每次新增放在第一个
  {
    imgUrl: require('@/assets/image/consult/cover26.png'),
    title: '打破常规之路，创新永不停歇！轻变聚合成功斩获2023第十一届TopDigital“年度最佳营销技术公司”奖项！',
    content: '轻变聚合凭借先进的广告变现技术，创新的变现理念，从海内外657家参赛企业提交的3052件作品中突出重围，成功斩获“TopDigital创新营销奖—年度最佳营销技术公司”奖项。'
  },
  {
    imgUrl: require('@/assets/image/consult/cover25.png'),
    title: '轻变聚合斩获金鼠标大赛“年度最佳数字营销工具”奖项！',
    content: '上海倍孜凭借“轻变聚合 移动广告变现管理系统”成功斩获“年度最佳数字营销工具”奖项！'
  },
  {
    imgUrl: require('@/assets/image/consult/cover24.png'),
    title: '轻变聚合加入中国商务广告协会数字营销委员会！',
    content: '近日，经数委会第四届理事会常务理事会议审核，报协会批准，上海倍孜网络技术有限公司加入中国商务广告协会数字营销专业委员会，成为会员单位。'
  },
  {
    imgUrl: require('@/assets/image/consult/cover23.png'),
    title: '轻变聚合入驻「全国SDK管理服务平台」，提升服务能力，推动产品健康合规发展！',
    content: '轻变聚合 SDK（安卓/IOS）成功入驻「全国SDK管理服务平台」，在提升合规水平和服务能力，推动行业健康合规发展的路上更进一步。'
  },
  {
    imgUrl: require('@/assets/image/consult/cover22.png'),
    title: '盘点那些极速成长、快速获利的APP都做对了什么',
    content: '在移动应用变现市场中，有些APP的商业化过程总是顺风顺水，很快实现变现收益，且收入十分可观，而有些APP却一直挣扎在生死边缘，没有找到匹配的商业模式，为什么同样是APP流量变现，还会出现如此巨大的差异呢？'
  },
  {
    imgUrl: require('@/assets/image/consult/cover21.png'),
    title: '视频类广告掘金潜力巨大，期待更大价值！',
    content: '随着技术的进步，视频类广告水涨船高，其相较于静态图片，转化效果、变现收益都有很大的提升。在极具发展潜力的视频广告变现市场，开发者如何做，才能最大化广告收益呢？'
  },
  {
    imgUrl: require('@/assets/image/consult/cover20.png'),
    title: '用户增长+流量变现 | 轻变聚合助推行业打造生态闭环',
    content: '互联网寒潮论持续蔓延，但移动阅读行业却在社会倡导读书的理念下异军突起，迎来飞跃式发展，轻变聚合顺应市场发展，为移动阅读APP开发者提供全流程“用户增长+流量变现”解决方案，从用户获取到深度培育形成高价值用户群高效变现，助力开发者打造生态闭环。'
  },
  {
    imgUrl: require('@/assets/image/consult/cover19.png'),
    title: '混合竞价模式下，App开发者如何制定价格策略',
    content: '第三方聚合平台逐步被广告开发者使用，但对于想要通过优化手段提升变现收益的开发者来讲，在房前多渠道混合竞价模式下，采取什么样的价格策略决定了最终的广告收益，那么如何指定价格策略优化提升开发者收益呢？'
  },
  {
    imgUrl: require('@/assets/image/consult/cover18.png'),
    title: 'App商业化，开发者收益路径，广告变现仍是最好选择！',
    content: '随着移动流量进入存量时代，广告变现之路愈加不容易，广告还是不是APP的首选变现方式呢？移动流量还有潜力可以挖掘吗？'
  },
  {
    imgUrl: require('@/assets/image/consult/cover17.png'),
    title: '轻变聚合出席中国信通院2022数据安全峰会并联合签署绿色SDK产业生态倡议书',
    content: '2022年7月28日，由中国信息通信研究院安全研究所主办，数据安全共同体计划、大数据应用与安全创新实验室承办的“数据安全峰会2022”在北京成功召开。'
  },
  {
    imgUrl: require('@/assets/image/consult/cover16.png'),
    title: '创新永不停歇，轻变聚合成功斩获2022第十届TopDigital创新营销奖',
    content: '2022年7月14日，第十届TopDigital创新营销奖获奖结果正式揭晓，上海倍孜凭借“轻变聚合移动广告变现管理平台”成功斩获“TopDigital创新营销奖—年度产品奖”。'
  },
  {
    imgUrl: require('@/assets/image/consult/cover15.png'),
    title: '轻变聚合聂子尧：从Waterfall到Bidding，开发者离Open Bidding还有多远',
    content: '应用内竞价模式（In-App Bidding）不仅能显著提升广告变现收益，而且未来势必将进一步完善移动广告流量变现生态，推动全行业自动化效率升级，无疑是移动流量采购方式程碑式的变化。'
  },
  {
    imgUrl: require('@/assets/image/consult/cover14.png'),
    title: '2022，APP广告变现生机在哪里，轻变聚合为开发者蓄势“增长”新动能',
    content: '移动应用如何做到收益增长，尤其是在流量红利早已见顶，市场进入存量化的新常态下，留给开发者可操作的空间十分有限，优化工具的出现，为媒体、开发者在新常态下取得“增长”增添了动力。'
  },
  {
    imgUrl: require('@/assets/image/consult/cover13.png'),
    title: '轻变聚合产品趋势解读（一）：优量汇“多阶竞价”',
    content: '今年上半年，国内主流广告平台，优量汇和穿山甲都在提升媒体广告变现效率方面，升级了自身产品。优量汇推出了“多阶竞价”功能，支持开发者在多阶价格区间段内寻找最高价值的广告匹配。穿山甲也推出了Gromore聚合产品，“智能分层”支持开发者通过广告分层，对收益进行优化。'
  },
  // {
  //   imgUrl: require('@/assets/image/consult/cover12.png'),
  //   title: '联盟平台开屏广告收入大减，APP开发者应如何应对？',
  //   content: '近期，工信部组织各省通信管理局，持续推进APP侵害用户权益专项整治行动，加大常态化检查力度。'
  // },
  // {
  //   imgUrl: require('@/assets/image/consult/cover11.png'),
  //   title: '轻变聚合推出最新媒体商业化解决方案，助力开发者广告收益大幅提升',
  //   content: '为进一步赋能开发者，促进媒体变现的持续增收，轻变聚合全面升级产品能力。在原有变现运营管理工具基础上，进一步提升工具价值，巩固媒体增收能力的同时，基于媒体基础变现能力突出平台变现优势，全面提升媒体商业化变现运营能力，帮助媒体达成商业化变现，收益增长的终极目标。'
  // },
  {
    imgUrl: require('@/assets/image/consult/cover10.png'),
    title: '变现干货 | App商业化变现中广告位设计的原则及优化思路',
    content: '我们在与APP开发者接触时常常会被问到：我的广告要设计成什么样？什么样的广告形式收益比较高？我的广告收益为什么远远低于行业水平？'
  },
  {
    imgUrl: require('@/assets/image/consult/cover9.png'),
    title: '深挖App流量变现价值，“LTV”这个你不得不了解的关键因素',
    content: 'LTV(lifetime value)，即用户生命周期价值，LTV代表了用户完整生命周期内给企业创造的价值总额。LTV越高，App流量变现的价值越高，常用于衡量App广告变现收益能力。所以，LTV值是流量变现过程中的一个重要指标。'
  },
  {
    imgUrl: require('@/assets/image/consult/cover8.png'),
    title: '了解广告SDK工作机制，保护APP自身安全',
    content: 'SDK，APP开发者应该都很了解，开发者们或多或少都接触或安装过，而提到SDK安全性这个问题，开发者们的情绪可能都不会平静，这其中的利害关系确实有点儿复杂。'
  },
  // {
  //   imgUrl: require('@/assets/image/consult/cover7.png'),
  //   title: '轻变聚合核心功能 | AB测试，驱动收益增长',
  //   content: '对流量变现有高收益期望的开发者来说，一定离不开A/B测试。A/B测试能够帮助产品运营选取最佳策略，是验证变现策略最直观、准确的方法之一，所以A/B测试是APP流量变现策略优化过程中超实用的必备功能。'
  // },
  {
    imgUrl: require('@/assets/image/consult/cover6.png'),
    title: '精彩回顾 | 6月24日“APP变现优化技巧”，轻变聚合蝉大师分享会要点来啦~',
    content: '为了系统帮助开发者了解从平台对接到变现优化整个广告变现流程，及多渠道变现优化过程中最让开发者“头痛”的优化方法和技巧。6月24日轻变聚合与蝉大师联合举办线上课“蝉学院·大咖分享会（第九期）”，全方面解析如何构建高效的APP流量变现运营体系。'
  },
  {
    imgUrl: require('@/assets/image/consult/cover5.png'),
    title: '变现干货 | 多渠道变现，APP开发者应如何选择广告变现平台',
    content: '广告变现是APP商业化的主要发展方向，一般APP都会选择直接对接广告平台，做好技术对接即可实现收益。这种投入小、见效快的形式，比自建销售体系要更有优势。'
  },
  // {
  //   imgUrl: require('@/assets/image/consult/cover4.png'),
  //   title: '核心功能 | 轻变聚合策略中心，提供多种变现策略灵活组合，实现最佳收益',
  //   content: '多渠道售卖流量是APP开发者提升自身商业化价值的一个重要手段。但随着多渠道的接入，流量如何分配才能保证收益最大化？…… 来看看轻变聚合媒体商业化变现管理系统如何解决这一问题。'
  // },
  {
    imgUrl: require('@/assets/image/consult/cover3.png'),
    title: '破解数据运营难题，“多维度数据透视+诊断”工具，轻松提升媒体变现运营能力',
    content: '针对当前数据后台不能支撑业务分析和指导决策的问题，轻变聚合全面升级产品，实现“全面实时数据呈现，深度剖析变现诊断”两大功能，多维度数据透视为开发者流量变现数据化运营赋能。'
  },
  {
    imgUrl: require('@/assets/image/consult/cover2.png'),
    title: '抓住APP变现增收“新机遇”，FinalLink 助你C位出道',
    content: '2019-2021年，随着直播电商、社交电商、社区团购等新型电商形式的兴起，在互联网广告市场上，电商广告主对流量需求大增。2020年电商广告增幅大于其他各种行业广告占比，整体占比接近50%，预计增长率将大于6.5%。'
  },
  {
    imgUrl: require('@/assets/image/consult/cover1.png'),
    title: '核心功能 | 轻变聚合变现诊断功能，帮助开发者明确流量损耗原因',
    content: '对开发者来说，维持变现业务稳定，保证广告收益增长，是变现运营过程中最重要的工作。但在变现链路中，广告填充率低，未填充部分原因不明，广告曝光成功率低，曝光流失量高，……，这些问题的原因分析和诊断却也是让开发者最头疼的事情。'
  },
];

// 咨询中心详情数据
import consultInfo1 from "../components/consult-info1";
import consultInfo2 from "../components/consult-info2";
import consultInfo3 from "../components/consult-info3";
// import consultInfo4 from "../components/consult-info4";
import consultInfo5 from "../components/consult-info5";
import consultInfo6 from "../components/consult-info6";
// import consultInfo7 from "../components/consult-info7";
import consultInfo8 from "../components/consult-info8";
import consultInfo9 from "../components/consult-info9";
import consultInfo10 from "../components/consult-info10";
// import consultInfo11 from "../components/consult-info11";
// import consultInfo12 from '../components/consult-info12';
import consultInfo13 from '../components/consult-info13';
import consultInfo14 from '../components/consult-info14';
import consultInfo15 from '../components/consult-info15';
import consultInfo16 from '../components/consult-info16';
import consultInfo17 from '../components/consult-info17';
import consultInfo18 from '../components/consult-info18';
import consultInfo19 from '../components/consult-info19';
import consultInfo20 from '../components/consult-info20';
import consultInfo21 from '../components/consult-info21';
import consultInfo22 from '../components/consult-info22';
import consultInfo23 from '../components/consult-info23';
import consultInfo24 from '../components/consult-info24';
import consultInfo25 from '../components/consult-info25';
import consultInfo26 from '../components/consult-info26';
import consultInfo27 from '../components/consult-info27';
import consultInfo28 from '../components/consult-info28';
import consultInfo29 from '../components/consult-info29';
// 每次新增放到consultInfoList[0] (上面第一个对应引入的consultInfo最后一个，所以反着插入)
export const consultInfoList = [
  consultInfo29,
  consultInfo28,
  consultInfo27,
  consultInfo26,
  consultInfo25,
  consultInfo24,
  consultInfo23,
  consultInfo22,
  consultInfo21,
  consultInfo20,
  consultInfo19,
  consultInfo18,
  consultInfo17,
  consultInfo16,
  consultInfo15,
  consultInfo14,
  consultInfo13,
  // consultInfo12,
  // consultInfo11,
  consultInfo10,
  consultInfo9,
  consultInfo8,
  // consultInfo7,
  consultInfo6,
  consultInfo5,
  // consultInfo4,
  consultInfo3,
  consultInfo2,
  consultInfo1
];
