<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">轻变聚合入驻「全国SDK管理服务平台」，提升服务能力，推动产品健康合规发展！</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2023年3月16日</span>
      <span class="detailTxt">分类：新闻动态</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="consult-content">
      <p class="contentTxt">近日，轻变聚合 SDK（安卓/IOS）成功入驻「全国SDK管理服务平台」，在提升合规水平和服务能力，推动行业健康合规发展的路上更进一步。</p>
    </div>
    <div class="img-warp">
      <img src="@/assets/image/consult/info23/consult_info1.png" alt="infoImg" class="infoImg">
    </div>
    <div class="consult-content">
      <p class="contentTxt">作为专业的移动变现管理系统，轻变聚合致力于为开发者提供安全、透明的一站式商业变现服务，解决移动应用变现技术研发、成本、增收问题。开发者可登录“全国SDK管理服务平台”查看轻变聚合 SDK的版本、更新、隐私政策、接入文档等介绍信息。</p>
    </div>
    <div class="img-warp">
      <img src="@/assets/image/consult/info23/consult_info2.png" alt="infoImg" class="infoImg">
    </div>
    <div class="consult-content">
      <p class="contentTxt">“全国SDK管理服务平台”是在工业和信息化部的指导下由中国信通院搭建，旨在提升SDK合规水平和服务能力，为开发者提供SDK政策标准发布、产品信息公示、监管问题处置等服务。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">轻变聚合自成立以来，一直秉持专业、透明、客观的态度，采用符合国家标准要求的SDK版本，对接风险低，数据安全有保障，软件安全稳定，并承诺一旦发生风险承担相应责任，多重保险保证媒体变现运营安全，在不断升级创新的同时坚持将安全、稳定放在首位，得到了国家、行业认可。2022年获得了“SDK安全转型评测证书”，并且联合签署《绿色SDK生态倡议书》等。</p>
    </div>
    <div class="img-warp special-img-wrap">
      <img src="@/assets/image/consult/info23/consult_info3.png" alt="infoImg" class="infoImg special-img">
    </div>
    <div class="consult-content">
      <p class="contentTxt">作为专业的一站式移动应用商业化服务供应商，未来，轻变聚合将继续深耕移动应用商业化领域，持续为开发者提供便捷、高效、专业的流量变现解决方案，帮助开发者构建完善的商业化运营体系，提升变现能力，实现商业价值！</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">让移动应用“变”得更好，我们一直在路上！</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo23'
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
  .special-img-wrap {
    text-align: center;
    .special-img {
      width: 50% !important;
    }
  }
</style>
