<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">精彩回顾 | 6月24日“APP变现优化技巧”，轻变聚合蝉大师分享会要点来啦~</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2021年6月28日</span>
      <span class="detailTxt">分类：产品功能</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="module1">
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">为了系统帮助开发者了解从平台对接到变现优化整个广告变现流程，及多渠道变现优化过程中最让开发者“头痛”的优化方法和技巧。6月24日轻变聚合与蝉大师联合举办线上课“蝉学院·大咖分享会（第九期）”，全方面解析如何构建高效的APP流量变现运营体系。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">下面是为大家整理的核心内容，想了解更详细内容可以直接扫描下方图片中的二维码观看：</p>
      </div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info6/consult_info1.png" alt="infoImg" class="infoImg">
      </div>
    </div>
    <!-------------------------------- 国内外变现现状 -------------------------------->
    <div class="module2">
      <!-- 文字标题 -->
      <div class="title_txt">国内外变现现状</div>
      <!-- 节点1 -->
      <div class="node-warp1">
        <!-- 节点标题 -->
        <div class="title_node">（1）APP变现渠道国内外都以寡头形式发展，不断涌现新的高价值平台。</div>
        <!-- 图片 -->
        <div class="img-warp">
          <img src="../../../assets/image/consult/info6/consult_info2.png" alt="infoImg" class="infoImg">
        </div>
      </div>
      <!-- 节点2 -->
      <div class="node-warp2">
        <!-- 节点标题 -->
        <div class="title_node">（2）国内外流量变现异同：</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt"><span class="txt_icon"></span>对接方式：</p>
          <p class="contentTxt">主要对接方式都是SDK/API，SDK为当前主流的对接方式，API主要集中在头部媒体使用，API对接，获得高填充率、高单价的机会不多。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txt_icon"></span>交易方式：</p>
          <p class="contentTxt">国内主要是waterfall形式为主，开发者先将广告请求先发给A平台、A平台不填充再发给B平台，依此类推。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">广告联盟平台，次日才会反馈eCPM，价格存在滞后性，这个滞后性会影响开发者的判断：我应该把流量卖给谁？或者更多的流量应该倾向给谁？……</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">相较于国内，国外主要采用In-App Bidding/Header Bidding，In-App Bidding更多的是把广告请求做竞价处理，每一次请求发出，多平台竞价，价高者得，eCPM提升非常显著。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">现在海外变现的开发者一般都会使用Mediation，我们称之为聚合中介的第三方聚合工具帮助做优化，聚合中介可以起到为开发者提升收益的作用。</span></p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">根据轻变聚合海外广告业务In-APP Bidding的实践，最直观的体会是：（1）竞价会带来eCPM明显的提升（2）填充率提升，总体收入比waterfall方式带来20%甚至更高的提升，但这种方式也给开发者带来很大的考验……</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">下面是大型“翱翔”现场（脱稿了）——“多渠道变现优化方法和技巧”，干货太多，直接扫描二维码观看视频（19：00分钟开始）↓↓↓</p>
        </div>
        <!-- 图片 -->
        <div class="img-warp">
          <img src="../../../assets/image/consult/info6/consult_info3.png" alt="infoImg" class="infoImg QRcodeImg">
          <p class="imgCenterInfo">扫码直接观看</p>
        </div>
      </div>
    </div>
    <!-------------------------------- 核心1：不借助第三方工具，开发者如何靠自己提升至少10%的收益 -------------------------------->
    <div class="module3">
      <!-- 文字标题 -->
      <div class="title_txt">核心1：不借助第三方工具，开发者如何靠自己提升至少10%的收益</div>
      <!-- 节点1 -->
      <div class="node-warp1">
        <!-- 节点标题 -->
        <div class="title_node">（1）填充率优化方面</div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txt_icon"></span>广告请求超时，预留5秒等待时长，每少1秒填充率下降10%……；</p>
          <p class="contentTxt"><span class="txt_icon"></span>广告返回超时，展示位置“容器不可见”……；</p>
          <p class="contentTxt"><span class="txt_icon"></span>广告展示面积不足，广告平台判做无效展示……；</p>
          <p class="contentTxt">……</p>
        </div>
      </div>
      <!-- 节点2 -->
      <div class="node-warp2">
        <!-- 节点标题 -->
        <div class="title_node">（2）eCPM优化方面</div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txt_icon"></span>广告频次越高eCPM单价越低，如何拆分频次获得更高收益？</p>
          <p class="contentTxt"><span class="txt_icon"></span>渠道串行下，eCPM提升如何操作？</p>
          <p class="contentTxt">……</p>
        </div>
      </div>
    </div>
    <!-------------------------------- 核心2：如何构建高效的APP流量变现运营体系 -------------------------------->
    <div class="module4">
      <!-- 文字标题 -->
      <div class="title_txt">核心2：如何构建高效的APP流量变现运营体系</div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txt_icon"></span>数据报告的重要性；</p>
        <p class="contentTxt"><span class="txt_icon"></span>业务分析、错误诊断的重要性；</p>
        <p class="contentTxt"><span class="txt_icon"></span>策略能力及A/B测试的重要性；</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo6',
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
</style>
