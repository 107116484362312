<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">轻变聚合加入中国商务广告协会数字营销委员会！</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2023年3月31日</span>
      <span class="detailTxt">分类：新闻动态</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="consult-content">
      <p class="contentTxt">近日，经数委会第四届理事会常务理事会议审核，报协会批准，上海倍孜网络技术有限公司加入中国商务广告协会数字营销专业委员会，成为会员单位。</p>
    </div>
    <div class="img-warp special-img-wrap">
      <img src="@/assets/image/consult/info24/consult_info1.png" alt="infoImg" class="infoImg special-img">
    </div>
    <div class="consult-content">
      <p class="contentTxt">中国商务广告协会数字营销委员会（CAAC）成立于2015年8月。旗下会员单位由各类数字媒体、数字技术公司、数字营销策划与代理机构、品牌主、第三方数据机构等与数字商业传播业务相关的机构与组织自愿结成的、跨传播主体的非营利性的组织，接受中国商务广告协会的业务领导和监督管理。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">数字营销委员会致力于加强传播主体间的联系与合作，依靠中国商务广告协会的资源影响力，为数字商业传播领域打造强大资源交流与沟通的跨界平台，推动中国数字营销健康、良性和有序的发展。</p>
    </div>
    <div class="img-warp">
      <img src="@/assets/image/consult/info24/consult_info2.png" alt="infoImg" class="infoImg">
    </div>
    <div class="consult-content">
      <p class="contentTxt">随着移动互联网与数字化技术的持续发展，广告变现领域规模持续增加，如何“变”得更好成为了众多移动应用开发者的追求目标。上海倍孜网络技术有限公司（轻变聚合）致力于为移动应用开发者提供更好的变现服务，运用技术、服务等综合实力打造了为开发者、流量主提供安全、透明一站式商业变现服务的移动应用变现管理系统，解决移动应用变现技术研发、成本、增收问题，努力让移动应用“变”得更好。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">此次受邀加入数委会，是对轻变聚合在移动应用商业化领域内既有成绩得极大认可。未来，我们也期待能与业内前沿精英深入交流学习与共创，释放更多价值与能量，一起为中国广告行业创造出更多的新可能。同时，轻变聚合也将继续秉持对移动应用商业化技术的热情与创新，拥抱变化，不断尝试与革新，为行业发展贡献力量。</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo24'
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
  .special-img-wrap {
    text-align: center;
    .special-img {
      width: 50% !important;
    }
  }
</style>
