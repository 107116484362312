<template>
  <div class="container">
    <div class="headers">
      <img src="../../assets/image/logo3.png" alt="logo" class="logoImg">
    </div>
    <div class="demo_container" :height="height">
      <div class="demoCont demo_left_bg">
        <div class="bannerStyCon">
          <div class="bannerSty">
            <p class="banner_text1">
              <span class="textLeft">免费获得</span>
              <span class="textRight">轻变聚合产品演示机会</span>
            </p>
            <p class="banner_text2">轻变聚合 媒体商业化工作台 助力媒体实现变现收益增长!</p>
          </div>
        </div>
        <div class="demoLeftCon">
          <p class="text1">作为领先的移动媒体变现工具公司，轻变聚合致力于帮助媒体伙伴在移动领域取得更大的成功。我们很高兴您能够留意到我们，并预约我们为您提供更强大更智能的变现工具平台的展示机会。</p>
          <p class="text2">我们能帮助您：</p>
          <ul class="text3">
            <li>监测实时变现数据，及时发现流量异常</li>
            <li>智能变现策略与优化，提高变现收入</li>
            <li>变现业务分析更简单，不再依赖IT和数据部门</li>
            <li>一站式接入多变现渠道，低工作量对接买方</li>
            <li>建立属于自己的商业化运营体系</li>
          </ul>
          <p class="text4">我们将根据您的需求，提供最佳的解决方案；</p>
          <p class="text5">业务需求可直接拨打<span>18658296496</span></p>
        </div>
      </div>
      <div class="demoCont demo_rightCon"></div>
      <div class="demo_rightFromCon">
        <div class="demoRightForm">
          <h3>开源系统领取</h3>
          <div class="line"></div>
          <div class="form-wrap">
            <div class="common-wrap" @click="focus('selectedTwo')" :class="{'selected': select.selectedTwo}">
              <img :src="select.selectedTwo ? twoActive : two" alt="" class="login-icon">
              <el-input placeholder="请输入您的姓名" v-model.trim="formData.userName" clearable @blur.native.capture="userNameBlur" @focus="focus('selectedTwo')"></el-input>
            </div>
            <div class="common-wrap" @click="focus('selectedOne')" :class="{'selected': select.selectedOne}">
              <img :src="select.selectedOne ? oneActive : one" alt="" class="login-icon">
              <el-input placeholder="请输入您的公司名称" v-model="formData.companName" clearable @focus="focus('selectedOne')"></el-input>
            </div>
            <div class="common-wrap" @click="focus('selectedEight')" :class="{'selected': select.selectedEight}">
              <img :src="select.selectedEight ? eightActive : eight" alt="" class="login-icon">
              <el-input placeholder="请输入您的APP名称" v-model="formData.appName" clearable @focus="focus('selectedEight')"></el-input>
            </div>
            <div class="common-wrap" @click="focus('selectedThree')" :class="{'selected': select.selectedThree}">
              <img :src="select.selectedThree ? threeActive : three" alt="" class="login-icon">
              <el-input placeholder="请输入您的手机号码" v-model.number="formData.phone" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" clearable @blur.native.capture="phoneBlur" @focus="focus('selectedThree')"></el-input>
            </div>
            <div class="common-wrap" @click="focus('selectedFour')" :class="{'selected': select.selectedFour}">
              <img :src="select.selectedFour ? fourActive : four" alt="" class="login-icon">
              <el-input placeholder="请输入6位验证码" v-model.trim="formData.messageCode" maxlength="6" oninput="value=value.replace(/[^\d]/g,'')" clearable style="width: 62% !important" @focus="focus('selectedFour')"></el-input>
              <div class="send" @click.stop="sendMessage" :class="{'disabled': disabled == true}">{{ buttonMsg }}</div>
            </div>
            <!--<div class="common-wrap" @click="focus('selectedFive')" :class="{'selected': select.selectedFive}">-->
              <!--<img :src="select.selectedFive ? fiveActive : five" alt="" class="login-icon">-->
              <!--<el-input placeholder="请输入您的所属的部门" v-model="formData.department" clearable @focus="focus('selectedFive')"></el-input>-->
            <!--</div>-->
            <!--<div class="common-wrap" @click="focus('selectedSix')" :class="{'selected': select.selectedSix}">-->
              <!--<img :src="select.selectedSix ? sixActive : six" alt="" class="login-icon">-->
              <!--<el-input placeholder="请输入您的所属的岗位" v-model="formData.station" clearable @focus="focus('selectedSix')"></el-input>-->
            <!--</div>-->
            <!--<div class="common-wrap commonText-wrap" @click="focus('selectedSeven')" :class="{'selected': select.selectedSeven}">-->
              <!--<img :src="select.selectedSeven ? sevenActive : seven" alt="" class="login-icon">-->
              <!--<el-input type="textarea" placeholder="请输入您面对的变现难题或想要咨询的问题" v-model="formData.remark" clearable @focus="focus('selectedSeven')"></el-input>-->
            <!--</div>-->
            <!-- 立即登录 -->
            <div class="login" @click="onSubmit">提交信息</div>
          </div>
        </div>
      </div>
      <!--用于显示粘性定位的头-->
    </div>
    <!-- 底部导航 -->
    <footers></footers>

  </div>
</template>

<script>
  import footers from "@/component/footer";
  import { collectUser, getMessage } from "@/api/api";
  export default {
    name: "demonstrate",
    components: {
      footers
    },
    data() {
      return {
        height:'', // 中间主体高度
        formData: {
          userName: '',
          phone: '',
          messageCode: '',
          companName: '',
          department: '',
          remark: '',
          station: '',
          appName: ''
        },
        // 是否触发焦点
        select: {
          selectedTwo: false,
          selectedThree: false,
          selectedFour: false,
          selectedOne: false,
          selectedFive: false,
          selectedSix: false,
          selectedSeven: false,
          selectedEight: false,
        },
        // 失去焦点和获取焦点切换图片
        one: require("../../assets/image/login/company.png"),
        oneActive: require("../../assets/image/login/companyActive.png"),
        two: require("../../assets/image/login/user.png"),
        twoActive: require("../../assets/image/login/userActive.png"),
        three: require("../../assets/image/login/phone.png"),
        threeActive: require("../../assets/image/login/phoneActive.png"),
        four: require("../../assets/image/login/code.png"),
        fourActive: require("../../assets/image/login/codeactive.png"),
        five: require("../../assets/image/login/branch.png"),
        fiveActive: require("../../assets/image/login/branchActive.png"),
        six: require("../../assets/image/login/station.png"),
        sixActive: require("../../assets/image/login/stationActive.png"),
        seven: require("../../assets/image/login/message.png"),
        sevenActive: require("../../assets/image/login/messageActive.png"),
        eight: require("../../assets/image/login/app.png"),
        eightActive: require("../../assets/image/login/appActive.png"),
        userNameFill: false, // 姓名是否填充
        phoneFill: false, // 手机号是否填充
        buttonMsg: '发送验证码', // 发送按钮文案
        timeDown: 60, // 倒计时按钮
        disabled: true, // 获取验证码按钮是否可以点击
      };
    },
    created() {
      this.height = window.innerHeight + 'px';
      if(sessionStorage.getItem('timeDown')) {
        // localStorage.setItem("openTimeDown", sessionStorage.getItem('timeDown'));
        let num = sessionStorage.getItem('timeDown');
        let down = window.setInterval(() => {
          if (num == 0) {
            window.clearInterval(down);
          }else {
            num -= 1;
          }
          localStorage.setItem("openTimeDown",num);
        },1000);
      }
    },
    methods: {
      // 表单触发焦点
      focus(name) {
        this.select[name] = true;
        for(let i in this.select) {
          if (name !== i) {
            this.select[i] = false;
          }
        }
      },
      // 姓名输入框失焦点事件
      userNameBlur() {
        if(this.formData.userName.length > 0) {
          this.userNameFill = true;
        }else {
          this.userNameFill = false;
        }
        // && this.companNameFill == true
        if(this.phoneFill == true && this.userNameFill == true) {
          this.disabled = false;
        }else {
          this.disabled = true;
        }
      },
      // 手机号输入框失焦点事件
      phoneBlur() {
        let aa = this.formData.phone+'';
        if(aa.length > 0) {
          this.phoneFill = true;
        }else {
          this.phoneFill = false;
        }
        // && this.companNameFill == true
        if(this.phoneFill == true && this.userNameFill == true) {
          this.disabled = false;
        }else {
          this.disabled = true;
        }
      },
      sendMessage() {
        this.utils.sendMessage(this,this.formData.phone,this.formData.userName)
      },
      // 提交接口
      async onSubmit() {
        if (this.formData.userName == "") {
          this.$message({
            message: '请输入您的姓名',
            type: 'warning'
          });
          return false;
        }
        let phone_reg = /^13[0-9]{1}[0-9]{8}$|15[0-9]{1}[0-9]{8}$|18[0-9]{1}[0-9]{8}|17[0-9]{1}[0-9]{8}$|14[0-9]{1}[0-9]{8}/;
        if (this.formData.phone == "") {
          this.$message({
            message: '请输入您的手机号',
            type: 'warning'
          });
          return false;
        }
        if (!phone_reg.test(this.formData.phone)) {
          this.$message({
            message: '请输入正确格式的手机号码',
            type: 'warning'
          });
          return false;
        }
        if (this.formData.messageCode == "") {
          this.$message({
            message: '请输入收到的验证码',
            type: 'warning'
          });
          return false;
        }

        if (this.formData.companName == "") {
          this.$message({
            message: '请输入您的公司名称',
            type: 'warning'
          });
          return false;
        }
        if (this.formData.appName == "") {
          this.$message({
            message: '请输入您的APP名称',
            type: 'warning'
          });
          return false;
        }
        // if (this.formData.department == "") {
        //   this.$message({
        //     message: '请输入您的所属部门',
        //     type: 'warning'
        //   });
        //   return false;
        // }
        // if (this.formData.station == "") {
        //   this.$message({
        //     message: '请输入您的所属岗位',
        //     type: 'warning'
        //   });
        //   return false;
        // }
        let datas = {
          deptName: this.formData.companName,
          userName: this.formData.userName,
          phonenumber: this.formData.phone+'',
          verifyCode: this.formData.messageCode,
          remark: this.formData.remark,
          station: this.formData.station,
          department: this.formData.department,
          appName: this.formData.appName,
          userType: "2",
        };
        const res = await collectUser(datas);
        if(res.code == 200) {
          this.$message({
            message: '信息提交成功',
            type: 'success'
          });
          this.formData.companName = '';
          this.formData.userName = '';
          this.formData.phone = '';
          this.formData.messageCode = '';
          this.formData.remark = '';
          this.formData.station = '';
          this.formData.department = '';
          this.formData.appName = '';
          this.timeDown = 0;
          this.disabled = true;
        }else {
          let message= res.msg;
          this.$message({
            message: `${message}`,
            type: 'error'
          });
        }
      },
    },
    mounted() {

    }
  };
</script>
<style lang="scss" scoped>
.headers{
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #2a3042;
  .logoImg{
    display: inline-block;
    height: 30px;
    padding-left: 25px;
  }
}
.demo_container{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .demo_left_bg{
    width: 60%;
    overflow: auto;
    .bannerStyCon{
      width: 100%;
      height: 350px;
      position: relative;
      background-size: 100% 350px;
      padding-top: 150px;
      background: url("../../assets/image/index/order2.png") no-repeat top;
      .bannerSty{
        width: 600px;
        height: 300px;
        box-shadow: 3px 3px 20px #666;
        background: url("../../assets/image/index/order1.png") no-repeat center;
        background-size: 100% 100%;
        margin: 0 auto;
        .banner_text1{
          padding-top: 40px;
          font-size: 30px;
          display: flex;
          font-weight: bolder;
          .textLeft{
            color: #fff;
            width: 33.3%;
            text-align: right;
          }
          .textRight{
            width: 66.6%;
            background: linear-gradient(to right, #493dd1, #a634e7);
            -webkit-background-clip: text;
            color: transparent;
          }
        }
        .banner_text2{
          font-size: 24px;
          padding: 10% 8.3% 0 38.3%;
        }
      }
    }
    .demoLeftCon{
      padding-top: 120px;
      width: 52%;
      margin: 0 auto;
      font-size: 18px;
      .text1{
        margin-top: 50px;
        line-height: 48px;
      }
      .text2{
        margin-top: 50px;
        margin-bottom: 40px;
      }
      .text3{
        padding-left: 20px;
        li {
          font-weight: bolder;
          margin-bottom: 40px;
          list-style-type: disc;
        }
      }
      .text4{
        margin-bottom: 20px;
        margin-top: 50px;
      }
      .text5{
        margin-bottom: 350px;
        span{
          font-size: 20px;
          font-weight: bolder;
          margin-left: 20px;
        }
      }
    }
  }
  .demo_rightCon{
    width: 40%;
    left: 60%;
    top: 0;
    bottom: 0;
    z-index: -1;
    position: absolute;
  }
  .demo_rightFromCon{
    position: sticky;
    top: 0px;
    width: 40%;
    align-self: start;
    background: url("../../assets/image/index/contour.png") repeat top left, linear-gradient(to bottom right, #8b1fc9, #2a3042);
    .demoRightForm{
      padding: 150px 15%;
      h3{
        font-size: 30px;
        color: #fff;
        font-weight: bolder;
        text-align: center;
      }
      .line{
        background: #fff;
        width: 40px;
        height: 6px;
        margin: 50px auto;
      }
      .form-wrap {
        width: 100%;
        margin: 0 auto;
        .common-wrap {
          width: 100%;
          height: 64px;
          line-height: 64px;
          margin-bottom: 40px;
          border: 1px solid #E5E5E5;
          background: #fff;
          border-radius: 10px;
          padding: 0 20px;
          box-sizing: border-box;
          position: relative;
          display: flex;
          .send {
            display: inline-block;
            width: 100px;
            height: 42px;
            line-height: 42px;
            text-align: center;
            background: #493DD1;
            border-radius: 6px;
            font-size: 14px;
            color: #fff;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 10px;
          }
          .send.disabled {
            background-color: #f0f0f0;
            color: #ccc;
            cursor: not-allowed;
          }
          .login-icon {
            display: inline-block;
            width: 28px;
            height: 28px;
            margin-top: 18px;
          }
          .code-icon {
            display: inline-block;
            width: 70px;
            height: 35px;
            vertical-align: middle;
            cursor: pointer;
          }
        }
        .commonText-wrap{
          height: 120px;
          padding: 11px 20px;
          .login-icon{
            margin-top: 0;
          }
        }
        .common-wrap.selected {
          border: 1px solid #493DD1;
        }
        .common-wrap /deep/ .el-input {
          width: 94% !important;
        }
        .common-wrap /deep/ .el-textarea {
          width: 94% !important;
          height: 100%;
        }
        .el-input /deep/ .el-input__inner {
          border: 0 none !important;
          color: #493DD1 !important;
        }
        .el-textarea /deep/ .el-textarea__inner {
          resize: none;
          height: 100% !important;
          border: 0 none !important;
        }
        .login {
          width: 100%;
          height: 60px;
          line-height: 60px;
          background: linear-gradient(-10deg, #A634E7, #493DD1);
          box-shadow: 0px 5px 10px 0px rgba(7, 0, 147, 0.15);
          border-radius: 30px;
          font-size: 14px;
          text-align: center;
          font-weight: 400;
          color: #fff;
          cursor: pointer;
          margin: 20px auto;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .demo_container {
    display: block;
    .demo_left_bg{
      width: 100%;
    }
    .demo_rightCon{
      width: 100%;
    }
    .demo_rightFromCon{
      width: 100%;
    }
  }
}
</style>
