<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>

<style scoped lang="scss">
#app {
  width: 100%;
  height: auto;
}

</style>
