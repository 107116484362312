<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">了解广告SDK工作机制，保护APP自身安全</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2021年8月9日</span>
      <span class="detailTxt">分类：产品功能</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="module1">
      <div class="consult-content">
        <p class="contentTxt">SDK，APP开发者应该都很了解，开发者们或多或少都接触或安装过，而提到SDK安全性这个问题，开发者们的情绪可能都不会平静，这其中的利害关系确实有点儿复杂。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">一方面，SDK方便开发者产品设计，帮助开发者简化开发步骤，提高开发效率，节约开发成本。</span>像框架类、广告类、推送类、统计类、地图类、社交类、支付类、客服类、测试类等等，这些不同类型的SDK极大地方便了开发者。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">而另一方面，对开发者来说，也不得不承担，因嵌入第三方SDK后带来的风险、后果。</span>由于SDK开发者开发能力的参差不齐，可能因为技术原因或恶意‘留后门’而存在大量安全漏洞，还有一些SDK会违规读取和储存用户隐私，或执行越权操作等，这些问题轻则造成APP卡顿，重则被应用市场下架，更严重的情况是漏洞被攻击，就可能会给APP及APP用户带来严重损失。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">早在2020年7月，延迟四个月举行的3.15晚会中，央视曝光了50多款App中内嵌SDK插件窃取用户隐私的问题，这些App通过内置SDK插件，在用户不知情的情况下，读取、上传用户电话号码、通讯录、短信记录、应用列表等信息，并窃取联系人、交易验证码等数据，严重侵犯用户隐私权益、财产安全……一时间，SDK成为“众矢之的”，令社会普遍认为，SDK是造成个人隐私数据泄露的“元凶”。上月，一则“滴滴出行”APP因严重违法违规收集使用个人信息被应用市场下架的消息，让个人信息安全问题重回热点，像滴滴这样的Hero APP都被下架了，更是令众多APP经营者风声鹤唳。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">事实真的如此吗？SDK真的是“洪水猛兽”吗？让我们来看一组数据。</span>根据观研天下发布的《2019年中国移动APP行业分析报告》调查数据显示，截止2018年第四季度，<span class="txtBolder">全球APP平均安装SDK数量达18.2个/APP，100-500万下载量的APP的SDK平均安装数量最多，达27.7个。</span></p>
      </div>
      <!-- 图片 -->
      <div class="img-warp">
        <p class="imgCenterInfo">2016-2018年全球APP平均安装SDK数量</p>
        <img src="../../../assets/image/consult/info8/consult_info1.png" alt="infoImg" class="infoImg">
      </div>
      <!-- 图片 -->
      <div class="img-warp">
        <p class="imgCenterInfo">2018年12月不同下载量级别APP的SDK数</p>
        <img src="../../../assets/image/consult/info8/consult_info2.png" alt="infoImg" class="infoImg">
      </div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">2019年，</span>南都个人信息保护研究中心委托中国金融认证中心针对使用率高的 SDK 做了隐私数据分析报告。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">报告显示，在测评的 60 款常用应用软件中，共使用了至少 966 个 SDK，<span class="txtBolder">平均每款 App 使用19.3 个</span><span class="txtLight">（注：移动金融类未计在内，因为该行业普遍对 App 进行加固，难以确切检测出使用的 SDK）。</span>其中生活服务类 App 平均使用的 SDK 个数最多，为 20.2 个，旅游交通类最少，平均使用 15.4 个 SDK。由此我们可以看出，SDK之于APP开发者们，在风险可控的情况下，并不排斥。</p>
      </div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info8/consult_info3.png" alt="infoImg" class="infoImg">
        <p class="imgIntroduce">数据来源：《常用第三方 SDK 收集使用个人信息测评报告》/ 南都个人信息保护研究中心中国金融认证中心（CFCA）</p>
      </div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">广告SDK是绝大多数App开发者在考虑商业化变现时，经常要用到的。下面我们就对广告SDK的功能分类，集成规范，工作原理来详细解读，以便APP开发者对广告SDK有更深入的了解。</p>
      </div>
    </div>
    <!-------------------------------- APP与广告SDK -------------------------------->
    <div class="module2">
      <!-- 副标题 -->
      <div class="title_sub">APP与广告SDK</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">根据2020年11月全国信息安全标准化技术委员会《网络安全标准实践指南-移动互联网应用程序（App）使用软件开发工具包（SDK）安全指引》中对SDK的分类，常见SDK类型分为16类（见下图），其中，对广告SDK的功能描述是提供广告展示功能，通过使用广告SDK，App提供者可以在App中展示广告商投放的广告，进而根据用户的点击赚取收益。</p>
      </div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info8/consult_info4.png" alt="infoImg" class="infoImg">
        <p class="imgIntroduce">资料来源：《网络安全标准实践指南—移动互联网应用程序（App）使用软件开发工具包（SDK）安全指引》</p>
      </div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">广告SDK在众多SDK类型中，是比较特殊的一类，其他SDK主要是方便APP自身运营需要，而广告SDK它的功能目的是直接的商业化变现。因此，具备一定规模流量的想快速获得收益的APP开发者都会考虑接入广告SDK。</p>
      </div>
    </div>
    <!-------------------------------- 广告SDK分类及用途 -------------------------------->
    <div class="module3">
      <!-- 副标题 -->
      <div class="title_sub">广告SDK分类及用途</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">广告SDK的功能是展示广告，从定义上来说，还分广义的和狭义的广告SDK。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">广义的广告SDK：</span>指为开发者提供广告相关功能（接入广告、广告监测等）的软件开发工具包。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">狭义的广告SDK：</span>指帮助App开发者接入广告，实现广告变现的软件开发工具包。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">从用途上分：</span>可以分为网页SDK和APP SDK，网页SDK用于网页（或小程序）的广告变现。APP SDK用于移动应用程序内的广告变现。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">APP SDK从编写语言上分：</span>又可分为Java SDK、Objective-C SDK。Java SDK适用于Android，Objective-C SDK适用于iOS。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">按照不同广告领域：</span>可分为展示广告SDK，激励广告SDK、互动广告SDK等。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">（1）展示广告SDK一般用于仅展示广告素材类型的广告，使用范围最为普遍。</p>
        <p class="contentTxt">（2）激励广告SDK一般用于有激励场景的APP，像激励视频、积分墙等广告形式，游戏APP安装较多。</p>
        <p class="contentTxt">（3）互动广告SDK，多用于像抽奖、大转盘等类型的广告形式，使用范围广泛，工具类应用较多安装。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">根据sdk商业性质来看：</span>可以分为单一广告SDK和聚合广告SDK。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">单一广告SDK直接用来进行广告投放，一般开发者多为广告联盟，仅用于需求方的广告投放。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">聚合广告SDK集成多家广告联盟SDK，可实现多平台的广告投放，开发者多为广告技术公司，主要用于方便APP开发者在进行多广告联盟变现时的运营优化。</p>
      </div>
    </div>
    <!-------------------------------- 广告SDK如何接入 -------------------------------->
    <div class="module4">
      <!-- 副标题 -->
      <div class="title_sub">广告SDK如何接入</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">一般广告SDK的开发者都会有对接文档给到APP开发者，APP开发者根据文档步骤自助对接，有问题的话再以邮件或工单提交等形式联系广告SDK开发者。有的广告SDK开发者会安排专人跟踪对接服务，相对来说处理问题会比较及时。总的来说，不管哪种服务方式，集成过程都基本遵循这样一个步骤：</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">Step 1 加入文件：</span>根据文档内容，将广告SDK嵌入指定项目目录中。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">Step 2 创建关联：</span>建立关联关系，向广告SDK添加引用，添加配置、权限等。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">Step 3 代码写入：</span>应用初始化及广告位开发，调用广告SDK提供的广告位模板。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">Step 4 测试联调：</span>测试广告流程，是否正常触发广告请求、返回广告，广告素材下发、渲染、展示是否正常，是否流畅，广告数据统计是否正常等内容。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">Step 5 更新软件：</span>将集成过广告SDK的APP上架到应用市场</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">完成以上步骤，广告位基本能够请求到广告，实现广告变现。</p>
      </div>
    </div>
    <!-------------------------------- 广告SDK的请求机制 -------------------------------->
    <div class="module5">
      <!-- 副标题 -->
      <div class="title_sub">广告SDK的请求机制</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">对于单一广告SDK和聚合广告SDK来说，工作机制基本相同，都是APP启动，调用SDK，触发广告请求，发送给广告平台服务器，广告平台服务器返回广告，下发素材，展示广告，如下图。</p>
      </div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info8/consult_info5.png" alt="infoImg" class="infoImg">
        <p class="imgIntroduce">广告SDK运行机制</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">区别在于，在发送广告请求上，聚合广告SDK会多一步转发请求的动作，将广告请求转发多家广告联盟平台。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">广告SDK的工作流程已经很清晰，但对APP开发者来说，这些可能都不是难点，最大的顾虑还是在SDK的安全性上。主要问题，一是，SDK安全漏洞。二是，是否预留“后门”。三是，数据是否滥用。最后，问题还是落在这三点上，要避免这些问题，最好的方法是对广告SDK源码进行核查。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">事实上，现在的做法也是这样的。一般开发者对接广告SDK的时候都会对SDK进行审查，但对于不是很了解广告行业或者技术能力不足的开发者，在SDK审查上，既有顾虑又可能无从下手，这里轻变聚合在SDK核查方面给出一些方法，以便于开发者操作。</p>
      </div>
    </div>
    <!-------------------------------- 广告SDK的核查 -------------------------------->
    <div class="module6">
      <!-- 副标题 -->
      <div class="title_sub">广告SDK的核查</div>
      <!-- 文字标题 -->
      <div class="title_txt">查什么？</div>
      <!-- 节点1 -->
      <div class="node-warp1">
        <!-- 节点标题 -->
        <div class="title_node">（1）来源安全性评估</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">SDK提供者的基本信息，沟通反馈渠道，隐私政策链接地址，提供者安全能力，SDK基本功能，SDK版本号等。</p>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">（2）代码安全性评估</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">是否存在已知的恶意代码，是否存在已知的安全漏洞，是否申请敏感权限，是否嵌入其他SDK。</p>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">（3）行为安全性评估</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">调用的敏感权限、目的和频率，收集的个人信息类型、目的和频率，个人信息回传服务器域名、IP地址、所在地域，是否存在热更新行为及热更新是否可主动关闭，传输数据是否加密，是否存在单独收集用户个人信息的界面，是否存在后台自动启动和关联启动后收集个人信息的行为等。</p>
        </div>
      </div>
      <!-- 文字标题 -->
      <div class="title_txt">怎么查？</div>
      <!-- 节点2 -->
      <div class="node-warp1">
        <!-- 节点标题 -->
        <div class="title_node">（1）文档审核</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">在接入SDK前，对广告SDK开发者提供的文档进行核查，根据前面提到的几项主要内容，对照文档查看是否都符合要求，还有双方未达成一致或有争议的内容。</p>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">（2）工具抓包</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">接入SDK后，在进行测试过程中，使用工具对广告SDK网络工作流进行抓包操作。通过抓包主要验证安全性评估内容，并查看是否有在文档和商务谈判中未涉及到的隐秘及可疑行为。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">市面上的抓包工具很多，这里主要介绍适合移动端开发者使用的抓包工具，Charles，Fiddler，Replica（移动端APP），这三款都不错，这里比较推荐Charles，它支持Windows，Mac，Linux，安装使用简单，功能强大。</p>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">（3）第三方认证</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">通过具有安全审核资质的第三方公司来对广告SDK进行审核工作，出具第三方评估报告，一般也是在接入后的测试环节进行。第三方认证机构也很多，比如360安全专家、网易易盾、腾讯安全等，但很多第三方的机构都是付费的。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">作为一种对接方式，广告SDK在APP商业化变现的过程中扮演重要的角色，在移动广告事业的发展中也起着非常关键的作用。正是由于SDK在移动广告行业中的广泛使用，才创造了移动互联网广告市场的万亿规模，为社会带来巨大的经济价值。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">SDK创造的价值不应被忽视，而只谈影响，SDK需要的是监管和合规，而不是取缔。欣喜的是，政府及监管部门正在出台多项法律法规积极治理，相信不久的将来，SDK将迎来一个净化过的市场环境，App开发者与SDK开发者终将以建立互信的商业合作关系结尾。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo8',
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
</style>
