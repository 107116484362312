<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">轻变聚合斩获金鼠标大赛“年度最佳数字营销工具”奖项！</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2023年5月23日</span>
      <span class="detailTxt">分类：新闻动态</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="consult-content">
      <p class="contentTxt">2023年5月19日，金鼠标国际数字营销节在北京举行，第十四届金鼠标数字营销大赛结果也在此次大会正式揭晓，上海倍孜凭借“轻变聚合 移动广告变现管理系统”成功斩获“年度最佳数字营销工具”奖项。</p>
    </div>
    <div class="img-warp special-img-wrap">
      <img src="@/assets/image/consult/info25/consult_info1.png" alt="infoImg" class="infoImg special-img">
      <img src="@/assets/image/consult/info25/consult_info2.png" alt="infoImg" class="infoImg special-img">
    </div>
    <div class="consult-content">
      <p class="contentTxt">作为领衔数字营销领域的专业赛事，金鼠标数字营销大赛一直肩负着记录行业发展进化和颠覆创新的使命，挖掘并传递数字营销的黄金价值。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">“年度最佳数字营销工具”奖项主要针对优秀数字营销产品开展，该奖项由数字营销行业产品与技术领域领袖级人物评出，一定程度上代表业界对上海倍孜的认可，对轻变聚合 移动广告变现管理系统的满意。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">上海倍孜网络技术有限公司自2017年成立以来，一直积极关注移动应用商业化变现趋势，从媒体变现实际需求出发，依托多年移动应用商业化从业经验，打造专为移动应用开发者服务的流量变现工具化产品——轻变聚合移动广告变现管理平台。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">轻变聚合广告变现管理平台是国内最早推出以Bidding为核心技术的聚合工具服务企业，主要为国内开发者提供服务，在不断的发展中，已经服务了5000+开发者，月覆盖用户数达到3亿。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">轻变聚合移动广告变现管理平台支持Bidding及Bidding+Waterfall模式，通过系统工具进行广告分层、智能混排、自动价格排序、流量分组、A/B测试等多种广告策略调优，同时，系统还可以提供实时多维度流量数据分析报表，帮助开发者直观查看广告变现情况，对变现过程中出现的异常情况，系统还会及时预警，节省运营人力，帮助开发者实现更好变现。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">轻变聚合广告变现管理平台现已接入多个国内外主流广告平台，同时支持开发者自有广告源接入，如您有变现需求，轻变聚合将是您不错的选择，期待与您达成合作！</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo25'
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
  .special-img-wrap {
    text-align: center;
    .special-img {
      width: 50% !important;
    }
  }
</style>
