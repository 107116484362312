import axios from 'axios';
import qs from 'qs';
import config from '@/config/config';
import router from '../router'
import Vue from 'vue';

/*
  * Request(): 请求后台数据，返回成功后，执行callback
  * url: 请求的半路径
  * params: { data：请求参数，methods：请求方式，callback：回调方法 }
*/
export const Request = (url = '', params = {}) => {

    let requestData = JSON.stringify(params.data);

    if (params.methods == 'GET') {
        return new Promise((resolve) => {
            axios.get( url, {
                params: params.data,
            }).then((res) => {
                return resolve(res.data);
            }).catch(err => {
                console.log(err);
                return false;
            });
        });
    } else {
        return new Promise((resolve) => {
            axios({
                url: url,
                method: 'POST',
                data: requestData,
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => {
                return resolve(res.data);
            }).catch(err => {
                console.log(err);
                return false;
            })
        });
    }
};