<template>
  <div class="container">
    <!-- 公用头部 -->
    <headers :activeindex="2"></headers>
    <!-- 解决方案头部 -->
    <div class="solution-warp">
      <div class="banner-wrap">
        <img :src="bannerContent.bannerUrl" alt="背景" class="banner">
        <img :src="bannerContent.bannerTxt" alt="解决方案" class="banner_txt">
        <div class="banner-title">{{ bannerContent.bannerTitle }}</div>
        <div class="register_btn" @click="goToRegister">
          <span class="btn_txt btn_txt_left">立即注册</span>
          <span class="btn_txt btn_txt_right">体验DEMO</span>
        </div>
      </div>
    </div>
    <frequency v-if="routeHash === ''"></frequency>
    <channel v-if="routeHash === '2'"></channel>
    <showRate v-if="routeHash === '3'"></showRate>
    <!-- 底部 -- 点击马上合作 -->
    <footer-btn></footer-btn>
    <!-- 底部导航 -->
    <footers></footers>
  </div>
</template>

<script>
  import headers from "@/component/header";
  import footers from "@/component/footer";
  import footerBtn from "@/component/footer-btn";
  import frequency from './components/frequency';
  import channel from './components/channel';
  import showRate from './components/showRate';
  export default {
    name: 'solution',
    components: {
      headers,
      footers,
      footerBtn,
      frequency,
      channel,
      showRate
    },
    computed: {
      routeHash() {
        if (this.$route.hash === '') return '';
        let routeHash = this.$route.hash.split('=')[1];
        return routeHash;
      },
      // 获取解决方案顶部banner、txt、title
      bannerContent() {
        let bannerCon = { bannerUrl: '', bannerTxt: '', bannerTitle: '' };
        if (this.routeHash === '') {
          bannerCon.bannerUrl = require('../../assets/image/solution/solution1/solution1_banner.png');
          bannerCon.bannerTxt = require('../../assets/image/solution/solution1/solution1_banner_txt.png');
          bannerCon.bannerTitle = 'A/BTest实践最佳收益模型，实现高频广告位收益最大化';
        } else {
          if(this.routeHash == 2) {
            bannerCon.bannerUrl = require('../../assets/image/solution/solution2/solution2_banner.png');
            bannerCon.bannerTxt = require('../../assets/image/solution/solution2/solution2_banner_txt.png');
            bannerCon.bannerTitle = '流量精细化管理+策略支撑，帮助媒体整体收益提升';
          } else {
            bannerCon.bannerUrl = require('../../assets/image/solution/solution3/solution3_banner.png');
            bannerCon.bannerTxt = require('../../assets/image/solution/solution3/solution3_banner_txt.png');
            bannerCon.bannerTitle = '系统精准诊断分析，制定针对性策略提升曝光率';
          }
        }
        return bannerCon
      }
    },
    methods: {
      goToRegister() {
        window.open(process.env.VUE_APP_HREF_AD_SCOPE + '#/register', "register");
      }
    }
  }
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: auto;
  /* 顶部背景 */
  .solution-warp {
    width: 100%;
    height: auto;
    .banner-wrap {
      height: 580px;
      position: relative;
      overflow: hidden;
      .banner {
        position: absolute;
        left: 50%;
        width: 1920px;
        height: 580px;
        margin-left: -960px;
      }
      .banner_txt{
        position: absolute;
        left: 50%;
        top: 200px;
        width: 610px;
        height: 84px;
        margin-left: -305px;
      }
      .banner-title {
        position: absolute;
        top: 304px;
        width: 100%;
        color: #fff;
        text-align: center;
        font-size: 30px;
        font-weight: bolder;
        text-shadow: 0px 4px 20px rgba(11, 2, 5, 0.5);
      }
      .register_btn{
        position: absolute;
        width: 400px;
        height: 60px;
        bottom: 100px;
        left: 50%;
        margin-left: -200px;
        text-align: center;
        line-height: 60px;
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        background: linear-gradient(-10deg, #A634E7, #493DD1);
        box-shadow: 0px 5px 10px 0px rgba(7, 0, 147, 0.15);
        border-radius: 30px;
        display: flex;
        cursor: pointer;
        .btn_txt {
          flex: 1;
        }
        .btn_txt_left {
          text-align: right;
          padding-right: 10px;
        }
        .btn_txt_right {
          text-align: left;
          padding-left: 10px;
        }
      }
    }
  }
}
</style>
