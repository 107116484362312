<template>
  <div>
    <div class="frequency-warp frequency-warp-one">
      <div class="frequency-container">
        <div class="container-title">媒体痛点</div>
        <div class="line"></div>
        <div class="container-title-two">填充高，曝光低</div>
        <div class="infoCon">
          <p class="infoTxt">有接近60%的广告曝光是由于网络环境不佳导致广告加载不成功，部分App用户处于弱网环境是问题成因。</p>
        </div>
        <div class="content-warp content-warp-one">
          <div class="content-info content-left">
            <img src="../../../assets/image/solution/solution3/solution3_chart1.png" class="content-left-img">
          </div>
          <div class="content-info content-right">
            <img src="../../../assets/image/solution/solution3/solution3_chart2.png" class="content-right-img">
          </div>
        </div>
      </div>
    </div>
    <div class="frequency-warp frequency-warp-two">
      <div class="frequency-container">
        <div class="container-title">轻变聚合解决方案</div>
        <div class="line"></div>
        <div class="infoCon">
          <p class="infoTxt">基于曝光流失原因，轻变聚合的诊断功能给出了更加精确的分析</p>
        </div>
        <div class="subtitle">弱网环境曝光率提升，通过系统诊断能力进行精准分析</div>
        <div class="content-warp content-warp-two">
          <div class="content-info content-left">
            <img src="../../../assets/image/solution/solution3/solution3_chart3.png" class="content-left-img">
          </div>
          <div class="content-info content-right">
            <img src="../../../assets/image/solution/solution3/solution3_chart4.png" class="content-right-img">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'solution-showRate'
  }
</script>

<style lang="scss" scoped>
  .frequency-warp-one {
    height: 680px;
    background: #FAFAFA;
    .frequency-container {
      background: url("../../../assets/image/solution/solution3/solution3_chart_bg.png") center bottom no-repeat;
    }
  }
  .frequency-warp-two {
    height: 805px;
  }
  .content-left-img {
    float: right;
    margin-right: 18px;
  }
  .content-right-img {
    float: left;
    margin-left: 18px;
  }
  .frequency-warp {
    width: 100%;
    .frequency-container {
      width: 1120px;
      height: 100%;
      margin: 0 auto;
      /* 标题 */
      .container-title {
        padding-top: 80px;
        width: 100%;
        text-align: center;
        color: #464646;
        font-size: 32px;
        font-weight: bolder;
        text-shadow: 0px 5px 10px rgba(7, 0, 147, 0.15);
      }
      .container-title-two {
        font-size: 24px;
        font-weight: bold;
        color: #464646;
        text-align: center;
        text-shadow: 0px 5px 10px rgba(7, 0, 147, 0.15);
        margin-top: 40px;
      }
      /* 详情 */
      .infoCon {
        width: 100%;
        margin-top: 20px;
        .infoTxt {
          text-align: center;
          font-size: 16px;
          line-height: 36px;
          color: #464646;
          font-weight: 400;
        }
      }
      /* 子标题 */
      .subtitle {
        width: 820px;
        height: 62px;
        margin: 40px auto;
        background: url("../../../assets/image/solution/title_bg.png") center no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        color: #fff;
        font-weight: bolder;
        text-align: center;
        line-height: 50px;
        text-shadow: 0px 5px 10px rgba(7, 0, 147, 0.15);
      }
      /* 主体内容 */
      .content-warp-one {
        margin-top: 40px;
        height: 290px;
      }
      .content-warp-two {
        height: 394px;
      }
      .content-warp{
        display: flex;
        .content-info {
          flex: 1;
        }
      }
    }
  }
</style>

