<template>
  <div>
    <div class="frequency-warp frequency-warp-one">
      <div class="frequency-container">
        <div class="container-title">媒体痛点</div>
        <div class="line"></div>
        <div class="content-warp content-warp-one">
          <div class="content-info content-left">
            <div class="content-left-top">
              <div class="title">渠道多，优化难</div>
              <div class="content">市场上，多数媒体都会同时对接多家售卖渠道；在众多渠道中流量如何分配如何售卖，实现收益最大化，成了难题。</div>
            </div>
            <div class="content-left-bottom">
              <div class="title">
                <span class="txt">代表性的媒体业务场景</span>
              </div>
              <div class="content">如工具类App使用频次低，流量库存少；但用户营销价值大，单价高；开发者往往对接了多渠道，以寻求最佳收益，给运营带来难度。</div>
            </div>
          </div>
          <div class="content-info content-right">
            <img src="../../../assets/image/solution/solution2/solution2_chart1.png">
          </div>
        </div>
      </div>
    </div>
    <div class="frequency-warp frequency-warp-two">
      <div class="frequency-container">
        <div class="container-title">轻变聚合解决方案</div>
        <div class="line"></div>
        <div class="infoCon">
          <p class="infoTxt">轻变聚合串、并行分层售卖策略，可区分用户进行混合式售卖，解决多渠道售卖问题</p>
        </div>
        <div class="subtitle">多渠道流量细分售卖，区分用户，混合策略</div>
        <div class="content-warp content-warp-two">
          <div class="content-info content-left">
            <img src="../../../assets/image/solution/solution2/solution2_chart2.png">
          </div>
          <div class="content-info content-right">
            <div class="content">
              <div class="title">调整策略</div>
              <div class="conTxt">
                <p class="txt">优质网络用户，网络环境较好，可以串行请求；</p>
                <p class="txt">弱网用户，SDK效率更高，优先填充；</p>
                <p class="txt">通过数据的积累，来及时调整渠道之间的分量，获取更高的收益。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'solution-channel'
  }
</script>

<style lang="scss" scoped>
  .frequency-warp-one {
    height: 628px;
  }
  .frequency-warp-two {
    height: 805px;
    background: #FAFAFA;
  }
  .frequency-warp {
    width: 100%;
    .frequency-container {
      width: 1120px;
      height: 100%;
      margin: 0 auto;
      /* 标题 */
      .container-title {
        padding-top: 80px;
        width: 100%;
        text-align: center;
        color: #464646;
        font-size: 32px;
        font-weight: bolder;
        text-shadow: 0px 5px 10px rgba(7, 0, 147, 0.15);
      }
      /* 详情 */
      .infoCon {
        width: 100%;
        margin-top: 20px;
        .infoTxt {
          text-align: center;
          font-size: 16px;
          line-height: 36px;
          color: #464646;
          font-weight: 400;
        }
      }
      /* 子标题 */
      .subtitle {
        width: 820px;
        height: 62px;
        margin: 40px auto;
        background: url("../../../assets/image/solution/title_bg.png") center no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        color: #fff;
        font-weight: bolder;
        text-align: center;
        line-height: 50px;
        text-shadow: 0px 5px 10px rgba(7, 0, 147, 0.15);
      }
      /* 主体内容 */
      .content-warp-one {
        margin-top: 40px;
        height: 290px;
        /* 左边内容部分 */
        .content-left {
          .content-left-top {
            background: url("../../../assets/image/solution/solution2/solution2_icon1.png") left top no-repeat;
            background-size: 98px 100px;
            margin-bottom: 45px;
            .title {
              font-size: 24px;
              font-weight: bold;
              color: #464646;
              padding: 30px 0 20px 0;
              text-shadow: 0px 5px 10px rgba(7, 0, 147, 0.15);
            }
            .content {
              width: 506px;
              font-size: 16px;
              font-weight: 400;
              color: #464646;
              line-height: 30px;
            }
          }
          .content-left-bottom {
            .title {
              height: 34px;
              background: url("../../../assets/image/solution/solution_icon.png") left no-repeat;
              background-size: 34px;
              .txt {
                display: inline-block;
                font-size: 16px;
                color: #464646;
                font-weight: bold;
                padding-left: 44px;
                margin-top: 10px;
              }
            }
            .content {
              margin-top: 16px;
              font-size: 14px;
              color: #6B6B6B;
              width: 506px;
            }
          }
        }
      }
      .content-warp-two {
        height: 394px;
        .content-right {
          padding-left: 105px;
          .content {
            height: 158px;
            width: 450px;
            background: url("../../../assets/image/solution/circula_bg.png") left no-repeat;
            background-size: 158px;
            padding-left: 68px;
            .title {
              line-height: 100px;
              font-size: 24px;
              color: #464646;
              font-weight: bold;
              text-shadow: 0px 5px 10px rgba(7, 0, 147, 0.15);
            }
            .conTxt {
              font-size: 16px;
              color: #464646;
              .txt {
                margin-bottom: 60px;
              }
            }
          }
        }
      }
      .content-warp{
        display: flex;
        .content-info {
          flex: 1;
        }
      }
    }
  }
</style>

