<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">混合竞价模式下，App开发者如何制定价格策略</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2022年9月8日</span>
      <span class="detailTxt">分类：产品功能</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="consult-content">
      <p class="contentTxt">流量变现市场，从去年开始，以穿山甲、优量汇为代表的国内主流变现平台纷纷上线了聚合广告变现产品，更多的第三方聚合广告平台也逐步被广大开发者使用，在流量变现方面，无论是之前已有变现策略，具备独立开发能力的开发者，还是没有使用过变现优化功能的开发者，基本都已经对工具优化收益有一定的了解，工具产品已经被极大程度的接受。</p>
      <p class="contentTxt">而对想通过优化手段提高变现收益的开发者来说，所面临的问题就是如何制定变现策略，而这其中变现策略的主要方面体现在价格策略上，在当前多渠道混合竞价模式下，采取什么样的价格策略决定了最终的广告收益，所以，如何制定价格策略成为开发者优化提升收益首先要解决的问题。</p>
    </div>
    <div class="module1">
      <!-- 副标题 -->
      <div class="title_sub">价格类型有哪些</div>
      <div class="consult-content">
        <p class="contentTxt">制定合理有效的价格策略，首先要了解当前都有哪些价格类型，目前国内市场上主要三个类型，分别是<span class="txtBolder">固定价、目标价、实时竞价。</span></p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">固定价：</span>通常是开发者根据经验和自己的底线设置的，兜底价格，可保证最低收益，同时起到保护用户体验的目的， 固定底价的劣势是流量价值黑盒，天级ecpm不确定性强，不利于比价，填充效率低。</p>
      </div>
      <!-- 节点标题 -->
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">目标价：</span>基于历史价格设定串行比价逻辑，优化流量分配，相比底价能更好平衡价格诉求和填充率，目标价主要劣势是基于经验设价，维护成本高，多层串行询价效率低，影响展示率和用户体验。</p>
      </div>
      <!-- 节点标题 -->
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">实时竞价：</span>对流量方最公平透明的价格模式，充分竞价，提升竞争密度，充分挖掘流量价值，高效询价，充分提升库存利用率，降低维护成本。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><p class="contentTxt">目前，市场主流是<span class="txtBolder">目标价+实时竞价混合竞价模式。</span></p>
      </div>
      <div class="img-warp special-img-wrap">
        <img src="@/assets/image/consult/info19/consult_info1.png" alt="infoImg" class="infoImg">
      </div>
    </div>
    <div class="module2">
      <!-- 副标题 -->
      <div class="title_sub">如何基于不同广告源设计整体变现架构</div>
      <div class="title_txt">C2S？S2S？适合自己最重要</div>
      <div class="consult-content">
        <p class="contentTxt">当前开发者的比价方式，主要分为两种，<span class="txtBolder">一种是客户端比价，另一种是服务端比价</span>，两种方式最重要的区别是比价发生在哪一方，客户端比价，比价过程全部在开发者客户端，服务端比价，比价过程可以发生在客户端以外的任何一方。</p>
      </div>
      <!-- 节点标题 -->
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">客户端竞价方式（C2S Bidding）：</span>适用开发者，对接多方SDK，且比价过程全部在客户端完成的开发者。接入成本低，比价模式简单，建议优先考虑C2S接入方式。</p>
      </div>
      <!-- 节点标题 -->
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">服务端竞价方式（S2S Bidding）：</span>适用开发者，使用第三方聚合SDK，自建聚合SDK或部分竞价过程发生在自建DSP平台的开发者。兼容三方/自建聚合SDK，灵活适配开发者自售场景，但接入成本高。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">优先推荐使用客户端竞价方式 C2S Bidding，接入运营成本低，逻辑简单易操作，开发能力较弱的开发者可直接选择第三方的聚合SDK，一次接入，即可实现以上操作。</span></p>
      </div>
      <div class="img-warp special-img-wrap">
        <img src="@/assets/image/consult/info19/consult_info2.png" alt="infoImg" class="infoImg">
      </div>
    </div>
    <div class="module3">
      <!-- 副标题 -->
      <div class="title_sub">比价逻辑，先Bidding后瀑布流</div>
      <div class="consult-content">
        <p class="contentTxt">优先Bidding，可使流量价值得到最大程度发挥，同时保证Bidding后流量不浪费，有填充。</p>
      </div>
      <div class="img-warp special-img-wrap">
        <img src="@/assets/image/consult/info19/consult_info3.png" alt="infoImg" class="infoImg">
      </div>
    </div>
    <div class="module4">
      <!-- 副标题 -->
      <div class="title_sub">请求设置符合实际</div>
      <div class="consult-content">
        <p class="contentTxt">为保证报价相关性，建议在保证展示率的前提下，使请求时机尽量贴近用户实际广告请求时间，无需过早请求。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">针对同用户在同场景下的一次曝光机会，建议瀑布流与Bidding发起一次请求即可，重复发送请求可能会影响对流量的真实价值判断，进而影响到对应广告位的长期收益。</p>
      </div>
    </div>
    <div class="module5">
      <!-- 副标题 -->
      <div class="title_sub">提升广告参竞，增加填充可能性</div>
      <div class="title_node"><span class="txt_icon dot_icon"></span>放开底价</div>
      <div class="consult-content">
        <p class="contentTxt">系统通过参与全量请求提升变现收入，底价逻辑往往无法基于实时预算与竞争环境实时更新，可能造成误伤。</p>
      </div>
      <div class="title_node"><span class="txt_icon dot_icon"></span>增加曝光</div>
      <div class="consult-content">
        <p class="contentTxt">竞胜广告享受曝光绝对优先级，建议优先曝光竞胜广告，可关注竞胜曝光率指标。</p>
        <p class="contentTxt">单次竞败广告可在缓存期内继续参与竞价，仅上报最终一次竞价结果即可。</p>
      </div>
      <div class="title_node"><span class="txt_icon dot_icon"></span>屏蔽精细化</div>
      <div class="consult-content">
        <p class="contentTxt">屏蔽策略容易导致广告竞价不充分，从而导致部分预算无法填充，建议通过精细化定向与柔性的屏蔽策略，充分释放预算潜力，不要通过硬性指标限制填充。</p>
      </div>
      <div class="title_node"><span class="txt_icon dot_icon"></span>数据充分且精准上传</div>
      <div class="consult-content">
        <p class="contentTxt">建议充分上传广告全链路数据指标，上传相关数据能够充分帮助系统准确评估流量价值，提升系统价格判断能力，获得最大收益。</p>
      </div>
    </div>
    <div class="module6">
      <div class="title_sub">能力进阶</div>
      <div class="title_node"><span class="txt_icon dot_icon"></span>竞争环境分析，理解竞价</div>
      <div class="consult-content">
        <p class="contentTxt">对广告竞争环境的分析，主要是了解买方的整体出价情况、所在行业、推广的产品信息等，尤其是胜出的广告主的出价、所在行业、产品等信息，可以帮助开发者更好的判断哪种类型的广告更适合自己，有更高的收益，获得更多的广告预算。</p>
      </div>
      <div class="title_node"><span class="txt_icon dot_icon"></span>了解广告场景，广告位，流量价值精准识别</div>
      <div class="consult-content">
        <p class="contentTxt">广告位和所在广告场景往往是APP广告价值的重要体现，充分理解广告位场景属性，有助于在相同场景下，稳定给出最优出价。</p>
      </div>
    </div>
    <div class="module7">
      <!-- 副标题 -->
      <div class="title_sub">混合竞价是当下最优解</div>
      <div class="consult-content">
        <p class="contentTxt">海外Open Bidding蓬勃发展，以Google、FaceBook为首的广告巨头玩家主导推进In-App竞价模式，而国内，正处在过渡时期的混合竞价模式。混合竞价在充分利用Bidding的同时，让非竞价渠道的收益得到最大保证，在当下是开发者变现的最优解决方式。</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo19'
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
  .special-img-wrap {
    text-align: center;
    .special-img {
      width: 50% !important;
    }
  }
</style>
