<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">轻变聚合产品趋势解读（一）：优量汇“多阶竞价”</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2021年11月15日</span>
      <span class="detailTxt">分类：行业趋势</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="consult-content">
      <p class="contentTxt">今年上半年，国内主流广告平台，优量汇和穿山甲都在提升媒体广告变现效率方面，升级了自身产品。优量汇推出了“多阶竞价”功能，支持开发者在多阶价格区间段内寻找最高价值的广告匹配。穿山甲也推出了Gromore聚合产品，“智能分层”支持开发者通过广告分层，对收益进行优化。很多正在变现的开发者都在问，“多阶竞价”和“智能分层”有什么作用？能带来多少提升？这里我们做个简短的分析，方便大家了解这些广告产品。本次主要针对优量汇“多阶竞价”来介绍。首先，多阶竞价是相对实时竞价来说的，实时竞价我们都知道，即广告请求发出后，广告平台返回中携带实时的价格，每次广告请求都返回一个实时价格，这样媒体就可以判断最高价格，把广告给到最优出价方。多阶竞价，跟实时竞价类似的是一个广告位ID只需一次请求，不同的是，<span class="txtBolder">多阶竞价返回的是阶梯价格区间，不是一个固定价格</span>，媒体可利用返回的价格区间大致判断最优出价，<span class="txtBolder">通过多阶竞价产品，可实现多层价格的探索</span>，同时保证首层流量价格最优，所以，多阶竞价虽然不像实时竞价确定性那么高，但也可在一定程度上实现收益最大化。</p>
    </div>
    <!-- 图片 -->
    <div class="img-warp">
      <img src="@/assets/image/consult/info13/consult_info1.gif" alt="infoImg" class="infoImg">
      <p class="imgIntroduce">优量汇多阶竞价广告请求示意图（来源：腾讯优量汇）</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">多阶竞价的应用，相对于简单瀑布流串行和并行的广告策略，在寻找最优价格方面优势明显，在提升收益方面，已得到很好验证，但在实际操作中，需求方的出价并不固定，低价格区间的需求方可能因为想获得优质的展示机会，会临时出一个更高的eCPM。但由于历史出价偏低，需求方只能在固定的竞价区间内参与竞拍，这样就存在潜在收益的流失。所以，需要开发者非常注意的是优化方法和技巧。<span class="txtBolder">优量汇多阶竞价优化技巧：</span>一个多阶价广告位最多可设置<span class="txtBolder">20层</span>价格，但是优量汇建议尽量控制在<span class="txtBolder">10层以内</span>。b.在设定每层价格层级时，要保证一定的价格区间跨度，层与层之间的价格差<span class="txtBolder">不小于5元</span>。c.在多阶价的<span class="txtBolder">首层设置目标价</span>，有助于在高价位层级争取到更多填充，<span class="txtBolder">其它层设置为底价策略</span>，可用于收益保底。</p>
      <p class="contentTxt">（了解更多优化技巧，登录轻变聚合官网咨询专业变现顾问）</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt"><span class="txtBolder">总结：</span>多阶竞价在当前流量变现过程中最大的优势是流量分配策略更具确切性，帮助开发者在流量筛选过程中识别到更多优质流量，能够更加合理的分配流量、优化比价逻辑，从而提升变现收益。</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo13'
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
</style>