<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">App商业化，开发者收益路径，广告变现仍是最好选择！</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2022年9月6日</span>
      <span class="detailTxt">分类：行业趋势</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="consult-content">
      <p class="contentTxt">APP商业化变现，一直以来就是困扰APP开发者的最大问题，与国外APP付费使用模式相比，国内APP通常都是免费模式，除部分有商品或服务提供的APP，大多数APP无内购等可实现收益的商业模式，因此，开发者们迫切需要一个能带来收益方法，接广告成为必然选择，但随着移动流量进入存量时代，广告变现之路也变得不那么容易，广告还是不是APP变现首选呢？2021年截止12月底，手机网民规模达10.29亿人，手机用户规模增长放缓，移动应用市场存量阶段更加明显。<span class="txtBolder">难道移动应用市场真的已达天花板嘛？</span></p>
    </div>
    <div class="module1">
      <div class="title_txt">移动流量仍有潜力可挖</div>
      <div class="consult-content">
        <p class="contentTxt">据APP Growing发布的《2022年移动应用运营增长洞察白皮书》分析，虽然手机用户规模逼近天花板，且为保证用户个人信息的隐私出台了大规模的个人信息保护政策，但移动互联网应用流量规模仍有增长空间。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">数据统计发现，<span class="txtBolder">虽然手机用户规模增长放缓趋于饱和，但移动应用仍有增长空间。</span>主要体现在以下几点：</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">1、移动应用用户单日有效使用时间提升5%以上，超过5.5个小时，用户对移动设备的使用更为深度，整体使用时长上升，开发者可在提升现有用户粘性方面大做文章。</p>
      </div>
      <div class="img-warp">
        <img src="../../../assets/image/consult/info18/consult_info1.png" alt="infoImg" class="infoImg">
      </div>
      <div class="img-warp">
        <img src="../../../assets/image/consult/info18/consult_info2.png" alt="infoImg" class="infoImg">
        <p class="imgCenterInfo">以上数据来源于《移动应用运营增长洞察白皮书》</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">2、虽然大盘流量增长缓慢，但占我国人口比68.4%的三线以下城市及农村地区的人群的娱乐、社交、电商等需求尚未被满足，企业仍有发力空间。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">3、随着科学技术的发展，新的智能设备不断研发，刺激新应用的产生和分发，为开发者们开辟了新的流量蓝海，增长前景依旧光明。</p>
      </div>
      <div class="img-warp">
        <img src="../../../assets/image/consult/info18/consult_info3.png" alt="infoImg" class="infoImg">
        <p class="imgCenterInfo">以上图片来源于《移动应用运营增长洞察白皮书》</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">4、伴随人工智能逐渐应用于运营工作，为降本增效提供了有力的支持，开发者可以进一步加强用户触达、提升用户覆盖，增强用户粘性。</p>
      </div>
    </div>
    <div class="module2">
      <div class="title_txt">移动广告增长趋势依然强劲</div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">基于流量市场仍有较大增长空间的前提，移动广告投放依旧前景广阔。</span>2021年截止到12月底，移动广告市场规模超过8000亿元，增速超20%，总体市场前景看好，<span class="txtBolder">预计2022年移动广告变现将超过万亿。</span></p>
      </div>
      <div class="img-warp">
        <img src="../../../assets/image/consult/info18/consult_info4.png" alt="infoImg" class="infoImg">
        <p class="imgCenterInfo">以上图片来源于《移动应用运营增长洞察白皮书》</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">从以上两个方面看，对于APP开发者来说，广告变现的宏观市场环境并没有那么糟糕，通过广告实现收益仍然是大有潜力的。</span></p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">虽然，移动应用和移动广告市场仍然有潜力可挖，但短期内对开发者广告变现来说，还是存在一定的不利因素</span>，2022年上半年，移动设备增长率放缓趋势更为明显，广告主们大幅缩减广告预算，广告KPI考核更为严格，各类针对移动营销的限制政策等等导致的eCPM单价降低，总体收益减少，用户流失等问题，使得移动应用短期内变现压力增大，迫使开发者必须拓展更多的广告渠道和采取更加精细化的流量运营策略，以达到保持收益提升的目的。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">聚合广告优化工具很好的满足了多渠道、精细化变现运营的市场需求，开发者借助聚合工具可实现跨渠道的广告分层、流量分组、实时竞价、A/B测试、数据分析等流量策略优化，精细化管理广告变现运营工作，达到收益提升的目标。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">轻变聚合聚合广告平台，通过五大核心能力有效帮助开发者应对短期变现增收压力。</span></p>
      </div>
      <!-- 节点1 -->
      <div class="node-warp1">
        <!-- 节点标题 -->
        <div class="title_node">1、实时竞价</div>
        <div class="consult-content">
          <p class="contentTxt">轻变聚合支持多家广告联盟参与竞价，<span class="txtBolder">针对每一次曝光机会公平出价，价高者胜出获得展示权</span>，可以有效提升广告填充收益；另外，轻变聚合聚合管理平台通过技术手段实现实时竞价，开发者接入聚合平台后仅需简单几步操作完成竞价设置，减少公司人力资源支出，解放运营人力。</p>
        </div>
        <!-- 图片 -->
        <div class="img-warp special-img-wrap">
          <img src="../../../assets/image/consult/info18/consult_info5.png" alt="infoImg" class="infoImg special-img">
        </div>
      </div>
      <!-- 节点2 -->
      <div class="node-warp2">
        <!-- 节点标题 -->
        <div class="title_node">2、业务诊断</div>
        <div class="consult-content">
          <p class="contentTxt">使用轻变聚合聚合管理平台，实现广告数据实时监测，通过数据展示，<span class="txtBolder">诊断增收难点，对标解决业务难题</span>。轻变聚合聚合管理平台主要有以下指标和分析点：</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">账户收益健康指标：</span>综合流量波动、曝光率、核减等指标计算账户收益健康情况与平台大盘进行横向对比；</p>
          <p class="contentTxt"><span class="txtBolder">账户数据健康指标：</span>综合收入、CPC、频次、ARPU等指标计算账户数据健康情况与平台大盘进行横向对比；</p>
          <p class="contentTxt"><span class="txtBolder">APP&广告位数据分析：</span>提供APP&广告位级别的健康指数展示，通过数据趋势、频次图表综合进行数据分析；</p>
          <p class="contentTxt"><span class="txtBolder">APP&广告位错误诊断：</span>提供APP&全流程化的错误诊断分析，从整体数据入手，将广告流程分为多个阶段来进行详细诊断。</p>
        </div>
      </div>
      <!-- 节点3 -->
      <div class="node-warp3">
        <!-- 节点标题 -->
        <div class="title_node">3、流量分组</div>
        <div class="consult-content">
          <p class="contentTxt">流量分组在APP广告变现中不算是新功能，但对于广大中小开发者来说，直接应用聚合工具提供的流量分组功能即可实现流量切分售卖提升变现收益。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">轻变聚合聚合管理平台通过对媒体流量拆分的手段，<span class="txtBolder">从多个维度实现流量拆分，目前支持9大维度流量规则配置，实现流量的精细化运营。</span>基本满足开发者流量分组测试需求。</p>
        </div>
        <!-- 图片 -->
        <div class="img-warp special-img-wrap">
          <img src="../../../assets/image/consult/info18/consult_info6.png" alt="infoImg" class="infoImg special-img">
        </div>
      </div>
      <!-- 节点4 -->
      <div class="node-warp4">
        <!-- 节点标题 -->
        <div class="title_node">4、灵活策略</div>
        <div class="consult-content">
          <p class="contentTxt">轻变聚合聚合管理平台还可实现灵活策略管理，<span class="txtBolder">主要支持权重分量和频次分量两种策略管理。</span></p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">权重分量：</span>基于流量分组对媒体的不同流量进行转发规则的设置，包括并行、串行、分量等；</p>
          <p class="contentTxt"><span class="txtBolder">频次分量：</span>对媒体的不同流量进行以频次为目标的转发规则的设置，包括：单次循环、循环规则等；</p>
        </div>
        <!-- 图片 -->
        <div class="img-warp special-img-wrap">
          <img src="../../../assets/image/consult/info18/consult_info7.png" alt="infoImg" class="infoImg special-img">
        </div>
      </div>
      <!-- 节点5 -->
      <div class="node-warp5">
        <!-- 节点标题 -->
        <div class="title_node">5、A/B测试</div>
        <div class="consult-content">
          <p class="contentTxt">轻变聚合 A/B测试功能作为变现优化工具核心功能，在不同的转发规则下进行A/B组的对照试验，对比各维度的数据，支持绝大多数开发者普遍的测试需求。</p>
        </div>
        <!-- 图片 -->
        <div class="img-warp special-img-wrap">
          <img src="../../../assets/image/consult/info18/consult_info8.png" alt="infoImg" class="infoImg special-img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo18'
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
  .special-img-wrap {
    text-align: center;
    .special-img {
      width: 50% !important;
    }
  }
</style>
