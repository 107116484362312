<template>
  <div>
    <!-- banner -->
    <div class="banner">
      <div class="title">变现提效增收工具</div>
      <div class="content">智能化提升媒体收益</div>
    </div>
    <!-- 聚合管理工具 -->
    <div class="tool-wrap">
      <p class="title font42 text-shadow fw700">
        <span class="spacing text-shadow fw700">聚合管理工</span>具
      </p>
      <div class="text-wrap-1">
        <div class="text">倍孜SDK支持竞价模式及多个竞价渠道，对媒体流量按照期望的方式进行灵活自主排序分配流量，</div>
        <div class="text">让不同广告平台间的优质优价广告充分竞争，从而找到收益最高的一个，实现最大化收益。</div>
      </div>
      <div class="text-wrap-2">
        <div class="text">同时，beizi sdk提供业务诊断、流量分组、A/B测试等管理工具，帮助开发者高效探索最优配置，</div>
        <div class="text">并通过数据报表能力，助力多维评估变现效果，让决策更科学高效</div>
      </div>
      <div class="video-wrap">
        <div class="icon"></div><div class="icon"></div><div class="icon"></div>
        <video
          class="video"
          ref="video"
          src="@/assets/video/introduce.mp4"
          poster="@/assets/image/product/pol/video-cover.png"
          controls
          controlslist="nodownload">
        </video>
      </div>
    </div>
    <!-- 聚合工具能力 -->
    <div class="ability-wrap">
      <p class="title font42 text-shadow fw700">
        <span class="spacing text-shadow fw700">聚合工具能</span>力
      </p>
      <div class="btn" @click="openOrder">预 约 演 示</div>
      <div class="swiper-wrap">
        <!-- swiper -->
        <swiper :options="toolSwiperOption">
          <swiper-slide v-for="item in toolList" :key="item.id">
            <div class="tool-content-wrap">
              <div class="img-wrap">
                <img class="tool-img" :src="item.imgUrl">
              </div>
              <div class="tool-cont-box">
                <div class="tool-content" v-if="item.content">
                  <div class="cont-title">{{ item.title }}</div>
                  <p class="contCont" v-for="(cont, index) in item.content" :key="index">{{ cont }}</p>
                </div>
                <div class="tool-content tool-content-special" v-else>
                  <div class="cont-title">{{ item.title }}</div>
                  <div v-for="(cont, index) in item.contentSpecial" :key="index">
                    <p class="contTitle">{{ cont.title }}</p>
                    <p class="contCont" :class="index + 1 === item.contentSpecial.length ? 'last-contCont' : ''">{{ cont.cont }}</p>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <!-- 如果需要分页器 -->
        <div class="tool-swiper-pagination swiper-pagination"></div>
      </div>
    </div>
    <!-- 合作案例 -->
    <div class="case-wrap">
      <p class="title colorfff font42 text-shadow fw700">
        <span class="spacing text-shadow fw700">合作案</span>例
      </p>
      <div class="explain fw700">
        <span class="spacing fw700">服务众多媒体，助力媒体实现收益提升及增长目</span>标
      </div>
      <div class="btn" @click="$router.push({ path: '/home/client-case' })">了 解 详 情</div>
      <div class="block-container">
        <div class="case-item" v-for="item in caseData" :key="item.label">
          <div class="label">{{ item.label }}</div>
          <div class="content">{{ item.content }}</div>
          <div class="rate-wrap">
            <div class="rate-item" v-for="rateItem in item.rateData" :key="rateItem.title">
              <div class="rate-title">{{ rateItem.title }}</div>
              <div class="rate">{{ rateItem.rate }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'product-pol',
    data() {
      return {
        // 轮播图
        toolSwiperOption: {
          pagination: {
            el: ".tool-swiper-pagination",
            clickable: true,
            // 处理分页器样式
            renderBullet: function(index, className) {
              let text = '';
              switch(index) {
                case 0: text = '竞价'; break;
                case 1: text = '诊断'; break;
                case 2: text = '流量组'; break;
                case 3: text = '策略'; break;
                case 4: text = '实验'; break;
              }
              return '<span class="'+ className +' span'+ index + '" style="color: #595959; font-size: 20px; border: none; opacity: 1; width: auto !important; height: auto !important; flex: 1; text-align: center;">'+ text +'</span>'
            //   background: none !important;
            }
          },
          loop: true,
          loopAdditionalSlides: 2,
        },
        toolList: [
          { id: 1, imgUrl: require('@/assets/image/product/pol/ability-swiper1.png'), title: '实时竞价（Bidding）',
            content: [ 'beizi sdk支持多家广告联盟参与竞价，针对每一次曝光机会公平出价，价高者胜出；', '竞价的方式可以有效的提升广告填充，并解放运营人力' ]
          },
          { id: 2, imgUrl: require('@/assets/image/product/pol/ability-swiper2.png'), title: '流量数据分析 业务状态诊断',
            contentSpecial: [
              { title: '账户收益健康指标', cont: '综合流量波动、曝光率、核减等指标计算账户收益健康情况与平台大盘进行横向对比；' },
              { title: '账户数据健康指标', cont: '综合收入、CPC、频次、ARPU等指标计算账户数据健康情况与平台大盘进行横向对比；' },
              { title: 'App&广告位数据分析', cont: '提供App&广告位级别的健康指数展示，通过数据趋势、频次图表综合进行数据分析' },
              { title: 'App&广告位错误诊断', cont: '提供App&全流程化的错误诊断分析，从整体数据入手，将广告流程分为多个阶段来进行详细诊断；' },
            ]
          },
          { id: 3, imgUrl: require('@/assets/image/product/pol/ability-swiper3.png'), title: '流量细分转发分组',
            content: [ '通过媒体流量的拆分，实现流量的精细化运营；', '操作系统、版本、网络环境、运营商等大量维度完成流量的细分' ]
          },
          { id: 4, imgUrl: require('@/assets/image/product/pol/ability-swiper4.png'), title: '灵活的策略管理',
            contentSpecial: [
              { title: '权重分量', cont: '基于流量分组对媒体的不同流量进行转发规则的设置，包括：并行、串行、分量等...' },
              { title: '频次分量', cont: '对媒体的不同流量进行以频次为目标的转发规则的设置，包括：单次规则、循环规则等...' },
            ]
          },
          { id: 5, imgUrl: require('@/assets/image/product/pol/ability-swiper5.png'), title: 'AB实验',
            content: [ '支持流量分层实验，在不同的转发规则下进行AB组的对照试验；', '对比各维度数据' ]
          }
        ],
        // 合作案例
        caseData: [
          {
            label: '某社交类APP',
            content: '倍孜SDK通过智能分量请求不同渠道，让其获得最佳收益，曝光成功率大幅提升...',
            rateData: [
              { title: '曝光率提升至', rate: '99%' },
              { title: '单价提升', rate: '15%' }
            ]
          },
          {
            label: '某工具类媒体',
            content: 'beizi sdk通过串并结合的请求策略及扩充的渠道资源，有效弥补流量低渠道少、缺乏优化经验的问题...',
            rateData: [
              { title: '填充率提升', rate: '8%' },
              { title: 'eCPM提升', rate: '10%' }
            ]
          },
          {
            label: '某小说阅读媒体',
            content: '流量收益显著增长，分频组预期收益平均高出未分频组35%，开屏广告填充流失降低...',
            rateData: [
              { title: '填充率提升', rate: '15%' },
              { title: '收入增幅', rate: '30%' }
            ]
          }
        ]
      }
    },
    mounted() {
      // 禁用video右键，禁止下载
      this.$refs.video.oncontextmenu = () => false;
    },
    methods: {
      openOrder() {
        window.open(window.location.origin + '#/home/demonstrate', "order")
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* banner */
  .banner {
    position: relative;
    height: 500px;
    background: url('~@/assets/image/product/pol/banner.png') center no-repeat;
    .title, .content {
      position: absolute;
      left: 50%;
      width: 1200px;
      margin-left: -600px;
      line-height: 1em;
      color: #1d1d1d;
      letter-spacing: 6px;
    }
    .title {
      top: 200px;
      font-size: 58px;
      font-weight: bold;
      color: #1d1d1d;
    }
    .content {
      top: 290px;
      font-size: 28px;
      color: #474747;
    }
  }
  /* 聚合管理工具 */
  .tool-wrap {
    height: 800px;
    background: url('~@/assets/image/product/pol/tool-bg.png') center no-repeat;
    .title {
      padding: 60px 0 40px;
      color: #272727;
      text-align: center;
      .spacing {
        letter-spacing: 4px;
      }
    }
    .text-wrap-1, .text-wrap-2 {
      color: #474747;
      font-size: 14px;
      line-height: 1em;
      text-align: center;
      letter-spacing: 1px;
      .text:last-child {
        margin-top: 10px
      }
    }
    .text-wrap-2 {
      margin: 34px 0 40px 0;
    }
    .video-wrap {
      position: relative;
      box-sizing: border-box;
      width: 740px;
      height: 445px;
      margin: auto;
      padding: 10px;
      border-radius: 10px;
      background: #c0e2fe;
      .icon {
        float: left;
        width: 10px;
        height: 10px;
        margin-right: 6px;
        border-radius: 100px;
        background: #2ba2ff;
      }
      .video {
        user-select: none;
        width: 720px;
        height: 405px;
        margin-top: 10px;
      }
    }
  }
  /* 聚合工具能力 */
  .ability-wrap {
    position: relative;
    height: 740px;
    background: url('~@/assets/image/product/pol/ability-bg.png') center no-repeat;
    .title {
      padding: 50px 0 30px;
      color: #272727;
      text-align: center;
      .spacing {
        letter-spacing: 4px;
      }
    }
    .btn {
      width: 246px;
      height: 46px;
      line-height: 46px;
      margin: auto;
      border-radius: 100px;
      background: #ff9712;
      text-align: center;
      color: #ffffff;
      font-size: 14px;
      cursor: pointer;
    }
    .swiper-wrap {
      position: absolute;
      top: 300px;
      left: 50%;
      right: 0;
      width: 1400px;
      height: 350px;
      margin-left: -700px;
      .tool-content-wrap {
        /* padding: 0 140px; */
        display: flex;
        .img-wrap {
          flex: 1;
          text-align: right;
          .tool-img {
            height: 300px;
            margin-right: 50px;
          }
        }
        .tool-cont-box {
          flex: 1;
          display: flex;
          align-items: center;
          margin-left: 50px;
          text-align: left;
          .cont-title {
            font-size: 30px;
            font-weight: bolder;
            letter-spacing: 4px;
            color: $theme-color;
            padding-bottom: 50px;
          }
          .contCont {
            padding-bottom: 20px;
            font-size: 14px;
            color: #272727;
          }
          .contTitle {
            font-size: 16px;
            color: #272727;
            font-weight: bolder;
            padding-bottom: 12px;
          }
          .tool-content-special {
            .cont-title {
              padding-bottom: 22px;
            }
            .contTitle {
              padding-bottom: 8px;
            }
            .contCont {
              padding-bottom: 14px;
            }
            .last-contCont {
              padding-bottom: 0;
            }
          }
        }
      }
      .tool-swiper-pagination {
        top: -90px;
        padding-left: 0;
        height: 40px;
        width: 100%;
        display: flex;
        line-height: 40px;
        border-bottom: 2px solid #ccc;

      }
      .tool-swiper-pagination /deep/ .swiper-pagination-bullet-active{
        font-weight: bolder;
        color: #272727 !important;
        background: url('~@/assets/image/icon1.png') no-repeat bottom center !important;
        margin-bottom: -2px;
      }
      .tool-swiper-pagination /deep/ .swiper-pagination-bullet {
        background: none;
      }
    }
  }
  /* 合作案例 */
  .case-wrap {
    height: 740px;
    background: url('~@/assets/image/product/pol/case-bg.png') center no-repeat;
    .title {
      padding: 40px 0 20px 0;
      text-align: center;
      .spacing {
        letter-spacing: 2px;
      }
    }
    .explain {
      margin-bottom: 30px;
      font-size: 20px;
      line-height: 1em;
      color: #d8e9fd;
      text-align: center;
      .spacing {
        letter-spacing: 10px;
      }
    }
    .btn {
      box-sizing: border-box;
      width: 246px;
      height: 46px;
      line-height: 44px;
      margin: 0 auto 30px;
      border: 2px solid #ffffff;
      border-radius: 100px;
      background: #57b1ff;
      text-align: center;
      color: #ffffff;
      font-size: 14px;
      cursor: pointer;
    }
    .block-container {
      box-sizing: border-box;
      width: 1200px;
      margin: auto;
      padding: 0 20px;
      .case-item {
        display: inline-block;
        box-sizing: border-box;
        width: 360px;
        height: 470px;
        margin-right: 40px;
        padding: 270px 30px 0 30px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        background: url('~@/assets/image/product/pol/case2.png') center no-repeat;
        &:first-child {
          background: url('~@/assets/image/product/pol/case1.png') center no-repeat;
        }
        &:last-child {
          margin-right: 0;
          background: url('~@/assets/image/product/pol/case3.png') center no-repeat;
        }
        .label {
          font-size: 22px;
          line-height: 1em;
          color: #2f9cf1;
          font-weight: bold;
        }
        .content {
          height: 78px;
          margin: 14px 0 0 0;
          font-size: 14px;
          line-height: 20px;
          color: #474747;
        }
        .rate-wrap {
          display: flex;
          .rate-item {
            flex: 1;
            .rate-title {
              font-size: 14px;
              line-height: 1em;
              color: #474747;
            }
            .rate {
              margin-top: 4px;
              font-size: 32px;
              font-weight: bold;
              color: #2f9cf1;
            }
          }
        }
      }
    }
  }
</style>
