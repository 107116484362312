<template>
  <div class="menu-wrapper">
    <ul class="menu-list" v-if="routeName !== 'download'">
      <!---->
      <!-- 产品服务 -->
      <el-dropdown placement="bottom">
        <li
          class="menu-item"
          :class="{'active' : activeindex == 1}"
          @click="swatchMenu(1)">
          产品服务
        </li>
        <!-- 下拉框内容 -->
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item
            :class="routeData.name === 'product' && routeData.hash === '' ? 'active-item' : ''"
            @click.native="dropdownSwitchMenu('product')">
            系统开源</el-dropdown-item> -->
          <el-dropdown-item
            :class="routeData.name === 'product' && routeData.hash === '2' ? 'active-item' : ''"
            @click.native="dropdownSwitchMenu('product', 2)">
            广告变现</el-dropdown-item>
          <el-dropdown-item
            :class="routeData.name === 'product' && routeData.hash === '3' ? 'active-item' : ''"
            @click.native="dropdownSwitchMenu('product', 3)">
            聚合管理</el-dropdown-item>
          <el-dropdown-item
            :class="routeData.name === 'product' && routeData.hash === '4' ? 'active-item' : ''"
            @click.native="dropdownSwitchMenu('product', 4)">
            福利中心</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 解决方案 -->
      <el-dropdown placement="bottom">
        <li
          class="menu-item"
          :class="{'active' : activeindex == 2}"
          @click="swatchMenu(2)">
          解决方案
        </li>
        <!-- 下拉框内容 -->
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            class="solution-wrap"
            :class="routeData.name === 'solution' && routeData.hash === ''? 'active-item' : ''"
            @click.native="dropdownSwitchMenu('solution')">
            高频广告位解决方案</el-dropdown-item>
          <el-dropdown-item
            class="solution-wrap"
            :class="routeData.name === 'solution' && routeData.hash == 2? 'active-item' : ''"
            @click.native="dropdownSwitchMenu('solution', 2)">
            精细化售卖解决方案</el-dropdown-item>
          <el-dropdown-item
            class="solution-wrap"
            :class="routeData.name === 'solution' && routeData.hash == 3? 'active-item' : ''"
            @click.native="dropdownSwitchMenu('solution', 3)">
            弱网环境解决方案</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <li class="menu-item" :class="{'active' : activeindex == 3}" @click="swatchMenu(3)">客户案例</li>
      <li class="menu-item" :class="{'active' : activeindex == 4}" @click="swatchMenu(4)">资讯中心</li>
      <li class="menu-item" :class="{'active' : activeindex == 5}" @click="swatchMenu(5)">关于我们</li>
      <!--<li class="menu-item" :class="{'active' : activeindex == 6}" @click="swatchMenu(6)">下载SDK</li>-->
    </ul>
    <ul class="menu-list" v-if="routeName == 'download'">
      <li class="menu-item" :class="{'active' : activeindex == 6}" @click="swatchMenu(6)">下载SDK</li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'nav-menu',
    props:{
      activeindex: { type: Number },
      //
    },
    data() {
      return {
        routeName: this.$route.name
      }
    },
    computed: {
      routeData() {
        let routeHash = '';
        if (this.$route.hash !== '') routeHash = this.$route.hash.split('=')[1];
        return { name: this.$route.name, hash: routeHash };
      },
    },
    methods: {
      // menu切换
      swatchMenu(index) {
        switch (index) {
          case 1: this.$router.push({ name: 'product', hash: '#page=2' }); break;
          case 2: this.$router.push({ name: 'solution' }); break;
          case 3: this.$router.push({ name: 'client-case' }); break;
          case 4: this.$router.push({ name: 'consult' }); break;
          case 5: this.$router.push({ name: 'about' }); break;
          case 6: this.$router.push({ name: 'download' }); break;
          default: break;
        }
      },
      // 点击下拉框内二级页面
      dropdownSwitchMenu(path, flag = '') {
        this.$router.push({ name: path, hash: flag ? '#page=' + flag : '' });
      },
    },
  }
</script>

<style lang="scss">
  .menu-wrapper {
    flex: 1;
    padding-left: 20px;
    box-sizing: border-box;
    .menu-list {
      width: 100%;
      height: 100%;
      .el-dropdown {
        float: left;
      }
      .menu-item {
        position: relative;
        float: left;
        width: 120px;
        height: 80px;
        margin-right: 10px;
        cursor: pointer;
        font-size: 14px;
        text-align: center;
        color: #454746;
        transition: all 250ms ease-in-out;
        &:hover {
          color: $theme-color;
        }
      }
      .menu-item.active {
        color: $theme-color;
        font-weight: bold;
        background: url('~@/assets/image/nav-active.png') center center no-repeat;
      }
    }
  }
  // 下拉框
  .el-dropdown-menu {
    min-width: 120px;
    margin: -16px 0 0 -10px !important;
    border-radius: 2px !important;
    text-align: center;
    .popper__arrow {
      display: none !important;
    }
    .el-dropdown-menu__item {
      background: transparent !important;
      &:hover {
        color: $theme-color !important;
      }
    }
    .active-item {
      color: $theme-color !important;
    }
  }
  .el-popper[x-placement^=bottom] .popper__arrow, .el-popper[x-placement^=bottom] .popper__arrow::after {
    border-bottom-color: rgba(255, 255, 255, 0.95) !important;
  }
</style>
