<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">2022，APP广告变现生机在哪里，轻变聚合为开发者蓄势“增长”新动能</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2022年1月21日</span>
      <span class="detailTxt">分类：产品功能</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="consult-content">
      <p class="contentTxt">前不久，国内主流广告联盟优量汇、穿山甲，都集中在年底召开了2021开发者大会，大家不约而同的共识是在过去的一年里，国内移动广告流量市场上，发生了不小的变化，今天我们就来总结一下，看看这一年，变在哪里，又有哪些新动能的产生。......
      </p>
    </div>
    <!-------------------------------- 01 -------------------------------->
    <div class="title_sub">2021年 移动广告流量市场变化明显</div>
    <div class="title_txt">1. 行业合规与监管越来越严格</div>
    <div class="consult-content">
      <p class="contentTxt">从政策层面上来看，工信部对APP内通过SDK非法获取个人及设备隐私、对开屏广告诱导点击的行为都予以严格限制，知名厂商APP抽查未达标的均被下架处理，几家头部广告平台也被点名批评，在监管日益加强的形式下，SDK和广告安全合规被提到首要位置，行业内进行了比较大的改变，广告SDK对用户信息的获取更为明确，严格按照国家及行业标准要求，开屏广告上线点击热区，响应对开屏广告的相关要求。</p>
    </div>
    <div class="title_txt">2. 广告联盟支持开发者多渠道变现，纷纷上线聚合产品，工具价值凸显</div>
    <div class="consult-content">
      <p class="contentTxt">过去的2021年，国内广告联盟在流量购买方式上都有不小的变化，以优量汇、穿山甲为代表的第三方广告联盟平台开始支持媒体开发者接入除自己以外的广告平台变现，纷纷推出了自己的聚合工具产品，支持开发者多渠道变现，并提供相关收益优化服务。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">年初，Facebook Audience Network平台宣布不在支持瀑布流的流量采购方式，转而全面支持In-App Header Bidding的竞价方式，而Google支持In-App Header Bidding则更早，这意味着海外广告平台的流量采购方式，已经基本实现全面公开竞价。国内主流广告联盟闻风而动的行为，背后传达出的信号，不难看出，未来的流量采购方式的风向在哪里。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">与海外开发者普遍使用聚合中介（Mediation）服务比起来，国内并没有完全的Open Bidding，第三方工具的使用没有海外那么普及，但这并不表示国内开发者不需要做收益优化，而恰恰相反的是国内开发者“苦收益优化工具久已” ，Bidding作为媒体最佳的收益优化解决方式，不管是开发者自建还是使用第三方工具，都是必备的、应对市场变化的重要能力。</p>
    </div>
    <div class="title_txt">3. Bidding技术的应用，使得全渠道混合竞价得以实现</div>
    <div class="consult-content">
      <p class="contentTxt">2021年，国内主流广告联盟在价格策略上都有显著的变化，从支持底价，到目标价，到多阶价，再到实时竞价（Bidding），让媒体、开发者看到了提升收益的可能性。Bidding实时竞价虽然没有完全开放，但Bidding能力与串并行能力的结合，让Bidding 与瀑布流的自由混排得以实现，多渠道变现的媒体、开发者在渠道层面实现了竞价。根据不同平台的价格来采取混合竞价的方法，最大限度地去匹配到最优价格的广告，极大地提升了媒体流量的价值。</p>
    </div>
    <!-- 图片 -->
    <div class="img-warp">
      <img src="@/assets/image/consult/info14/consult_info1.png" alt="infoImg" class="infoImg">
      <p class="imgIntroduce">（图片来源：腾讯优量汇 2021 开发者大会. 2021.12.29）</p>
    </div>
    <!-------------------------------- 02 -------------------------------->
    <div class="title_sub">收益优化的不可或缺性是什么？</div>
    <div class="title_txt">1. 以Bidding为核心的收益优化能力，决定着APP流量变现的未来</div>
    <div class="consult-content">
      <p class="contentTxt">综上，2021年，我们可以看到，移动广告在流量采购方式上的变化，超过了以往任何一年，原来传统的依靠人工进行广告变现的方式，已经不能适应现在的流量变现要求，根据当前广告联盟提供的流量采购方式，要求开发者必须具备系统优化能力，才能达到收益最大化的目标，因此，以Bidding为核心的收益优化能力被摆在了一个重要的地位。</p>
    </div>
    <!-- 图片 -->
    <div class="img-warp">
      <img src="@/assets/image/consult/info14/consult_info2.png" alt="infoImg" class="infoImg">
    </div>
    <div class="title_txt">2. 优化工具的使用，为开发者带来的是直接收益的提升</div>
    <div class="consult-content">
      <p class="contentTxt">对APP开发者来说，构建自有收益优化系统，存在专业、技术门槛的限制，研发成本和收益之间的ROI问题，使用第三方工具又有安全性的顾虑，而更大的疑问是国内是否真的有必要使用收益提升工具，2021年以前可能还不是很肯定，但2021年以后，答案是肯定的，经过一年的产品和市场演进，我们可以看到，没有优化能力的开发者和有优化能力的开发者相比，收益上的差异是巨大的，尤其是开屏点击热区上线以后，开屏收益大幅降低，具备优化能力的开发者，在解决收益下降问题时，会有更多替代性解决方案，降低的收益在最大范围内得以挽回，而没有优化能力的开发者，在开屏上的收益直接<span class="txtBolder">下降5倍</span>。</p>
    </div>
    <!-- 图片 -->
    <div class="img-warp">
      <img src="@/assets/image/consult/info14/consult_info3.png" alt="infoImg" class="infoImg">
      <p class="imgIntroduce">（数据来源：根据轻变聚合平台数据统计，2021.1月-12月）</p>
    </div>
    <!-------------------------------- 03 -------------------------------->
    <div class="title_sub">优化工具的长期价值在于帮助媒体、开发者构建强有力的卖方能力</div>
    <div class="consult-content">
      <p class="contentTxt">我们可以看到，当Bidding实时竞价成为主流，流量采购越来越依靠程序化的今天，造成收益差异的原因已经不再仅仅是商务谈判和人员经验就能决定的，开发者是否具备强大的系统优化能力，成为主要影响因素。媒体的卖方能力完备的情况下做精细化运营，做优化，是获得收益增长的最有效途径。优化工具的使用，在这一点上，可以帮助开发者很好的弥补这一能力的不足， 但很多开发者总是会质疑第三方工具，担心第三方的安全性，涉及到很多方面，来自SDK的、来自系统能力的、来自数据的等等，这里还是要再次强调，第三方与媒体、开发者的关系，作为工具身份出现的第三方，是为开发者提供技术、运营服务的角色，对开发者而言，<span class="txtBolder">并不是竞争关系，而是利益共同体</span>。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt"><span class="txtBolder">第三方与媒体、开发者的关系是“共生”关系，第三方平台的角色是在买方和媒体之间做连接、做优化，帮助媒体、开发者获得更大的收益，工具的价值本质是提升能力，而不仅仅是提供服务，这是轻变聚合做媒体赋能的长期机会，赋能媒体、开发者构建更完善更高级的卖方能力。</span></p>
    </div>
    <!-------------------------------- 04 -------------------------------->
    <div class="title_sub">轻变聚合致力于提供媒体更好的收益提升工具</div>
    <div class="title_txt">（一）满足变现运营核心需要</div>
    <div class="consult-content">
      <p class="contentTxt">轻变聚合作为媒体变现收益提升工具，相信，在未来，工具对开发者的价值会越来越大。轻变聚合以精细化运营为出发点，以提升收益为落脚点，提供媒体、开发者高效的广告聚合、灵活的售卖策略、多维度的数据支持、智能的系统管理四大核心运营需要。</p>
    </div>
    <div class="title_node">高效广告聚合</div>
    <div class="consult-content">
      <p class="contentTxt"><span class="txt_icon dot_icon"></span>轻变聚合聚合了多家优质广告联盟，免去媒体自己选择渠道的苦恼，和评估成本，帮助开发者在变现业务上拓展更多成熟变现渠道；</p>
    </div>
    <div class="title_node">灵活售卖策略</div>
    <div class="consult-content">
      <p class="contentTxt"><span class="txt_icon dot_icon"></span>通过Bidding实时竞价，优质广告充分竞争，让每次请求获得最优收益，配合自主的流量分配策略，灵活售卖，助力媒体取得最佳收益；</p>
    </div>
    <div class="title_node">多维数据支持</div>
    <div class="consult-content">
      <p class="contentTxt"><span class="txt_icon dot_icon"></span>关键数据展示：提供广告收益、DAU、eCPM、LTV、留存等关键数据展示；</p>
      <p class="contentTxt"><span class="txt_icon dot_icon"></span>实时数据呈现：流量实时监控，异常数据报警，变现情况一手掌握；</p>
    </div>
    <div class="title_node">智能系统管理</div>
    <div class="consult-content">
      <p class="contentTxt"><span class="txt_icon dot_icon"></span>媒体变现业务实现线上程序化管理，操作简单方便，降低人力成本，提升运营效率；</p>
    </div>
    <div class="title_txt">（二）智能提升变现优化能力</div>
    <div class="consult-content">
      <p class="contentTxt">轻变聚合在产品功能上，支持Bidding实时竞价模式及多个竞价渠道的混合竞价，媒体可按照期望的方式进行灵活自主排序分配，让不同广告平台间的优质优价广告充分竞争，从而找到收益最高的一个，实现最大化收益。同时，提供业务诊断、流量分组、A/B测试等聚合管理功能，帮助开发者高效探索最优配置，并通过数据报表能力，助力多维评估变现效果，让决策更科学高效。</p>
    </div>
    <div class="title_node">1.实时竞价（Bidding）</div>
    <div class="consult-content">
      <p class="contentTxt">支持多家广告联盟参与竞价，针对每一次曝光机会公平竞价，价高者胜出，可有效提升填充，并解放运营人力。</p>
    </div>
    <div class="title_node">2.变现诊断</div>
    <div class="consult-content">
      <p class="contentTxt">通过对广告请求、返回、曝光各个阶段的数据进行分析，给出可能的错误原因，例如，广告请求超时、网络错误、容器不可见等问题，帮助媒体归因诊断并做出正确调整。</p>
    </div>
    <div class="title_node">3.流量分组</div>
    <div class="consult-content">
      <p class="contentTxt">支持按照一定规则，包括设备信息、地域、网络、渠道等，开发者可自定义分组规则，实现流量差异化策略，并结合A/B测试，对比不同分组策略效果，进行科学决策。</p>
    </div>
    <div class="title_node">4.A/B测试</div>
    <div class="consult-content">
      <p class="contentTxt">开发者可以按照一定规则设置不同流量分配策略，建立A、B方案，并对不同实验组分配流量比例，对比不同策略下变现数据，找到最佳策略。</p>
    </div>
    <div class="title_node">5.数据报表</div>
    <div class="consult-content">
      <p class="contentTxt">可视化看板，关键数据图表化展示，数据变化一目了然，支持开发者实时查看，提供按时间、OS、广告位、APP、渠道等多种维度查询、上传及导出等功能。同时，提供渠道API报表，免去繁琐的人工下载、上传报表，配置报表API自动获取数据。</p>
    </div>
    <div class="title_txt">（三）自查与合规并举的多重安全保障</div>
    <div class="consult-content">
      <p class="contentTxt">纵观移动互联网广告这些年的发展，我们可以看到，移动广告网络SDK，一直都有一定的行业标准，不管是在技术层面，还是在数据收集与应用方面，不同于其他类型的SDK，移动广告网络SDK属于合规性安全性都较高的SDK，这是由移动广告网络业务性质决定的，第三方的广告平台与APP开发者是利益共同体，“一荣俱荣，一损俱损”，为APP开发者提供安全稳定的SDK是双方获得收益的基本保障，也是合作的前提，在这一点上，轻变聚合 SDK严格按照《个人信息保护法》《数据安全法》《网络安全法》《互联网广告管理办法（公开征求意见稿）》等法律法规要求和移动广告行业标准执行，对可能出现的安全漏洞，采取定期自查和更新。代码安全性方面，轻变聚合 SDK代码逻辑清晰可见，开发者可验证，透明度高，未来还会在代码开源方面做更多的努力。</p>
    </div>
    <!-------------------------------- 结语 -------------------------------->
    <div class="title_sub">结语 轻变聚合与媒体、开发者，从“共生”到“共振”</div>
    <div class="consult-content">
      <p class="contentTxt">媒体、开发者的核心需要是什么？是“增长”！如何做到增长，尤其是在流量红利早已见顶，市场进入存量化的新常态下，留给开发者可操作的空间十分有限，优化工具的出现，为媒体、开发者在新常态下取得“增长”增添了动力。轻变聚合与媒体因相同的利益关系而“共生”，因相同的目标而发展，在这个“共生”发展过程中，轻变聚合将与媒体、开发者同频“共振”。</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo14'
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
</style>