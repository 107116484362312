import { getMessage } from "@/api/api";
export default {
  //判断对象是否为空
  isEmptyObject: function (obj) {
    for (var key in obj) {
      return false; //返回false，不为空对象
    }
    return true; //返回true，为空对象
  },
  async sendMessage(_this,phone,name) {
    if(_this.disabled) {
      return;
    }
    let phone_reg = /^13[0-9]{1}[0-9]{8}$|15[0-9]{1}[0-9]{8}$|18[0-9]{1}[0-9]{8}|17[0-9]{1}[0-9]{8}$|14[0-9]{1}[0-9]{8}/;
    if (!phone_reg.test(phone)) {
      _this.$message({
        message: '请输入正确格式的手机号码',
        type: 'warning'
      });
      return false;
    }
    let params = {
      phonenumber: phone,
    };
    const res = await getMessage(params);
    if(res.code == 200) {
      this.down(_this,phone,name);
    }else {
      let message= res.msg;
      _this.$message({
        message: `${message}`,
        type: 'error'
      });
    }
  },
  down: function(_this,phone,name) {
    _this.disabled = true;
    let down = window.setInterval(() => {
      if (_this.timeDown == 0) {
        window.clearInterval(down);
        if(phone !== "" && name !== "") {
          _this.disabled = false;
        } else {
          _this.disabled = true;
        }
        _this.buttonMsg = '发送验证码';
        _this.timeDown = 60;
      }else {
        _this.timeDown -= 1;
        _this.buttonMsg = `(${_this.timeDown})`;
      }
    },1000);
  },
};
