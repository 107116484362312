<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">深挖App流量变现价值，“LTV”这个你不得不了解的关键因素</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2021年8月17日</span>
      <span class="detailTxt">分类：产品功能</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="module1">
      <!-- 副标题 -->
      <div class="title_sub">什么是LTV</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">LTV(lifetime value)，即用户生命周期价值，LTV代表了用户完整生命周期内给企业创造的价值总额。LTV越高，App流量变现的价值越高，常用于衡量App广告变现收益能力。所以，LTV值是流量变现过程中的一个重要指标。</p>
      </div>
    </div>
    <!-------------------------------- LTV的作用 -------------------------------->
    <div class="module2">
      <!-- 副标题 -->
      <div class="title_sub">LTV的作用</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">从当前市场状况看，LTV这项指标更多的用于游戏行业，主要是衡量获客（买量）和变现（卖量）之间的关系。而对于绝大多数开发者而言，了解自身App的 LTV值也同样对流量变现起到重要作用。它可以更好的评估App用户价值，计算回报周期、验证盈利模式，从而在App用户获取和商业化变现上采取更多更优策略，获得更高收益。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt"><span class="txtBolder">这里面有两方面的应用：</span></p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">一是在买量方面，计算各个渠道导入用户的LTV、CPX（CPM、CPC、CPD、CPA等）、ROI数据，从而筛选优质渠道优化投放，不断提升边际效益数据。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">二是在卖量方面，计算各个变现渠道和各种变现策略下获得的收益，看不同渠道和策略下LTV值的表现，从而为流量变现策略提供优化基础。这里我们主要从卖量，也就是变现这个方面来讲。</p>
      </div>
    </div>
    <!-------------------------------- LTV如何计算 -------------------------------->
    <div class="module3">
      <!-- 副标题 -->
      <div class="title_sub">LTV如何计算</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">在讲LTV计算前，我们需要了解另一个指标——ARPU值。ARPU(Average Revenue Per User)，即平均每用户收入。它的计算公式比较好理解，就是ARPU 值=总收入 / 活跃用户数，那么LTV实际上是以用户生命周期为单位的ARPU值。</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">LTV的计算公式简单来说就是——新增用户在留存期内的总收益/新增用户数，而LTV是一个带时间概念的值，所以，在计算的时候一般都有指定的天数或周期，它的计算公式就是：</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt txtAlignCenter"><span class="txtBolder">LTVn = 当日新增用户往后n天的总收益 / 当日新增用户数</span></p>
      </div>
    </div>
    <!-------------------------------- LTV的影响因素 -------------------------------->
    <div class="module4">
      <!-- 副标题 -->
      <div class="title_sub">LTV的影响因素</div>
      <!-- 节点1 -->
      <div class="node-warp1">
        <!-- 节点标题 -->
        <div class="title_node">（1）广告渗透率</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">广告渗透率即广告展示的用户数占活跃用户总数的比。渗透率越高，说明广告覆盖到的用户量越广，广告曝光量越高，越有助于App提升收益，这点对于IAA类型的开发者变现来说更为重要。</p>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">（2）人均广告展示频次</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">人均广告展示频次即平均每个用户看到广告的次数，即广告展示次数/活跃用户数。人均广告展示频次对LTV的影响作用，不是简单的线性增多或减少，不是人均展示次数越多，LTV就会越高，这其中存在一个临界值。例如，某用户广告展示为3次转化为1次，广告展示6次转化2次，广告展示10次转化3次，广告展示20次转化3次，人均广告展示频次虽然不是线性增长关系，但对LTV却有重要影响。</p>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">（3）eCPM</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">eCPM在之前的eCPM影响因素一文中已经说明，eCPM（effective cost per mille）指的是每一千次展示可以获得的广告收入，是衡量广告变现效果的重要指标。eCPM越高，表明变现收益越高，LTV就越高。</p>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">（4）用户留存率</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">LTV与留存率有着密切关系，这点从公式上就可以看出，用户生命周期即用户留存在App的时间。用户留存时间越长，留存用户数越多，越能创造更多的广告收益，留存率越高，LTV也会越高。</p>
        </div>
      </div>
    </div>
    <!-------------------------------- 如何提升LTV -------------------------------->
    <div class="module5">
      <!-- 副标题 -->
      <div class="title_sub">如何提升LTV</div>
      <!-- 节点1 -->
      <div class="node-warp1">
        <!-- 节点标题 -->
        <div class="title_node">1. 提升广告渗透率</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">开发者可以对活跃用户进行频率更高、次数更多的广告展示策略，从而提升广告的渗透率，但需要控制的是广告与用户的对标性以及后续广告行为的转化，否则会造成频次过高，反而降低eCPM价格，造成收入减少。</p>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">2. 提升eCPM</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">eCPM越高，LTV就越高，开发者需要针对eCPM去做相应优化，从而提升LTV，具体方法请参考前文《影响eCPM的因素》。</p>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">3. 保证稳定的新增用户</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">用户留存一般随时间推移减少，如果没有新增用户的不断进入，LTV值的逐步递减是必然，所以保证稳定的新增用户是保证LTV稳定的一项重要工作，目前多数开发者都会通过投放买量来维持新增用户的稳定。</p>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">4. 提升用户留存率</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">LTV最直接的影响因素是留存率，留存用户越多、留存时间越长、留存用户活跃度越高，创造的收益越多。<span class="txtBolder">影响留存率的因素，总结下来，主要有买入的用户质量和APP本身的质量两个方面。</span></p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">买入的用户质量高低，直接影响用户留存。</span>如果用户质量明显较低，留存基本无法保证，LTV也就无法保证，这里主要要进行买量渠道的控制，高质量的买量渠道才能带来高质量的用户，留存才能高。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">另一个重要的方面是App本身的质量。</span>App用户体验越高，用户留存越高，LTV才会高。如果用户体验不好，新增用户也是很难留下来。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">LTV这一指标与ARPDAU以及留存率关系密切，在App变现过程中作为重要的KPI数据，对App产品的长期成功非常重要。开发者通过主动追踪LTV，可以帮助我们更加正确的选择货币化技术决定。</p>
        </div>
      </div>
    </div>
    <!-------------------------------- 轻变聚合 LTV报表功能 -------------------------------->
    <div class="module6">
      <!-- 副标题 -->
      <div class="title_sub">轻变聚合 LTV报表功能</div>
      <div class="title_sub">深挖App流量变现价值，助力广告收益提升</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">轻变聚合针对这一需求，推出LTV报表功能，将变现收入与新增用户结合，直接为开发者提供LTV数据报告，方便开发者了解LTV情况，及时做出优化策略。</p>
      </div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info9/consult_info1.png" alt="infoImg" class="infoImg">
        <img src="../../../assets/image/consult/info9/consult_info2.png" alt="infoImg" class="infoImg">
      </div>
      <!-- 文字标题 -->
      <div class="title_txt">轻变聚合LTV报表，具备多种运营分析功能：</div>
      <!-- 节点1 -->
      <div class="node-warp1">
        <!-- 节点标题 -->
        <div class="title_node">（一）查看某日LTV</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">根据轻变聚合平台内获取到的新增用户数据，与媒体变现收入直接计算LTV值，省去开发者多种数据追踪监测及制表的麻烦。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt"><span class="txtBolder">LTV(n)= 选定日期的新增用户在N天的总收益/选定日期新增用户数量</span></p>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">（二）查看某日留存率</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">根据轻变聚合平台获取到的媒体活跃用户与新增用户情况，统计用户在安装应用N天后的留存情况，即用户安装应用在N天后仍有打开应用的比例。</p>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">（三）预估LTV</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">可根据已知LTV值及留存情况，预估指定天数内的LTV值，支持预估3天、5天、15天、30天的LTV，帮助开发者深度分析产品商业化变现价值。</p>
        </div>
        <!-- 节点标题 -->
        <div class="title_node">（四）区分用户来源</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">可区分不同买量渠道带来的LTV值，帮助开发者判断买量渠道质量，以便及时做出买量策略调整。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo8',
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
</style>
