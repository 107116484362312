<template>
  <div class="footer-btn-bg">
    <p class="title">使用轻变聚合领先的智能变现工具，开启您的增收之旅</p>
    <div class="submit" @click="handleClick">马上合作</div>
  </div>
</template>

<script>
  export default {
    name: 'footer-btn',
    methods: {
      handleClick() {
        window.open(process.env.VUE_APP_HREF_AD_SCOPE + '#/register', "register");
      }
    }
  }
</script>

<style lang="scss" scoped>
  .footer-btn-bg {
    width: 100%;
    height: 290px;
    background: url("../assets/image/index/footerBtnBg.png") center no-repeat;
    background-size: 100% 100%;
    .title {
      color: #fff;
      font-size: 32px;
      font-weight: bolder;
      text-align: center;
      padding-top: 80px;
    }
    .submit {
      width: 400px;
      height: 60px;
      line-height: 60px;
      margin: 55px auto 0;
      text-align: center;
      background: linear-gradient(-10deg, #A634E7, #493DD1);
      box-shadow: 0px 4px 6px 0px rgba(7, 0, 147, 0.1);
      border-radius: 50px;
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      cursor: pointer;
    }
  }
</style>
