<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">轻变聚合加入中国广告协会！</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2023年7月5日</span>
      <span class="detailTxt">分类：新闻动态</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="consult-content">
      <p class="contentTxt">近日，经协会批准，上海倍孜网络技术有限公司正式加入中国广告协会，成为会员单位。上海倍孜将在中广协的组织和引导下，依托自身在行业深耕多年的优势，为中国数字营销领域贡献力量。</p>
    </div>
    <div class="img-warp special-img-wrap">
      <img src="@/assets/image/consult/info27/consult_info1.png" alt="infoImg" class="infoImg special-img">
    </div>
    <div class="consult-content">
      <p class="contentTxt">中国广告协会（中广协）成立于1983年，作为国内规模和影响力最大的广告行业组织，全面覆盖广告活动各类主体，现拥有会员单位三千余家，共同促进行业发展，推动广告行业诚信建设，树立广告业良好的社会形象。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">轻变聚合作为一家专业的移动应用商业化服务商，伴随移动互联网与数字化技术的持续发展，与众多开发者一同追求让移动应用“变”得更好。致力于运用技术、服务等综合实力为开发者、广告主提供安全、高效、透明的一站式商业变现服务，解决移动应用变现技术研发久、成本控制难、增收实现慢的问题，为开发者提供专属的变现解决方案。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">成功加入中国广告协会，是业界对轻变聚合在移动应用商业化领域内已取得成绩得极大认可。未来，我们也期待能与业内前沿精英深入交流学习与共创，释放更多价值与能量，一起为中国广告行业创造更多可能。同时，轻变聚合也将继续秉持对移动应用商业化技术的热情与创新，拥抱变化，不断尝试与革新，为行业发展贡献力量！</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo27'
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
  .special-img-wrap {
    text-align: center;
    .special-img {
      width: 50% !important;
    }
  }
</style>
