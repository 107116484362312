<template>
  <div class="container" :class="routeHash ? 'containerInfo' : ''">
    <!-- 公用头部 -->
    <headers :activeindex="4"></headers>
    <!-- 资讯中心头部 -->
    <div class="consult-warp">
      <div class="banner-wrap" v-if="!routeHash">
        <img src="../../assets/image/consult/consult_bg.png" alt="背景" class="banner">
        <img src="../../assets/image/consult/consult_txt.png" alt="资讯中心" class="banner_txt">
      </div>
      <div class="banner-consult-wrap" v-else>
        <img src="../../assets/image/consult/consult_top_bg.png" alt="背景" class="banner">
        <img src="../../assets/image/consult/consult_top_txt.png" alt="资讯中心" class="banner_txt">
      </div>
    </div>
    <!-- 资讯中心主体部分 -->
    <div class="consult-content-warp" v-if="!routeHash">
      <!-- 资讯中心 -->
      <template v-for="(item, index) in consultList">
        <div
          class="consult-warp"
          v-if="currentPage[0] < index + 1 && index + 1 <= currentPage[1]"
          :key="index">
          <span class="corner_marker" v-if="index + 1 < 10"> 0{{ index + 1 }}</span>
          <span class="corner_marker" v-else>{{ index + 1 }}</span>
          <div class="consult-info-warp">
            <p class="title" :title="item.title" @click="viewDetail(index + 1)">{{ item.title }}</p>
            <p class="contentTxt" :title="item.content">{{ item.content }}</p>
            <span class="viewDetail" @click="viewDetail(index + 1)">查看详情  > </span>
          </div>
          <div class="image-warp">
            <div class="consult-img-warp">
              <img :src="item.imgUrl" alt="资讯图片" class="consult-img">
            </div>
          </div>
        </div>
      </template>
      <el-pagination
        class="pagination"
        background
        layout="prev, pager, next"
        :current-page.sync="pageData.pageNum"
        :page-size="pageData.pageSize"
        :total="pageData.total">
      </el-pagination>
    </div>
    <!-- 咨询中心详情 -->
    <template v-for="(item, index) in consultInfoList">
      <component
        :key="index"
        v-if="routeHash == index + 1"
        :is="item">
      </component>
    </template>
    <!-- 上一篇 or 下一篇 -->
    <div class="article-select-warp" v-if="routeHash">
      <p class="article-select" v-if="routeHash > 1" @click="viewDetail(routeHash - 1, routeHash)">
        <span class="article_label">上一篇：</span>
        <span class="article_title">{{ consultList[routeHash - 2].title }}</span>
      </p>
      <p class="article-select" v-if="routeHash < consultList.length" @click="viewDetail(routeHash + 1, routeHash)">
        <span class="article_label">下一篇：</span>
        <span class="article_title">{{ consultList[routeHash].title }}</span>
      </p>
    </div>
    <!-- 底部 -- 点击马上合作 -->
    <footer-btn></footer-btn>
    <!-- 底部导航 -->
    <footers></footers>
  </div>
</template>

<script>
  import headers from "@/component/header";
  import footers from "@/component/footer";
  import footerBtn from "@/component/footer-btn";
  import { consultList, consultInfoList } from './data/consult';
  export default {
    name: 'consult',
    components: { headers, footers, footerBtn },
    data() {
      return {
        consultList,
        consultInfoList,
        pageData: {
          total: consultList.length, //数据总条数
          pageNum: 1, //当前第几页
          pageSize: 5  //每页显示多少条数据
        }
      }
    },
    computed: {
      routeHash() {
        if (this.$route.hash === '') return '';
        let routeHash = this.$route.hash.split('=')[1];
        return Number(routeHash);
      },
      // 当前分页区间
      currentPage() {
        let { pageNum, pageSize } = this.pageData;
        return [ pageSize * (pageNum - 1), pageSize * pageNum ];
      }
    },
    methods: {
      viewDetail(id) { // 第二参数 cureentId
        this.$router.push({ name: 'consult', hash: '#page=' + id });
        // 当前分页第一或最后一条数据点击上一条或下一条时，定位到对应分页
        // if (cureentId) {
        //   // 当前分页最后一篇文章 且 点击了下一篇文章
        //   if (cureentId % 5 === 0 && id > cureentId) this.pageData.pageNum ++;
        //   // 当前分页第一篇文章 且 点击了上一篇文章
        //   if (cureentId % 5 === 1 && id < cureentId) this.pageData.pageNum --;
        // }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .containerInfo {
    background: #fff !important;
  }
  .container {
    width: 100%;
    height: auto;
    background: #FAFAFA;
    /* 顶部背景 */
    .consult-warp {
      width: 100%;
      height: auto;
      .banner-wrap {
        height: 420px;
        position: relative;
        overflow: hidden;
        .banner {
          position: absolute;
          left: 50%;
          width: 1920px;
          height: 420px;
          margin-left: -960px;
        }
        .banner_txt{
          position: absolute;
          left: 50%;
          top: 50%;
          width: 310px;
          height: 74px;
          margin-left: -155px;
        }
      }
      .banner-consult-wrap {
        height: 273px;
        position: relative;
        overflow: hidden;
        .banner {
          position: absolute;
          left: 50%;
          width: 1920px;
          height: 273px;
          margin-left: -960px;
        }
        .banner_txt{
          position: absolute;
          left: 50%;
          top: 50%;
          width: 246px;
          height: 58px;
          margin-left: -123px;
        }
      }
    }
    /* content内容部分 */
    .consult-content-warp {
      width: 1240px;
      margin: 66px auto 0;
      .consult-warp {
        position: relative;
        width: 1222px;
        height: 260px;
        margin: 0 auto 48px;
        background: #fff;
        transition: all 300ms ease-in-out;
        &:hover {
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
          .image-warp {
            .consult-img-warp {
              .consult-img {
                width: 486px;
                height: 282px;
              }
            }
          }
        }
        /* 角标 */
        .corner_marker {
          position: absolute;
          top: -10px;
          left: -10px;
          width: 100px;
          height: 44px;
          color: #fff;
          font-size: 28px;
          font-weight: bolder;
          line-height: 44px;
          text-align: center;
          background: url("../../assets/image/consult/marker_bg.png") center no-repeat;
          background-size: 100% 100%;
          border-radius: 20px 0 0 0;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
        }
        /* 左边内容部分 */
        .consult-info-warp {
          width: 772px;
          height: 100%;
          float: left;
          padding: 0 30px;
          position: relative;
          /* 标题 */
          .title {
            padding-top: 75px;
            padding-bottom: 20px;
            font-size: 20px;
            font-weight: bolder;
            color: #464646;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            cursor: pointer;
            &:hover {
              color: #3e27d7;
            }
          }
          /* 内容 */
          .contentTxt {
            font-size: 16px;
            font-weight: 400;
            color: #464646;
            line-height: 27px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .viewDetail {
            position: absolute;
            left: 30px;
            bottom: 27px;
            font-size: 12px;
            color: #A534E7;
            cursor: pointer;
          }
        }
        /* 右侧图片部分 */
        .image-warp {
          position: absolute;
          right: -8px;
          top: -10px;
          width: 450px;
          height: 262px;
          .consult-img-warp {
            width: 450px;
            height: 262px;
            overflow: hidden;
            position: relative;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
            .consult-img {
              position: absolute;
              width: 450px;
              height: 262px;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              transition: all 300ms ease-in-out;
            }
          }
        }
      }
      /* 分页 */
      .pagination {
        margin-bottom: 48px;
        text-align: center;
      }
      .el-pagination /deep/ .el-pager .number:hover {
        color: $theme-color; // 每个分页划入时字体颜色
      }
      .el-pagination.is-background /deep/ .el-pager li:not(.disabled).active {
        background-color: $theme-color; // 分页选中背景颜色
        color: #ffffff;
      }
      .el-pagination.is-background /deep/ .el-pager li:not(.disabled):hover {
        color: $theme-color; // ...省略符号划入颜色
      }
    }
    // 上一篇 or 下一篇
    .article-select-warp {
      width: 1240px;
      margin: 100px auto;
      .article-select {
        font-size: 15px;
        font-weight: 400;
        color: #333;
        line-height: 36px;
        cursor: pointer;
        &:hover {
          .article_title {
            color: #3CB0FD;
          }
        }
        .article_label {
          color: #3CB0FD;
        }
      }
    }
  }
</style>
