<template>
  <div>
    <!-- banner -->
    <div class="banner">
      <!-- <div class="logo"></div> -->
      <div class="title">提供媒体</div>
      <div class="content">高品质广告变现</div>
      <!-- <div class="title">“优加”广告联盟</div> -->
      <!-- <div class="content">提供媒体高质量广告变现</div> -->
    </div>
    <!-- 多种广告形式 -->
    <div class="type-wrap">
      <p class="title colorfff font42 text-shadow fw700">
        <span class="spacing text-shadow fw700">多种广告形</span>式
      </p>
      <div class="block-container">
        <div class="type-left">
          <template v-for="(item, index) in adTypeData">
            <div
              v-if="index < 4"
              :key="item.type"
              class="type-item"
              :class="adType === index + 1 ? 'active' : ''"
              @click="handleAdTypeChange(index + 1)">
              <img class="icon" :src="item.icon">
              <div class="label">{{ item.type }}</div>
              <div class="content">{{ item.content }}</div>
            </div>
          </template>
        </div>
        <div class="phone-wrap">
          <img v-if="adType === 1" src="@/assets/image/product/adv/type-style1.png">
          <img v-if="adType === 2" src="@/assets/image/product/adv/type-style2.png">
          <img v-if="adType === 3" src="@/assets/image/product/adv/type-style3.png">
          <img v-if="adType === 4" src="@/assets/image/product/adv/type-style4.png">
          <img v-if="adType === 5" src="@/assets/image/product/adv/type-style5.png">
          <img v-if="adType === 6" src="@/assets/image/product/adv/type-style6.png">
          <img v-if="adType === 7" src="@/assets/image/product/adv/type-style7.png">
        </div>
        <div class="type-right">
          <template v-for="(item, index) in adTypeData">
            <div
              v-if="index > 3"
              :key="item.type"
              class="type-item"
              :class="adType === index + 1 ? 'active' : ''"
              @click="handleAdTypeChange(index + 1)">
              <img class="icon" :src="item.icon">
              <div class="label">{{ item.type }}</div>
              <div class="content">{{ item.content }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 快捷接入 -->
    <div class="join-wrap">
      <p class="title font42 text-shadow fw700">
        <span class="spacing text-shadow fw700">快捷接</span>入
      </p>
      <div class="block-container">
        <div class="join-item">
          <div class="label">成熟的SDK</div>
          <img class="icon" src="@/assets/image/icon2.png">
          <div class="content">倍孜提供成熟的轻量化SDK，接入简单快捷</div>
        </div>
        <div class="join-item">
          <div class="label">详尽的文档</div>
          <img class="icon" src="@/assets/image/icon2.png">
          <div class="content">对接文档详尽专业，附带技术对接最佳实践</div>
        </div>
      </div>
    </div>
    <!-- 合作案例 -->
    <div class="case-wrap">
      <p class="title colorfff font42 text-shadow fw700">
        <span class="spacing text-shadow fw700">合作案</span>例
      </p>
      <div class="explain fw700">
        <span class="spacing fw700">倍孜SDK服务众多媒体，助力媒体实现收益提升及增长目</span>标
      </div>
      <div class="btn" @click="$router.push({ path: '/home/client-case' })">了 解 详 情</div>
      <div class="block-container">
        <div class="case-item" v-for="item in caseData" :key="item.label">
          <div class="label">{{ item.label }}</div>
          <div class="content">{{ item.content }}</div>
          <div class="rate-wrap">
            <div class="rate-item" v-for="rateItem in item.rateData" :key="rateItem.title">
              <div class="rate-title">{{ rateItem.title }}</div>
              <div class="rate">{{ rateItem.rate }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'product-adv',
    data() {
      return {
        adType: 1,
        adTypeData: [
          {
            icon: require('@/assets/image/product/adv/type1.png'),
            type: '开屏广告',
            content: '应用启动触发，曝光黄金位置',
          },
          {
            icon: require('@/assets/image/product/adv/type2.png'),
            type: '插屏广告',
            content: '弹出窗口展示广告，适应各类场景',
          },
          {
            icon: require('@/assets/image/product/adv/type3.png'),
            type: 'Banner广告',
            content: '可以持续展示的广告，支持轮播',
          },
          {
            icon: require('@/assets/image/product/adv/type4.png'),
            type: '原生广告',
            content: '原生植入，与应用风格统一',
          },
          {
            icon: require('@/assets/image/product/adv/type5.png'),
            type: '全屏视频广告',
            content: '视频全屏展示，获得更好收益',
          },
          {
            icon: require('@/assets/image/product/adv/type6.png'),
            type: '激励视频广告',
            content: '用户主动观看视频，更好的体验，更高的收益',
          },
          {
            icon: require('@/assets/image/product/adv/type7.png'),
            type: '原生通知广告',
            content: '更美观更灵活，位置可控',
          }
        ],
        caseData: [
          {
            label: '某智能家居类APP',
            content: '倍孜SDK提供品牌广告，广告更优质，单价更高，真正实现了高单价高收益',
            rateData: [
              { title: '平均单价提升', rate: '120%' },
              { title: '收入增长', rate: '180%' }
            ]
          },
          {
            label: '某教育类APP',
            content: 'beizi sdk通过广告素材预审核，屏蔽体验不佳的广告，在提升可用广告比例的同时保护了用户体验',
            rateData: [
              { title: '用户投诉下降', rate: '70%' },
              { title: '收入提升', rate: '25%' }
            ]
          },
          {
            label: '某母婴APP',
            content: '千人千面，不同品牌广告与用户的匹配程度提升，有效提升点击率，拉高收入',
            rateData: [
              { title: '点击率提升', rate: '55%' },
              { title: 'eCPM提升', rate: '100%' }
            ]
          }
        ]
      }
    },
    methods: {
      handleAdTypeChange(adType) { this.adType = adType }
    }
  }
</script>

<style lang="scss" scoped>
  /* banner */
  .banner {
    position: relative;
    height: 500px;
    background: url('~@/assets/image/product/adv/banner.png') center no-repeat;
    .logo, .title, .content {
      position: absolute;
      left: 50%;
      width: 1200px;
      margin-left: -600px;
      line-height: 1em;
      color: #1d1d1d;
      letter-spacing: 6px;
    }
    .logo {
      /* top: 160px; */
      top: 220px;
      width: 182px;
      height: 60px;
      background: url('~@/assets/image/product/adv/logo.png') center no-repeat;;
    }
    .title {
      top: 200px;
      font-size: 58px;
      font-weight: bold;
      color: #1d1d1d;
    }
    .content {
      top: 280px;
      font-size: 58px;
      font-weight: bold;
      color: #1d1d1d;
    }
  }
  /* 多种广告形式 */
  .type-wrap {
    height: 740px;
    background: url('~@/assets/image/product/adv/type-bg.png') center no-repeat;
    .title {
      padding: 60px 0;
      text-align: center;
      .spacing {
        letter-spacing: 4px;
      }
    }
    .block-container {
      display: flex;
      width: 1200px;
      margin: auto;
      .phone-wrap {
        width: 256px;
        height: 536px;
        margin: auto;
        border-radius: 12px;
        background: #ffffff;
      }
      .type-left, .type-right {
        width: 472px;
        margin-top: 20px;
        .type-item {
          height: 100px;
          margin-bottom: 30px;
          color: #ffffff;
          letter-spacing: 2px;
          cursor: pointer;
          &:last-child {
            margin-bottom: 0;
          }
          .icon {
            width: 60px;
            padding-top: 20px;
          }
          .label {
            padding-top: 22px;
            font-size: 22px;
            font-weight: bold;
          }
          .content {
            margin-top: 6px;
            font-size: 14px;
          }
        }
      }
      .type-left .type-item {
        border-radius: 100px 0 0 100px;
        background: linear-gradient(to right, rgba(109, 188, 255, 0.25), rgba(109, 188, 255, 0.25), rgba(109, 188, 255, 0.25), rgba(109, 188, 255, 0.15), rgba(109, 188, 255, 0.1));
        .icon {
          float: left;
          margin: 0 20px 0 30px;
        }
      }
      .type-left .active {
        background: linear-gradient(to right, rgb(109, 188, 255), rgb(109, 188, 255), rgb(109, 188, 255), rgb(109, 188, 255), rgba(109, 188, 255, 0.8), rgba(109, 188, 255, 0.6), rgba(109, 188, 255, 0.4), rgba(109, 188, 255, 0.2));
      }
      .type-right .type-item {
        border-radius: 0 100px 100px 0;
        background: linear-gradient(to left, rgba(109, 188, 255, 0.25), rgba(109, 188, 255, 0.25), rgba(109, 188, 255, 0.25), rgba(109, 188, 255, 0.15), rgba(109, 188, 255, 0.1));
        text-align: right;
        .icon {
          float: right;
          margin: 0 30px 0 20px;
        }
      }
      .type-right .active {
        background: linear-gradient(to left, rgb(109, 188, 255), rgb(109, 188, 255), rgb(109, 188, 255), rgb(109, 188, 255), rgba(109, 188, 255, 0.8), rgba(109, 188, 255, 0.6), rgba(109, 188, 255, 0.4), rgba(109, 188, 255, 0.2));
      }
    }
  }
  /* 快捷接入 */
  .join-wrap {
    height: 700px;
    background: url('~@/assets/image/product/adv/join-bg.png') center no-repeat;
    .title {
      padding: 60px 0;
      color: #272727;
      text-align: center;
      .spacing {
        letter-spacing: 4px;
      }
    }
    .block-container {
      box-sizing: border-box;
      width: 1200px;
      padding: 0 60px;
      margin: auto;
      .join-item {
        display: inline-block;
        width: 500px;
        height: 460px;
        padding: 50px 0 0 50px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        background: url('~@/assets/image/product/adv/join1.png') center no-repeat;
        &:last-child {
          margin-left: 80px;
          background: url('~@/assets/image/product/adv/join2.png') center no-repeat;
        }
        .label {
          color: #2d9eff;
          font-size: 26px;
          line-height: 1em;
          font-weight: bold;
          letter-spacing: 3px;
        }
        .icon {
          margin: 20px 0;
          vertical-align: top;
        }
        .content {
          color: #474747;
          font-size: 14px;
          line-height: 1em;
          letter-spacing: 1px;
        }
      }
    }
  }
  /* 合作案例 */
  .case-wrap {
    height: 740px;
    background: url('~@/assets/image/product/adv/case-bg.png') center no-repeat;
    .title {
      padding: 40px 0 20px 0;
      text-align: center;
      .spacing {
        letter-spacing: 2px;
      }
    }
    .explain {
      margin-bottom: 30px;
      font-size: 20px;
      line-height: 1em;
      color: #d8e9fd;
      text-align: center;
      .spacing {
        letter-spacing: 10px;
      }
    }
    .btn {
      box-sizing: border-box;
      width: 246px;
      height: 46px;
      line-height: 44px;
      margin: 0 auto 30px;
      border: 2px solid #ffffff;
      border-radius: 100px;
      background: #57b1ff;
      text-align: center;
      color: #ffffff;
      font-size: 14px;
      cursor: pointer;
    }
    .block-container {
      box-sizing: border-box;
      width: 1200px;
      margin: auto;
      padding: 0 20px;
      .case-item {
        display: inline-block;
        box-sizing: border-box;
        width: 360px;
        height: 470px;
        margin-right: 40px;
        padding: 270px 30px 0 30px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        background: url('~@/assets/image/product/adv/case2.png') center no-repeat;
        &:first-child {
          background: url('~@/assets/image/product/adv/case1.png') center no-repeat;
        }
        &:last-child {
          margin-right: 0;
          background: url('~@/assets/image/product/adv/case3.png') center no-repeat;
        }
        .label {
          font-size: 22px;
          line-height: 1em;
          color: #2f9cf1;
          font-weight: bold;
        }
        .content {
          height: 78px;
          margin: 14px 0 0 0;
          font-size: 14px;
          line-height: 20px;
          color: #474747;
        }
        .rate-wrap {
          display: flex;
          .rate-item {
            flex: 1;
            .rate-title {
              font-size: 14px;
              line-height: 1em;
              color: #474747;
            }
            .rate {
              margin-top: 4px;
              font-size: 32px;
              font-weight: bold;
              color: #2f9cf1;
            }
          }
        }
      }
    }
  }
</style>
