<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">视频类广告掘金潜力巨大，期待更大价值！</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2022年11月1日</span>
      <span class="detailTxt">分类：行业趋势</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="consult-content">
      <p class="contentTxt">总的说来，视频广告在移动广告形式诞生之初不久就已有，全屏视频、半屏视频，嵌入式视频都有，但当时并没有表现出投放优势，随着移动互联网的发展，视频广告能更丰富的展示广告内容，吸引用户关注，与静态图片相比，转化效果大为提升，逐渐被广告主重视，进而在变现收益上也有大的提升。</p>
      <p class="contentTxt">目前，移动广告平台视频广告，从形式上来讲，有全屏视频、半屏视频、嵌入式视频、原生信息流视频几大类；从场景位置上来讲，分开屏位视频、插屏位视频、激励位视频；从推广目的上来讲，市面上主要流行的收益占比较高的视频类广告是品牌类视频广告和效果类广告两类，效果类视频广告目前以激励视频和信息流视频为主。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt"><span class="txtBolder">品牌视频广告</span>是最早开始投放的视频广告类型，主要是品牌广告在电视和视频网站投放素材的延伸，在移动设备上，同步播放广告活动内容，主要投放开屏位全屏视频，信息流视频等形式。</p>
      <p class="contentTxt">品牌广告主投放移动端视频广告的预算投入随着移动端逐步占据主要媒体终端也在逐步增大，但品牌广告预算，在纯品牌推广上的投入并没有增加，反而呈减少态势，品牌视频广告在整体广告投放形式中的比重非常低，尽管品牌视频广告的单价高，但开发者在品牌类视频广告上的收入非常低，跟其他形式形成鲜明对比。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt"><span class="txtBolder">效果类视频广告</span>是近几年发展较大的广告形式，尤其是激励视频广告发展较快，激励视频单价高，收益好，受到广大开发者欢迎。</p>
      <p class="contentTxt">激励视频，简单来讲就是一种沉浸式的视频广告，发源于游戏应用场景，用户可以通过观看广告来获取道具、物品或免费使用时长等奖励。而另外一类效果类视频广告是信息流视频，信息流视频广告是以APP原生环境为场景，出现在移动应用使用过程中，外观一般为信息流大图形式。</p>
      <p class="contentTxt">效果类视频广告注重转化效果，激励视频和信息流视频以超高转化吸引效果类广告主投放，广告预算逐年走高。</p>
      <p class="contentTxt">激励视频广告价格一路水涨船高，引得众多开发者跃跃欲试，但激励视频并不是所有APP都适合，它需要有适用的场景，主要是要有激励场景，因此当前的激励视频广告主要在选对了广告场景才能发挥最大的流量价值，让广告收益有大幅提升。</p>
      <p class="contentTxt">信息流视频相比激励视频适用场景比激励视频要多，有原生内容的APP都可以实现，但有原生内容的APP并不多，能设置信息流广告的位置也不多，整体广告库存流量想要扩大并不容易。</p>
    </div>
    <div class="module1">
      <div class="title_txt">视频广告潜力巨大，仍是未来重要掘金地</div>
      <div class="consult-content">
        <p class="contentTxt">与之形成鲜明对比的是市场表现，2021年，全网在投移动广告素材投放形式分布来看，视频投放是主要形式，以视频投放占比高达55.16%；以图片投放占39.41%；以图标投放占3.40%；纯文案投放占1.39%。</p>
      </div>
      <div class="img-warp">
        <img src="@/assets/image/consult/info21/consult_info1.png" alt="infoImg" class="infoImg">
        <p class="imgCenterInfo">资料来源：App Growing、易观、智研咨询整理</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">2021年1-12月全网移动广告视频广告形式占比均较2020年同期有所增长，1月、6月、7月移动广告视频广告形式占比较2020年同期增长超30%；分别达到33.16%、33.13%、35.08%。</p>
      </div>
      <div class="img-warp">
        <img src="@/assets/image/consult/info21/consult_info2.png" alt="infoImg" class="infoImg">
        <p class="imgCenterInfo">资料来源：App Growing、易观、智研咨询整理</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">从近两年视频广告的市场表现来看，广告主对视频广告的需求不断扩大，主要原因归结为以下两点：</p>
      </div>
      <div class="node-warp1">
        <!-- 节点标题 -->
        <div class="title_node">1、技术发展，高转化率，视频化大势所趋</div>
        <div class="consult-content">
          <p class="contentTxt">21世纪以来，4G、5G技术飞速发展，视频制作门槛降低，生产效率提升。根据数据显示，自2016年起，国内外移动视频广告展示占比连年增长，支出增长比例近两倍。视频广告势头逐渐增强。</p>
          <p class="contentTxt">视频广告相较于图片广告，在单位时长内的信息传递效率更高，影像与声音的结合更利于吸引用户注意力，使得用户对广告信息接受程度更高。并且就激励视频来讲，根据测试发现，在完成10~30S视频观看后，选择点击广告的用户，对产品的兴趣和转化机率更高，后续付费行为、次留行为甚至LTV都会更好。</p>
          <p class="contentTxt">视频广告的先天优势，让视频广告的发展更为迅速，成为当下流量变现市场不可忽略的存在。</p>
        </div>
      </div>
      <div class="node-warp2">
        <!-- 节点标题 -->
        <div class="title_node">2、兼顾利益，平衡用户体验</div>
        <div class="consult-content">
          <p class="contentTxt">视频广告改变了传统的广告单向对用户展示的模式，将选择权交给了观众，在相同的时间内，视频广告以更多的画面吸引用户注意力，弱化一成不变的图片素材广告带给用户的不适体验，更甚至激励视频还给观看视频的用户一定的奖励，激励用户主动观看广告。</p>
          <p class="contentTxt">视频广告的出现，帮助开发者找到了广告变现与用户体验的平衡。开发者可以获得更高的CPM和产品满意度，广告主也能更多的获得用户转化，如此正循环有助于平台方构建更加稳定和健康的广告生态。</p>
        </div>
      </div>
      <div class="consult-content">
        <p class="contentTxt">综上，当下广告投放市场，虽然视频类广告收益较好，受到开发者和广告主的追捧，但无法否认的是，由于APP广告场景限制，视频广告的流量价值和收益会存在瓶颈，但仍有广阔市场前景，视频广告仍是移动广告变现中最大的收益增长点。</p>
      </div>
    </div>
    <div class="module2">
      <div class="title_txt">精细化运营，让视频广告流量价值最大化</div>
      <div class="consult-content">
        <p class="contentTxt">视频广告自上线以来，借助广告平台过硬的技术能力和前瞻的创作理念，在产品形态、流量使用、场景差异上形成了独特的优势，在投放收益上遥遥领先。从市场反映投放需求上看，视频广告还将有更大的增长空间，但视频广告的库存少和场景限制等因素，是影响视频广告发展的最大阻力，这两大问题可能都不能快速解决，对广大开发者来说，要想吃到视频广告的收益红利也没那么难，它需要更精细化的运营。</p>
      </div>
      <div class="node-warp1">
        <div class="title_node">1、广告匹配合适场景</div>
        <div class="consult-content">
          <p class="contentTxt">轻变聚合经过测试发现，匹配的广告场景，能够更大的激发视频广告的转化，增加流量收益。</p>
          <p class="contentTxt"><span class="txt_icon dot_icon"></span>以品牌广告为例，APP在广告场景展示位置的选择的上，应以大尺寸，尽可能多的展示品牌内容，体现品牌调性，展示时间要放长，时间过短品牌广告内容展示不完整。</p>
          <p class="contentTxt"><span class="txt_icon dot_icon"></span>而效果类广告主要在于吸引用户向既定KPI转化，广告场景要求有完整的转化链路，激励场景下，对广告曝光和推动效果转化都是最佳的广告场景，具备激励或半激励性质的位置都适合作为效果类广告位。</p>
        </div>
      </div>
      <div class="node-warp2">
        <div class="title_node">2、流量分组，广告价值最大化</div>
        <div class="consult-content">
          <p class="contentTxt">目前市面上流行的两类视频广告形式，根据其展示形式及展示目的不同，根据广告品牌、广告活动内容选择合适的人群流量，能够充分发挥流量价值，帮助开发者获取更高收益。</p>
          <p class="contentTxt"><span class="txt_icon dot_icon"></span>在用户选择上，品牌广告应以高购买力人群流量为主，区分流量地域、时段、频次、设备、版本等，筛选高价值用户，提升品牌广告曝光有效性。</p>
          <p class="contentTxt"><span class="txt_icon dot_icon"></span>对效果类视频广告，提升效果类视频的流量价值，主要应从提升广告效果入手，轻变聚合测试表明，筛选与推广内容匹配的用户流量进行广告展示与通投的展示相比，在转化效果上有大幅提升，eCPM单价也有大幅提升，说明区分流量提升收益是有显著效果的。</p>
        </div>
        <div class="consult-content">
          <p class="contentTxt">以上为提升视频广告收益在运营方面的一部分实用方法，在倍孜网络公众号内回复“视频广告”，轻变聚合专业变现顾问，将为您提供更多收益提升技巧！</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo21'
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
  .special-img-wrap {
    text-align: center;
    .special-img {
      width: 50% !important;
    }
    .special4-img {
      width: 25% !important;
    }
  }
</style>
