<template>
  <div class="container">
     <!-- 公用头部 -->
    <headers :activeindex="5"></headers>


    <div class="about-wrap">
      <div class="banner-wrap">
        <img src="../../assets/image/logo3.png" class="banner_logo_img">
        <img src="../../assets/image/about/banner_title.png" class="banner_title_img">
        <div class="banner_info_text">上海倍孜网络技术有限公司是国内领先的一站式移动应用商业化服务供应商，</div>
        <div class="banner_info_text">凭借倍孜SDK工具为移动应用开发者提供便捷、高效、专业的流量变现解决方案；</div>
        <div class="banner_info_text">帮助移动应用开发者构建完善的商业化运营体系，提升变现能力，实现媒体商业价值。</div>
        <div class="banner_info_text">公司核心团队来自行业一流技术公司，由阿里、搜狐、优酷等程序化核心技术人员担任，平均10年以上开发经验。</div>
        <div class="banner_info_text">服务团队人员均拥有多年的广告及企业营销服务经验。</div>
      </div>
      <!-- 发展与荣誉 -->
      <div class="develop-wrap">
        <div class="container">
          <p class="title font32 text-shadow">发展与荣誉</p>
          <div class="line"></div>
          <div class="containerss">
            <div class="lines" :class="{'activeOne': activeIndex == 0,'activeFour': activeIndex == 4}">
              <span class="icon"></span>
              <span class="cdd"></span>
              <span class="icon"></span>
            </div>
            <div class="wrap">
              <div class="common-wrap bg16" @mouseover="toggle(0)" :class="{'active': activeIndex == 0}">
                <p class="title pr20 tar">2016年</p>
                <img src="../../assets/image/about/medal.png" alt="" class="images" v-if="activeIndex != 0">
                <img src="../../assets/image/about/16.png" alt="" class="bgs" v-if="activeIndex == 0">

                <div class="info" v-if="activeIndex == 0">
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">轻变聚合正式成立</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">研发部门在北京成立</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">业务部门在上海成立</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">开始积极投入到MAP的研发中</span>
                  </p>
                </div>
              </div>
              <div class="common-wrap bg17" @mouseover="toggle(1)" :class="{'active': activeIndex == 1}">
                <p class="title pr20 tar">2017年</p>
                <img src="../../assets/image/about/medal.png" alt="" class="images">
                <img src="../../assets/image/about/17.png" alt="" class="bgs" v-if="activeIndex == 1">
                <div class="diamond" v-if="activeIndex == 1"></div>
                <div class="info" v-if="activeIndex == 1">
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">MAP移动广告交易平台上线第一家媒体通过MAP开始变现</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">移动广告投放平台上线第一家广告商开始进行广告投放</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">获得2017TMA年度最佳移动营销服务平台</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">获得2017年度金触点最具创新力商业模式</span>
                  </p>
                </div>
              </div>
              <div class="common-wrap bg18" @mouseover="toggle(2)" :class="{'active': activeIndex == 2}">
                <p class="title pr20 tar">2018年</p>
                <img src="../../assets/image/about/medal.png" alt="" class="images">
                <img src="../../assets/image/about/18.png" alt="" class="bgs" v-if="activeIndex == 2">
                <div class="diamond" v-if="activeIndex == 2"></div>
                <div class="info special" v-if="activeIndex == 2">
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">1000+广告商，500+广告公司使用MAP进行广告营销</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">1000+媒体使用MAP进行变现</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">获得2018年度新锐移动营销公司</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">获得2018年度最具创新企业奖</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">获得2018年度移动营销新锐人物</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">获得2018年度最具价值营销平台</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">获得2018年度Todigital创新奖</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">入选2018年度CMO技术营销云图</span>
                  </p>
                </div>
              </div>
              <div class="common-wrap bg19" @mouseover="toggle(3)" :class="{'active': activeIndex == 3}">
                <p class="title pr20 tar">2019年</p>
                <img src="../../assets/image/about/medal.png" alt="" class="images">
                <img src="../../assets/image/about/19.png" alt="" class="bgs" v-if="activeIndex == 3">
                <div class="diamond" v-if="activeIndex == 3"></div>
                <div class="info" v-if="activeIndex == 3">
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">技术服务能力提升，软件产品正式推向市场“第一家”软件产品客户签约落地</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">获得2019年度最具营销价值用户平台</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">获得2019年度大数据营销案例奖</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">获得2019年度数字营销实效奖</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">获得2019年度大数据实战金案奖</span>
                  </p>
                </div>

              </div>
              <div class="common-wrap bg20" @mouseover="toggle(4)" :class="{'active': activeIndex == 4}">
                <p class="title pr20 tar">2020年</p>
                <img src="../../assets/image/about/medal.png" alt="" class="images">
                <img src="../../assets/image/about/20.png" alt="" class="bgs" v-if="activeIndex == 4">
                <div class="info" v-if="activeIndex == 4">
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">轻变聚合媒体变现诊断平台上线，并正式推向市场</span>
                  </p>
                  <p class="common-info">
                    <span class="left"></span>
                    <span class="right">一经投入市场即获得媒体客户的欢迎，上线第一周订货量突破100+</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  <!-- 底部导航 -->
  <footers></footers>
  </div>
</template>

<script>
import headers from "@/component/header";
import footers from "@/component/footer";
export default {
  name: "about",
  components: {
    headers,
    footers
  },

  data() {
    return {
      activeIndex: 0,    // 当前选中的下标
    };
  },

  methods: {
    // 切换日期
    toggle(index) {
      this.activeIndex = index;
    },

  },

  mounted() {

  }
};
</script>
<style lang="scss" scoped>
/* 文字阴影 */
.text-shadow {
  text-shadow: 1px 5px 10px rgba(7, 0, 147, 0.15);
}
.tar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  background: transparent;
  width: 100%;
  height: 110px;
  line-height: 110px;
}
.container {
  width: 100%;
  height: auto;
  padding: 0;
  .about-wrap {
    width: 100%;
    height: auto;
    .banner-wrap {
      height: 720px;
      background: url('~@/assets/image/about/banner.jpg') center no-repeat;
      background-size: cover;
      .banner_logo_img{
        display: block;
        width: 220px;
        // height: 50px;
        margin: 0 auto;
        padding-top: 120px;
        padding-bottom: 40px;
      }
      .banner_title_img{
        display: block;
        width: 900px;
        margin: 0 auto 40px;
        border-radius: 5px;
      }
      .banner_info_text{
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        padding-bottom: 30px;
      }
    }
    .develop-wrap {
      width: 100%;
      height: 800px;
      background-color: #fff;
      text-align: center;
      background: url('../../assets/image/about/about.png') no-repeat center center;
      background-size: 100% 100%;

      .container {
        width: 1240px;
        height: auto;
        margin: 0 auto;
        padding: 60px 0 110px 0;
        box-sizing: border-box;
        position: relative;
        .containerss {
          position: relative;
          width: 1240px;
          height: auto;
        }
        .lines {
          width: 1040px;
          position: absolute;
          top: 92px;
          left: 100px;
          z-index: 99;
          .icon {
            display: inline-block;
            width: 10px;
            height: 10px;
            vertical-align: middle;
            background: #FFFFFF;
          }
          .cdd {
            display: inline-block;
            background: #FFFFFF;
            width: 1020px;
            height: 2px;
            vertical-align: middle;
          }
        }
        /* 时间轴在2016年 */
        .lines.activeOne {
          width: 920px;
          position: absolute;
          top: 92px;
          left: 220px;
          z-index: 99;
          .cdd {
            display: inline-block;
            background: #FFFFFF;
            width: 900px;
            height: 2px;
            vertical-align: middle;
          }
        }
        /* 时间轴在2020年 */
        .lines.activeFour {
          width: 920px;
          position: absolute;
          top: 92px;
          left: 100px;
          z-index: 99;
          .cdd {
            display: inline-block;
            background: #FFFFFF;
            width: 900px;
            height: 2px;
            vertical-align: middle;
          }
        }
        .title {
          font-weight: 700;
        }
        .wrap {
          margin-top: 30px;
          width: 100%;
          display: flex;
          height: 540px;
          .common-wrap {
            width: 200px;
            height: auto;
            min-height: 540px;
            padding-top: 20px;
            box-sizing: border-box;
            cursor: pointer;
            position: relative;
            .diamond {
              position: absolute;
              top: 95px;
              left: 220px;
              width: 10px;
              height: 10px;
              background-color: #fff;
            }
            .title {
              font-size: 30px;
              font-weight: 700;
              color: #fff;
            }
            .images {
              display: block;
              width: 102px;
              height: 122px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-51px,-61px);
            }
            .bgs {
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
            .info {
              width: 100%;
              height: auto;
              background: transprent;
              text-align: left;
              padding-left: 40px;
              padding-top: 128px;
              padding-bottom: 20px;
              box-sizing: border-box;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 99;
              .common-info {
                height: 25px;
                line-height: 25px;
                margin-bottom: 50px;
                .left {
                  display: inline-block;
                  width: 10px;
                  height: 10px;
                  background-color: #fff;
                  border-radius: 50%;
                  vertical-align: top;
                  margin-right: 10px;
                  margin-top: 6px;
                }
                .right {
                  display: inline-block;
                  width: 90%;
                  vertical-align: top;
                  color: #fff;
                  font-size: 18px;
                  font-weight: 700;
                }
              }
            }
            .info.special  .common-info{
              margin-bottom: 35px;
            }
          }
          .common-wrap.active {
            flex: 1;
            position: relative;
          }
          .bg16 {
            background-color: #493DD1;
          }
          .bg17 {
            background-color: #A634E7;
          }
          .bg18 {
            background-color: #DE189F;
          }
          .bg18.common-wrap.active {
            height: 600px;
          }
          .bg19 {
            background-color: #EF7F1C;
          }
          .bg20 {
            background-color: #3446F1;
          }
        }
      }
    }
  }
}
</style>
