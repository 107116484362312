<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">破解数据运营难题，“多维度数据透视+诊断”工具，轻松提升媒体变现运营能力</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2021年5月14日</span>
      <span class="detailTxt">分类：产品功能</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="module1">
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">数据在APP流量变现过程中所起的作用，相比不言而喻。做变现运营，数据是运营的基础，也是优化提升的前提，但在媒体流量变现业务运营过程中，作为开发者是否常常遇到这些问题：</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">（1）渠道数据隔天反馈，无法及时发现问题做调整</p>
        <p class="contentTxt">（2）用户数据统计口径不一致，各方差异大</p>
        <p class="contentTxt">（3）曝光成功率低，未曝光部分原因不明</p>
        <p class="contentTxt">（4）核减率超过正常范围，收入变化大，账户风险较大</p>
      </div>
      <div class="consult-content">
        <p class="contentTxt">针对当前数据后台不能支撑业务分析和指导决策的问题，轻变聚合全面升级产品，实现“全面实时数据呈现，深度剖析变现诊断”两大功能，多维度数据透视为开发者流量变现数据化运营赋能。</p>
      </div>
    </div>
    <!-------------------------------- 01 仪表盘，多维度实时数据呈现 -------------------------------->
    <div class="module2">
      <!-- 副标题 -->
      <div class="title_sub">01 仪表盘，多维度实时数据呈现</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">
          <span class="txtBolder">轻变聚合仪表盘展示系统整体数据，解决数据报告的次日反馈延迟问题，当天数据做到实时呈现。</span>
          结合流量库存、广告填充、单价、收益等核心变现指标，APP媒体主能够及时发现异常数据，及时进行调整避免损失。
        </p>
      </div>
      <!-- 文字标题 -->
      <div class="title_txt">目前有核心指标、收入数据、实时数据三大部分：</div>
      <!-- 节点标题 -->
      <div class="title_node">Ⅰ 核心数据——关键流量数据统计</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">包含DAU、广告位请求量、返回量、曝光量、点击量、填充率、曝光率、点击率和eCPM等决定变现的核心数据。</p>
        <p class="contentTxt">APP开发者可以直观地查看流量变化情况，并同比昨日数据，给出浮动百分比，变化情况一目了然，方便分析和归因诊断。</p>
      </div>
      <!-- 节点标题 -->
      <div class="title_node">Ⅱ 收入数据——对收入数据做拆解</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">开发者可以看到各采买渠道收入占比，按时间查看收入及趋势、账户下各APP收入占比，帮助开发者筛选优质渠道，优化渠道策略，轻松实现广告变现收益的最大化。</p>
      </div>
      <!-- 节点标题 -->
      <div class="title_node">Ⅲ 实时数据——可视化图表展示核心数据</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">可单独查看某个APP的请求量、返回量、填充率、曝光量、曝光率、点击量、点击率等实时数据。</p>
      </div>
      <!-- 图片 -->
      <div class="img-warp">
        <img src="../../../assets/image/consult/info3/consult_info1.png" alt="infoImg" class="infoImg">
        <img src="../../../assets/image/consult/info3/consult_info2.png" alt="infoImg" class="infoImg">
      </div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">
          解决数据反馈不及时，开发者只能次日回看昨日变现数据报表的问题，
          <span class="txtBolder">在轻变聚合实时数据模块APP开发者能实时观察APP流量及广告填充变化</span>
          ，这一改进将极大提高开发者收益分析和调整决策的效率。
        </p>
        <p class="contentTxt">
          除此之外，
          <span class="txtBolder">在轻变聚合仪表盘还将推出实时数据的流量预警功能</span>
          ，该功能将帮助开发者实时监控流量情况，及时预防和解决突发事件，避免和降低因流量波动造成的收益损失。
        </p>
      </div>
    </div>
    <!-------------------------------- 02数据中心，6大数据表记录变现全流程 -------------------------------->
    <div class="module3">
      <!-- 副标题 -->
      <div class="title_sub">02数据中心，6大数据表记录变现全流程</div>
      <!-- 内容 -->
      <div class="consult-content">
        <p class="contentTxt">
          区别于仪表盘，
          <span class="txtBolder">数据中心提供单维度的详情数据报表，开发者可以查看账户数据、应用数据、广告位数据、买方数据、转发策略数据、用户生命周期价值数据六个报表。</span>
        </p>
      </div>
      <!-- 节点1 -->
      <div class="node-warp1">
        <!-- 节点标题 -->
        <div class="title_node">（1）账户数据报表——以账户维度展示数据报表</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">可按时间段、操作系统、广告位类型（广告形式）查询并展现数据，并可根据自定义指标来下载数据报表。列表以时间为维度，展示请求量、返回量、填充率、曝光量、曝光率、点击量、点击率、收入、eCPM、CPC等数据，开发者可通过以上指标进行变现评估，调整广告策略。</p>
        </div>
        <!-- 图片 -->
        <div class="img-warp">
          <img src="../../../assets/image/consult/info3/consult_info3.png" alt="infoImg" class="infoImg">
        </div>
      </div>
      <!-- 节点2 -->
      <div class="node-warp2">
        <!-- 节点标题 -->
        <div class="title_node">（2）应用数据报表——以应用为维度展示数据报表，展示样式与账户数据报表结构相同。</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">开发者可按照“应用”查看核心数据评估收益，调整广告策略。筛选优质应用类型，同时为产品研发决策提供数据支持。</p>
        </div>
        <!-- 图片 -->
        <div class="img-warp">
          <img src="../../../assets/image/consult/info3/consult_info4.png" alt="infoImg" class="infoImg">
        </div>
      </div>
      <!-- 节点3 -->
      <div class="node-warp3">
        <!-- 节点标题 -->
        <div class="title_node">（3）广告位数据——以广告位为维度展示数据报表，展示样式与账户数据报表结构基本相同。</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">
            支持根据时间段、操作系统、广告位类型、应用、广告位五个维度的筛选条件。
            <span class="txtBolder">数据列表以广告位为主向开发者展示核心数据，帮助开发者分析不同广告位类型收益，制定广告策略。</span>
          </p>
        </div>
        <!-- 图片 -->
        <div class="img-warp">
          <img src="../../../assets/image/consult/info3/consult_info5.png" alt="infoImg" class="infoImg">
        </div>
      </div>
      <!-- 节点4 -->
      <div class="node-warp4">
        <!-- 节点标题 -->
        <div class="title_node">（4）买方数据——以买方渠道为维度展示数据报表，展示样式与账户数据报表结构基本相同。</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">开发者可看到不同买方渠道数据，分析不同买方的收益差异，制定最高收益策略。在轻变聚合买方数据报表中，支持按时间段、操作系统、广告位类型、应用、广告位、渠道应用、渠道广告位七个维度查询数据。</p>
          <p class="contentTxt">数据列表中展示，不同渠道广告位、渠道，平台应用、广告位类型、系统平台对应的数据，媒体可根据数据表现对渠道广告收益进行评估。</p>
        </div>
        <!-- 图片 -->
        <div class="img-warp">
          <img src="../../../assets/image/consult/info3/consult_info6.png" alt="infoImg" class="infoImg">
        </div>
      </div>
      <!-- 节点5 -->
      <div class="node-warp5">
        <!-- 节点标题 -->
        <div class="title_node">（5）转发策略数据——以策略为维度展示数据报表</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">转发策略数据报表，支持根据时间段、应用、广告位、策略类型、策略维度来查看数据，并根据策略名称，策略类型展示数据，媒体可根据具体策略数据对策略效果进行深入分析，判断策略优劣，并以此优化渠道策略。</p>
        </div>
        <!-- 图片 -->
        <div class="img-warp">
          <img src="../../../assets/image/consult/info3/consult_info7.png" alt="infoImg" class="infoImg">
        </div>
      </div>
      <!-- 节点6 -->
      <div class="node-warp6">
        <!-- 节点标题 -->
        <div class="title_node">（6）用户生命周期价值——以用户价值为维度展示数据报表</div>
        <!-- 内容 -->
        <div class="consult-content">
          <p class="contentTxt">
            <span class="txtBolder">用户生命周期价值（LTV，LiveTime Value）是衡量用户长期价值的关键指标，可以帮助开发者深入了解每一位用户的价值，以此判断应用能否获得持续的收益。</span>
          </p>
          <p class="contentTxt">通过该报表开发者可以查看用户安装应用后，在N天后累计产生的广告收益（LTV）和用户安装应用在N天后仍有打开应用的比例（留存率）。支持按时间段、应用、用户来源、预估天数查询数据，便于开发者了解广告变现与用户之间的价值关系。</p>
        </div>
        <!-- 图片 -->
        <div class="img-warp">
          <img src="../../../assets/image/consult/info3/consult_info8.png" alt="infoImg" class="infoImg">
          <img src="../../../assets/image/consult/info3/consult_info9.png" alt="infoImg" class="infoImg">
          <img src="../../../assets/image/consult/info3/consult_info10.png" alt="infoImg" class="infoImg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo1',
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
</style>
