<template>
  <div>
    <div class="frequency-warp frequency-warp-one">
      <div class="frequency-container">
        <div class="container-title">媒体痛点</div>
        <div class="line"></div>
        <div class="content-warp content-warp-one">
          <div class="content-info content-left">
            <div class="content-left-top">
              <div class="title">高频次，低单价</div>
              <div class="content">高频广告位指单个用户一天观看广告次数偏多，用户曝光广告频次越高，eCPM越低。且过度高频，存在被误判作弊的风险。</div>
            </div>
            <div class="content-left-bottom">
              <div class="title">
                <span class="txt">代表性的媒体业务场景</span>
              </div>
              <div class="content">阅读类媒体的Banner广告位，休闲游戏类媒体的Banner广告位，此类情况尤甚；</div>
            </div>
          </div>
          <div class="content-info content-right">
            <img src="../../../assets/image/solution/solution1/solution1_painPoint.png">
          </div>
        </div>
      </div>
    </div>
    <div class="frequency-warp frequency-warp-two">
      <div class="frequency-container">
        <div class="container-title">轻变聚合解决方案</div>
        <div class="line"></div>
        <div class="infoCon">
          <p class="infoTxt">轻变聚合 A/B Test功能，支持根据流量特征进行拆分实验</p>
          <p class="infoTxt">针对频次进行A/B实验，对比频次对收益的影响，确定最佳实践，并可以进行一键发布。</p>
        </div>
        <div class="subtitle">使用A/B Test功能进行试验，确定高频广告位的最佳收益模型</div>
        <div class="content-warp content-warp-two">
          <div class="content-info content-left">
            <img src="../../../assets/image/solution/solution1/solution1_chart1.png">
          </div>
          <div class="content-info content-right">
            <div class="content content_one">
              <div class="title">实验结论</div>
              <div class="conTxt">
                <p><span class="green">绿色</span>售卖渠道在用户频次小于6次时单价高于<span class="blue">蓝色</span>通道</p>
                <p><span class="blue">蓝色</span>售卖渠道在用户频次大于6次时单价高于<span class="green">绿色</span>通道</p>
              </div>
            </div>
            <div class="content">
              <div class="title">调整策略</div>
              <div class="conTxt">
                <p>系统设置为用户6次及以下请求发送给<span class="green">绿色</span>通道</p>
                <p>6次及以上请求发送给<span class="blue">蓝色</span>通道</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'solution-frequency'
  }
</script>

<style lang="scss" scoped>
  .frequency-warp-one {
    height: 590px;
  }
  .frequency-warp-two {
    height: 864px;
    background: #FAFAFA;
  }
  .frequency-warp {
    width: 100%;
    .frequency-container {
      width: 1120px;
      height: 100%;
      margin: 0 auto;
      /* 标题 */
      .container-title {
        padding-top: 80px;
        width: 100%;
        text-align: center;
        color: #464646;
        font-size: 32px;
        font-weight: bolder;
        text-shadow: 0px 5px 10px rgba(7, 0, 147, 0.15);
      }
      /* 详情 */
      .infoCon {
        width: 100%;
        margin-top: 20px;
        .infoTxt {
          text-align: center;
          font-size: 16px;
          line-height: 36px;
          color: #464646;
          font-weight: 400;
        }
      }
      /* 子标题 */
      .subtitle {
        width: 820px;
        height: 62px;
        margin: 40px auto;
        background: url("../../../assets/image/solution/title_bg.png") center no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        color: #fff;
        font-weight: bolder;
        text-align: center;
        line-height: 50px;
        text-shadow: 0px 5px 10px rgba(7, 0, 147, 0.15);
      }
      /* 主体内容 */
      .content-warp-one {
        margin-top: 40px;
        height: 290px;
        /* 左边内容部分 */
        .content-left {
          .content-left-top {
            background: url("../../../assets/image/solution/solution1/solution1_icon2.png") left top no-repeat;
            background-size: 98px 100px;
            margin-bottom: 45px;
            .title {
              font-size: 24px;
              font-weight: bold;
              color: #464646;
              padding: 30px 0 20px 0;
              text-shadow: 0px 5px 10px rgba(7, 0, 147, 0.15);
            }
            .content {
              width: 506px;
              font-size: 16px;
              font-weight: 400;
              color: #464646;
              line-height: 30px;
            }
          }
          .content-left-bottom {
            .title {
              height: 34px;
              background: url("../../../assets/image/solution/solution_icon.png") left no-repeat;
              background-size: 34px;
              .txt {
                display: inline-block;
                font-size: 16px;
                color: #464646;
                font-weight: bold;
                padding-left: 44px;
                margin-top: 10px;
              }
            }
            .content {
              margin-top: 16px;
              font-size: 14px;
              color: #6B6B6B;
              width: 506px;
            }
          }
        }
      }
      .content-warp-two {
        height: 394px;
        .content-right {
          padding-left: 105px;
          .content_one {
            margin-bottom: 60px;
          }
          .content {
            height: 158px;
            width: 450px;
            background: url("../../../assets/image/solution/circula_bg.png") left no-repeat;
            background-size: 158px;
            padding-left: 68px;
            .title {
              line-height: 90px;
              font-size: 24px;
              color: #464646;
              font-weight: bold;
              text-shadow: 0px 5px 10px rgba(7, 0, 147, 0.15);
            }
            .conTxt {
              font-size: 16px;
              color: #464646;
              line-height: 36px;
            }
          }
        }
      }
      .content-warp{
        display: flex;
        .content-info {
          flex: 1;
        }
      }
    }
  }
</style>
