<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">轻变聚合加入中国互联网协会！</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2023年7月26日</span>
      <span class="detailTxt">分类：新闻动态</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="consult-content">
      <p class="contentTxt">近日，经协会批准，轻变聚合（上海倍孜网络技术有限公司）正式加入中国互联网协会，成为会员单位。</p>
    </div>
    <div class="img-warp">
      <img src="@/assets/image/consult/info28/consult_info1.png" alt="infoImg" class="infoImg">
    </div>
    <div class="consult-content">
      <p class="contentTxt">中国互联网协会隶属于中华人民共和国工业和信息化部，是由中国互联网行业及与互联网相关的企事业单位、社会组织自愿结成的全国性、行业性、非营利性社会组织，旨在推动我国互联网健康有序发展、对推进新一轮科技革命和数字经济跨越式发展等方面具有重大意义。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">当下，数字经济已经成为全球经济增长的重要驱动力，网络营销作为数字经济领域的重要组成部分，通过数字化、技术性创新模式，业务活力和质量进一步提升。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">轻变聚合作为一家专业的移动应用商业化服务商，在移动应用变现领域深耕多年，伴随移动互联网与数字化技术的持续发展，与众多开发者一同追求让移动应用“变”得更好。致力于运用技术、服务等综合实力为开发者、广告主提供安全、高效、透明的一站式商业变现服务，解决移动应用变现技术研发久、成本控制难、增收实现慢的问题，为开发者提供专属的变现解决方案。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">很荣幸能够成为中国互联网协会会员单位，轻变聚合将秉持初心，致力于为移动应用变现领域贡献力量。未来，我们也期待能与业内前沿精英深入交流学习与共创，释放更多价值与能量。同时，轻变聚合也将继续秉持对移动应用商业化技术的热情与创新，拥抱变化，不断尝试与革新，与其他成员一同维护互联网科技环境的健康有序发展！</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo28'
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
  .special-img-wrap {
    text-align: center;
    .special-img {
      width: 50% !important;
    }
  }
</style>
