<template>
  <div class="container">
    <!-- 公用头部 -->
    <headers :activeindex="3"></headers>
    <!-- 客户案例头部 -->
    <div class="client-case-warp">
      <!-- 列表页头部 -->
      <div class="banner-wrap" v-if="!routeHash">
        <img src="../../assets/image/client-case/top_bg.png" alt="背景" class="banner">
        <img src="../../assets/image/client-case/top_txt.png" alt="客户案例" class="banner_txt">
      </div>
      <!-- 详情头部 -->
      <div class="banner-case-wrap" v-else>
        <img src="../../assets/image/client-case/case_top_bg.png" alt="背景" class="banner">
        <img src="../../assets/image/client-case/top_txt.png" alt="客户案例" class="banner_txt">
      </div>
    </div>
    <!-- 客户案例主体部分 -->
    <div class="client-case-content" v-if="!routeHash">
      <!-- 案例 -->
      <div class="case-content" v-for="item in caseContentList" :key="item.id">
        <div class="img-wrap">
          <img :src="item.imgUrl" alt="图片" class="caseImg">
        </div>
        <p class="title" :title="item.title" @click="viewDetail(item.id)">{{ item.title }}</p>
        <p class="case-line"></p>
        <p class="content" :title="item.content">{{ item.content }}</p>
        <p class="more-btn" @click="viewDetail(item.id)">MORE</p>
      </div>
    </div>
    <!-- 案例详情 -->
    <case-info v-else :caseInfo="caseInfo[routeHash-1]"></case-info>
    <!-- 底部 -- 点击马上合作 -->
    <footer-btn></footer-btn>
    <!-- 底部导航 -->
    <footers></footers>
  </div>
</template>

<script>
  import headers from "@/component/header";
  import footers from "@/component/footer";
  import footerBtn from "@/component/footer-btn";
  import caseInfo from "./components/caseInfo";
  import { caseList, caseInfoData } from './data/case';
  export default {
    name: 'client-case',
    components: {
      headers,
      footers,
      footerBtn,
      caseInfo
    },
    computed: {
      caseContentList() {
        return caseList
      },
      caseInfo() {
        return caseInfoData
      },
      routeHash() {
        if (this.$route.hash === '') return '';
        let routeHash = this.$route.hash.split('=')[1];
        return routeHash;
      }
    },
    methods: {
      viewDetail(id) {
        this.$router.push({ name: 'client-case', hash: '#page=' + id });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    width: 100%;
    height: auto;
    /* 顶部背景 */
    .client-case-warp {
      width: 100%;
      height: auto;
      .banner-wrap {
        height: 420px;
        position: relative;
        overflow: hidden;
        .banner {
          position: absolute;
          left: 50%;
          width: 1920px;
          height: 420px;
          margin-left: -960px;
        }
        .banner_txt{
          position: absolute;
          left: 50%;
          top: 50%;
          width: 310px;
          height: 74px;
          margin-left: -155px;
        }
      }
      .banner-case-wrap {
        height: 273px;
        position: relative;
        overflow: hidden;
        .banner {
          position: absolute;
          left: 50%;
          width: 1920px;
          height: 273px;
          margin-left: -960px;
        }
        .banner_txt{
          position: absolute;
          left: 50%;
          top: 50%;
          width: 246px;
          height: 58px;
          margin-left: -123px;
        }
      }
    }
    /* content内容部分 */
    .client-case-content {
      width: 1236px;
      overflow: hidden;
      margin: 0 auto;
      padding-top: 30px;
      .case-content {
        z-index: 9;
        position: relative;
        float: left;
        box-sizing: border-box;
        width: 412px;
        height: 470px;
        padding: 18px;
        margin-bottom: 60px;
        transition: all 300ms ease-in-out;
        &:hover {
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
          .img-wrap {
            .caseImg {
              width: 406px;
              height: 300px;
            }
          }
        }
        /* 图片 */
        .img-wrap {
          overflow: hidden;
          position: relative;
          width: 378px;
          height: 280px;
          .caseImg{
            position: absolute;
            width: 378px;
            height: 280px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: all 300ms ease-in-out;
          }
        }
        /* 标题 */
        .title {
          color: #333;
          font-size: 20px;
          padding-top: 14px;
          font-weight: bolder;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          cursor: pointer;
          &:hover {
            color: #3e27d7;
          }
        }
        /* 线 */
        .case-line {
          width: 30px;
          height: 4px;
          margin-top: 14px;
          margin-bottom: 14px;
          background: linear-gradient(-10deg, #A634E7, #493DD1);
        }
        /* 文字内容 */
        .content {
          color: #333;
          font-size: 14px;
          margin-bottom: 8px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        /* more按钮 */
        .more-btn{
          width: 60px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          font-size: 10px;
          color: #fff;
          border-radius: 15px;
          font-weight: 400;
          position: absolute;
          right: 18px;
          bottom: 18px;
          cursor: pointer;
          background: linear-gradient(-10deg, #A634E7, #493DD1);
        }
      }
    }
  }
</style>
