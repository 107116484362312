<template>
  <div class="consult-container">
    <!-- 主标题 -->
    <div class="title_main">打破常规之路，创新永不停歇！轻变聚合成功斩获2023第十一届TopDigital“年度最佳营销技术公司”奖项！</div>
    <!-- 资讯详情 -->
    <div class="detail-warp">
      <span class="detailTxt">发布时间：2023年6月30日</span>
      <span class="detailTxt">分类：新闻动态</span>
    </div>
    <!-------------------------------- 文章开头 -------------------------------->
    <div class="consult-content">
      <p class="contentTxt">2023年6月29日，第十一届TopDigital创新营销奖获奖结果正式揭晓，轻变聚合凭借先进的广告变现技术，创新的变现理念，从海内外657家参赛企业提交的3052件作品中突出重围，成功斩获“TopDigital创新营销奖—年度最佳营销技术公司”奖项。</p>
    </div>
    <div class="img-warp">
      <img src="@/assets/image/consult/info26/consult_info1.png" alt="infoImg" class="infoImg">
    </div>
    <div class="img-warp">
      <img src="@/assets/image/consult/info26/consult_info2.png" alt="infoImg" class="infoImg">
      <p class="imgCenterInfo">左三为轻变聚合</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">TopDigital创新营销奖是数字营销领域品牌创建与产品创新的权威奖项，关注以任何创新形式帮助企业获得增长的营销作品，表彰最具创新力的品牌及企业。该奖项保持一贯的高标准、高水平，以“营销创新增长模型”为理念根本，重视对参赛作品创新性、执行性、商业价值、可持续性的考察，注重长期价值建设和内外部综合表现。</p>
    </div>
    <div class="img-warp">
      <img src="@/assets/image/consult/info26/consult_info3.png" alt="infoImg" class="infoImg">
    </div>
    <div class="consult-content">
      <p class="contentTxt">“年度最佳营销技术公司”主要针对企业机构展开评选，从创新力、商业力和影响力三个维度展开，对企业的营销理念、营销战略、年度业绩变现、为客户创造价值等多个方面综合考量。轻变聚合斩获这一奖项，是业界对公司的认可，也是对轻变聚合近年来工作理念的认可。</p>
    </div>
    <div class="img-warp special-img-wrap">
      <img src="@/assets/image/consult/info26/consult_info4.png" alt="infoImg" class="infoImg special-img">
    </div>
    <div class="consult-content">
      <p class="contentTxt">作为极富创新活力与专业性的媒体变现一站式移动应用商业化服务供应商，轻变聚合自成立以来积极关注移动应用商业化变现趋势，从媒体变现实际需求出发，依托多年移动广告行业经验与独到的媒体商业化见解，为上千家APP开发者提供变现服务，并且根据客户特性，打造专属变现解决方案，成为媒体变现好伙伴。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">轻变聚合根据多年从业经验、创新的理念和先进的技术，打造了一套专业的广告变现管理系统，以轻量化、工具化的系统服务，为媒体提供商业化变现及增收运营解决方案。同时，轻变聚合也是国内最早推出以Bidding为核心技术的聚合工具服务企业，通过Bidding及Bidding+Waterfall模式，配合广告变现管理工具进行广告分层、智能混排、自动价格排序、流量分组、A/B测试等多种广告策略调优，为国内开发者提供服务，已经服务了超5000家开发者，月覆盖用户超3亿。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">此外，轻变聚合移动广告变现管理平台还具有数据透明、对接安全、专业优质服务三大优势，以增收为核心，业务透明、系统安全有保障，专人对接及时响应需求，为媒体变现业务运营提供坚实基础。同时，系统还可以提供实时多维度流量数据分析报表，帮助开发者直观查看广告变现情况，对变现过程中出现的异常情况，系统还会及时预警，节省运营人力，帮助开发者实现更好变现。</p>
    </div>
    <div class="consult-content">
      <p class="contentTxt">未来，轻变聚合将继续不负期待，坚持用专业与创新为移动应用开发者服务，通过不断提升核心竞争力，持续为开发者赋能！</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'consultInfo26'
  }
</script>

<style lang="scss" scoped>
  @import "../style/common";
  .special-img-wrap {
    text-align: center;
    .special-img {
      width: 50% !important;
    }
  }
</style>