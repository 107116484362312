<template>
  <div class="container">
    <!-- 公用头部 -->
    <headers :activeindex="1"></headers>
    <!-- 系统开源 -->
    <!-- <open-source v-if="routeHash === ''"></open-source> -->
    <!-- 广告变现 -->
    <adv v-if="routeHash === '2'"></adv>
    <!-- 聚合管理 -->
    <pol v-if="routeHash === '3'"></pol>
    <!-- 福利中心 -->
    <weal v-if="routeHash === '4'"></weal>
    <!-- 底部按钮 -- 立即接入 -->
    <div class="footer-btn-wrap" :class="footerBgClass" v-if="routeHash !== ''">
      <div class="content" v-if="routeHash === '2'">加入轻变聚合马上开始变现增收</div>
      <div class="content" v-if="routeHash === '3'">使用轻变聚合领先的智能变现工具，开启您的增收之旅</div>
      <div class="content" v-if="routeHash === '4'">开启福利中心<span class="text">进入用户促活新时代</span></div>
      <div class="btn" @click="goRegister">立 即 接 入</div>
    </div>
    <!-- 底部导航 -->
    <footers></footers>
  </div>
</template>

<script>
  import headers from "@/component/header";
  // import openSource from './components/openSource';
  import adv from './components/adv';
  import pol from './components/pol';
  import weal from './components/weal';
  import footers from "@/component/footer";

  export default {
    name: 'product-index',
    components: {
      headers,
      // openSource,
      adv,
      pol,
      weal,
      footers,
    },
    computed: {
      routeHash() {
        if (this.$route.hash === '') return '';
        let routeHash = this.$route.hash.split('=')[1];
        return routeHash;
      },
      footerBgClass() {
        switch (this.routeHash) {
          case '2': return 'footer-bg1';
          case '3': return 'footer-bg2';
          case '4': return 'footer-bg3';
          default: return '';
        }
      }
    },
    methods: {
      // 点击跳转到注册页
      goRegister() {
        window.open(process.env.VUE_APP_HREF_AD_SCOPE + '#/register', "register");
      },
    }
  }
</script>

<style lang="scss" scoped>
  .footer-btn-wrap {
    height: 300px;
    .content {
      padding-top: 80px;
      font-size: 42px;
      line-height: 1em;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      letter-spacing: 4px;
      /* margin-left: -6px; */
      .text {
        margin-left: 32px;
        font-weight: bold;
      }
    }
    .btn {
      width: 260px;
      height: 52px;
      line-height: 52px;
      margin: 40px auto 0;
      background: #2e9eff;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
    }
  }
  .footer-bg1 {
    background: url('~@/assets/image/product/adv/footer.png') center no-repeat;
  }
  .footer-bg2 {
    background: url('~@/assets/image/product/pol/footer.png') center no-repeat;
  }
  .footer-bg3 {
    background: url('~@/assets/image/product/weal/footer.png') center no-repeat;
  }
</style>
