<template>
  <div>
    <!-- banner -->
    <div class="banner">
      <div class="title">用户促活  福利中心</div>
      <div class="content">提供媒体高质量广告变现</div>
    </div>
    <!-- 福利 -->
    <div class="weal-contaniner" v-for="(item, index) in wealList" :key="index" :class="'weal-contaniner' + (index + 1)">
      <div class="weal-content-wrap">
        <div class="weal-img-wrap" v-if="item.showFlag == 'left'">
          <img :src="item.imgUrl" class="weal-img weal-img-left">
        </div>
        <div class="weal-cont-wrap">
          <div class="weal-cont">
            <div class="title">{{ item.title }}</div>
            <div class="line"></div>
            <p class="cont" v-for="(cont, ind) in item.content" :key="ind">{{ cont }}</p>
          </div>
        </div>
        <div class="weal-img-wrap" v-if="item.showFlag == 'right'">
          <img :src="item.imgUrl" class="weal-img weal-img-right">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'product-weal',
    data() {
      return {
        wealList: [
          { id: 1,
            showFlag: 'left',
            title: '福利中心原生化',
            imgUrl: require('../../../assets/image/product/weal/weal1.png'),
            content: [ '通过促活组件的UI自定义，', '可以将”福利中心”的UI样式更加贴近App本身；', '更加原生，体验更好。' ]
          },
          { id: 2,
            showFlag: 'right',
            title: '任务&激励定制化',
            imgUrl: require('../../../assets/image/product/weal/weal2.png'),
            content: [ '倍孜SDK通过可配置的任务管理，','将App本身的用户行为和积分体系相结合，', '制定符合用户习惯的积分任务和激励广告任务；', '进一步提升用户的活跃和粘性，并带来客观的收入。' ]
          },
          { id: 3,
            showFlag: 'left',
            title: '兑换商品定制化',
            imgUrl: require('../../../assets/image/product/weal/weal3.png'),
            content: [ '倍孜SDK通过可配置的商品管理，', '设计一系列的与App消费内容相关的商品提供给用户；', '实现App增收和用户受益的双赢。' ]
          },
          { id: 4,
            showFlag: 'right',
            title: '抽奖积分消耗定制化',
            imgUrl: require('../../../assets/image/product/weal/weal4.png'),
            content: [ '倍孜SDK通过可配置的抽奖管理，', '给App用户提供有趣味性的抽奖活动，带来给更多的选择；', '并可以进一步消耗用户积分来平衡损益。' ]
          },
          { id: 5,
            showFlag: 'left',
            title: '福利中心数据报表化',
            imgUrl: require('../../../assets/image/product/weal/weal5.png'),
            content: [ '倍孜SDK通过 福利中心损益、用户活跃、福利中心留存、福利中心分析、', '任务/商品/抽奖分析 等数据报表的组合；', '来深入了解福利中心的运营情况，做出进一步的调整。' ]
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  /* banner */
  .banner {
    position: relative;
    height: 500px;
    background: url('~@/assets/image/product/weal/welfareBanner.png') center no-repeat;
    .title, .content {
      position: absolute;
      left: 50%;
      width: 1200px;
      margin-left: -600px;
      line-height: 1em;
      color: #1d1d1d;
      letter-spacing: 6px;
    }
    .title {
      top: 200px;
      font-size: 52px;
      font-weight: bold;
      color: #1e1e1e;
    }
    .content {
      top: 290px;
      font-size: 26px;
      color: #474747;
    }
  }

  /* 福利信息 */
  .weal-contaniner {
    width: 100%;
    height: 480px;
    background-size: 100% 100%;
    overflow: hidden;
    .weal-content-wrap {
      width: 1200px;
      height: 310px;
      margin: 90px auto 80px;
      display: flex;
      .weal-cont-wrap {
        display: flex;
        flex: 1;
        align-items: center;
        .weal-cont {
          text-align: left;
          padding-left: 80px;
          .title {
            font-size: 30px;
            color: $theme-color;
            font-weight: bolder;
            letter-spacing: 2px;
            padding-bottom: 30px;
          }
          .line {
            margin: 0 0 30px 0;
            width: 20px;
            height: 5px;
            background: $theme-color;
          }
          .cont {
            font-size: 14px;
            color: #2b2b2b;
            padding-bottom: 18px;
          }
        }
      }
      .weal-img-wrap{
        .weal-img-left{
          margin-left: 50px;
          margin-right: 80px;
        }
        .weal-img-right{
          margin-left: 70px;
        }
      }
    }
  }
  .weal-contaniner1 {
    background: url("~@/assets/image/product/weal/wealBanner1.png") no-repeat center;
  }
  .weal-contaniner2 {
    background: url("~@/assets/image/product/weal/wealBanner2.png") no-repeat center;
  }
  .weal-contaniner3 {
    background: url("~@/assets/image/product/weal/wealBanner3.png") no-repeat center;
  }
  .weal-contaniner4 {
    background: url("~@/assets/image/product/weal/wealBanner4.png") no-repeat center;
  }
  .weal-contaniner5 {
    background: url("~@/assets/image/product/weal/wealBanner5.png") no-repeat center;
  }
</style>
